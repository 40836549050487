import { IoIosArrowBack } from "react-icons/io";
import "./styles/styles.scss";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const TermsPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });
  return (
    <section className="terms-section">
      <div className="header flex-horizontal">
        <h1>Terms of Service</h1>
      </div>
      <ul>
        <li>
          <h3>1. Introduction and Definitions</h3>
          <p className="body-reg">
            Welcome to the CastleBird Website and Services. We are confident
            that you will find our Services useful. These Terms of Use (the
            “TOU”) cover several different situations, so please refer to the
            following definitions for clarification:
            <br />
            <strong>“Client”</strong> refers to a client of a User of the
            Services. An <strong>“Active Client”</strong> is a Client who has
            used services and may be making a payment of{" "}
            <strong>“Professional Services Fees”</strong> to a User via the
            Services.
            <br />
            <strong>“CastleBird”</strong> is the trading name of Practice
            CastleBird Inc., a proprietary limited company headquartered in
            Canada at the address listed below. Where the present TOU refers to
            “CastleBird”, they may refer to Practice CastleBird Inc and its
            subsidiaries and affiliates, and/or their respective shareholders,
            officers, directors, employees, agents, partners, principals,
            representatives, successors, and assigns (collectively{" "}
            <strong>“Representatives”</strong>), depending on the context. Any
            reference to <strong>“we”</strong>, <strong>“our”</strong>, or{" "}
            <strong>“us”</strong> in these TOU refers to CastleBird.
            <br />
            <strong>“Professional Services”</strong> refers to a message
            service, video calling service, or any other professional services
            that a Logged-In User has created through the Services to send to a
            Client.
            <br />
            “Services” refers to the software services accessed through the
            Website and the App that CastleBird has developed and/or licensed
            that allow you to create Professional Services, receive payments
            from Clients, and various related functionalities.
            <br />
            “User” refers to the individual who has created an account to use
            the Services for their own benefit and/or on behalf of a company,
            partnership, association, or other legal entity (a “Legal Entity”).
            When a User has created an account to use the Services and is logged
            in, they may be referred to in these TOU as a “Logged-in User”. The
            Legal Entity that the User is representing may be referred to in
            these TOU as a “Professional Services Provider”.
            <br />
            “Website” is the website located at https://castlebird.com which is
            owned and operated by CastleBird, and includes all subdomains and
            subpages, present and future. Without limiting the generality of the
            foregoing, the Website includes the subdomain present and future.
            <br />
            Please note that in these TOU, anyone (including but not limited to
            Users and Clients) interacting with the Website or Services may be
            referred to as “you” and “your”.
            <br />
            Additional definitions shall be made throughout these TOU, but they
            will be recognizable as they will be capitalized, bolded, and in
            quotation marks. The definitions found in these TOU shall also apply
            to our Privacy Policy
          </p>
        </li>
        <li>
          <h3>2. Acceptance and Modifications</h3>
          <p className="body-reg">
            <strong>
              By interacting with the Services in any way including but not
              limited to: (i) clicking or tapping the acceptance button upon
              signing up for an account or a Subscription; or (ii) accepting a
              Professional Service or making a payment through the Services if
              you are a Client, you hereby accept to be bound by these Terms of
              Use (“TOU”) without any reservations, modifications, additions or
              deletions.  If you do not agree to all the provisions contained in
              the TOU, you are not authorized to use the Services. The TOU is a
              legal and binding agreement between you and us.
            </strong>
            <br />
            <strong>
              If you are using the Services and accepting or agreeing to these
              TOU on behalf of the Legal Entity, you represent and warrant that
              you have the authority to bind that Legal Entity to these TOU and,
              in such event, "you" and "your" will refer and apply to that Legal
              Entity, as applicable.
            </strong>
            <br />
            CastleBird reserves the right, at any time and without prior notice,
            to modify or replace any of these TOU. Any changes to the TOU can be
            found at this URL. It is your responsibility to check the TOU
            periodically for changes. Your use of the Website or Services
            following the posting of any changes to the TOU constitutes
            acceptance of those changes. If there are any significant changes to
            the TOU that materially affect our relationship with you, we will
            use commercially reasonable efforts to notify you by sending a
            notice to the primary email address specified in your account, by
            posting a prominent notice when you log in to the Services for the
            first time following those changes, or by posting a prominent notice
            in the Services and/or on the Website.
            <br />
            These TOU should be read in conjunction with the Privacy Policy as
            both of these documents govern your use of the Website and Services.
          </p>
        </li>
        <li>
          <h3>3. Contacting Us</h3>
          <p className="body-reg">
            If you have any questions about these TOU, please contact us at:
            CastleBird Legal Services legal@castlebird.com
            <br />
            or
            <br />
            CastleBird Inc.
            <br />
            #201 - 1076 Bathurst St.
            <br />
            Toronto, ON. M5R 3G9
            <br />
            Canada
            <br />
            If you have any questions regarding the Services, please contact us
            at support@castlebird.
          </p>
        </li>
        <li>
          <h3>
            4. General Code of Conduct for Use of the Website and Services
          </h3>
          <p className="body-reg">
            In addition to the more specific rules found elsewhere in these TOU,
            you agree that by interacting with the Website or Services in any
            way, you will:
            <ol className="list-roman">
              <li>
                Not use the Website/App or Services in any manner that in any
                way violates these TOU or any other applicable policy posted on
                the Website or in the Services by CastleBird;
              </li>
              <li>
                Not use the Website/App or Services in any manner that violates
                any intellectual property rights of CastleBird or any third
                party;
              </li>
              <li>
                Not use the Website/App or Services in any manner to propagate
                spam, including but not limited to unsolicited advertising or
                bulk electronic mail or messages, including to link to a spam or
                phishing website;
              </li>
              <li>
                Not use the Website/App or Services in any manner to propagate
                software viruses, Trojan horses, worms, or any other malicious
                or non-malicious computer code, files, or programs that are
                designed or intended to disrupt, damage, limit or interfere with
                the proper function of any software, hardware, or
                telecommunications equipment in any form whether belonging to
                CastleBird or a third party, or to damage or obtain unauthorized
                access to any system, data, password or other information
                (whether personal data or not) of CastleBird, other Services
                Users, or any other third party;
              </li>
              <li>
                Not: (1) take any action that imposes or may impose (as
                determined by CastleBird in its sole discretion) an unreasonable
                or disproportionately large load on CastleBird’s (or its
                third-party providers’) infrastructures; (2) interfere or
                attempt to interfere with the proper functioning of the Website
                or Services or any activities conducted on or via the Website or
                Services; (3) bypass any measures CastleBird may use to prevent
                or restrict access to the Services or any element thereof; (4)
                use manual or automated software, devices, or other processes to
                “crawl” or “spider” any page or portion of the Website or
                Services; or (5) harvest or scrape any content from the Website
                or Services in an unreasonable manner;
              </li>
              <li>
                Not take any action or use any process that removes, modifies,
                disables, blocks, obscures, or otherwise impairs any advertising
                or other promotions in connection with the Website or Services;
              </li>
              <li>
                Not use the Website/App or Services to in any way collect
                information (whether personal information or not) of any third
                party or in violation of our Privacy Policy, except as permitted
                by the nature and function of the Website or Services;
              </li>
              <li>
                Not use the Website/App or Services to advertise or promote
                products or services that are not expressly approved in advance
                in writing by CastleBird, or as otherwise permitted by the
                nature of the Services;
              </li>
              <li>
                Not interfere with any third party’s use or enjoyment of the
                Website or Services;
              </li>
              <li>
                Not do anything or encourage conduct that would constitute a
                criminal offense or give rise to civil liability, or is in any
                way unlawful, illegal, fraudulent, or harmful to any third
                party;
              </li>
              <li>Not attempt to do any of the foregoing prohibitions;</li>
              <li>
                Use the Website/App and Services in good faith and in compliance
                with all applicable local, state, or provincial, national, and
                international laws.
              </li>
            </ol>
          </p>
        </li>
        <li>
          <h3>5. Accounts</h3>
          <ul>
            <li>
              <h3>a. Age Requirements</h3>
              <p className="body-reg">
                In order to create an account on the Services, you affirm that
                you are at least eighteen (18) years of age or over, or the age
                of majority in the jurisdiction you reside and from which you
                access the Services where the age of majority is greater than
                eighteen (18) years of age.
              </p>
            </li>
            <li>
              <h3>b. Accounts</h3>
              <p className="body-reg">
                In order to be able to use the Services, you will be required to
                create an account. Accounts are available to anyone who provides
                the requisite information, subject to the restrictions and
                conditions as outlined elsewhere in these TOU.
                <br />
                As part of the account registration, you will be asked to choose
                a third-party provider to signup such as Google, Facebook, etc….
                It is your responsibility to keep your account secure on those
                login providers. You are responsible for all activities that
                occur under your account whether by you or any third party. You
                agree to immediately notify us of any unauthorized use of your
                account or any other breach of security regarding your account.
                <br />
                If you are a Logged-in User, it is strongly suggested that you
                log out of your account at the end of every session, or not
                leave a logged-in account unattended for any period of time.
                CastleBird and its Representatives will not be held liable for
                any losses or damages, direct or indirect, pecuniary or
                non-pecuniary, resulting from your failure to log out at the end
                of the session, an unattended logged-in session, or a third
                party using the Services with your Account Information and
                accessing your account through any means, and disclaims any
                responsibility in this regard.
                <br />
                CastleBird reserves the right to suspend or terminate your
                account, at its sole discretion, at any time and for any reason,
                including but not limited to whether you have violated the
                letter or spirit of the TOU, as more fully described
                hereinbelow.
              </p>
            </li>
            <li>
              <h3>c. Account Information</h3>
              <p className="body-reg">
                The information required to create an account to begin using the
                Services is the following: email address, full name, company,
                phone number, and location (country). This information you
                submit as part of the sign-up process may be referred to in the
                present TOU or the Privacy Policy as “Account Information”.
                <br />
                By submitting Account Information, you represent and warrant
                that the Account Information is true and accurate to the best of
                your knowledge. Submitting false or misleading Account
                Information may result in you being banned from using the
                Services, at our sole discretion. CastleBird reserves the right
                to verify your submitted Account Information for any reason.
                <br />
                CastleBird retains absolute discretion to refuse to approve your
                account for any reason, including but not limited to if we
                believe you have submitted false or misleading Account
                Information. Without limiting the generality of the Disclaimer
                of Warranties further in these TOU, CastleBird and/or its
                Representatives shall not be responsible for any losses or
                damages, pecuniary or otherwise, to you resulting from
                CastleBird’s refusal to approve your account, and CastleBird
                disclaims any responsibility thereto.
              </p>
            </li>
            <li>
              <h3>d. Additional Users</h3>
              <p className="body-reg">
                The information required to create an account to begin using the
                Services is the following: email address, full name, company,
                phone number, and location (country). This information you
                submit as part of the sign-up process may be referred to in the
                present TOU or the Privacy Policy as “Account Information”.
                <br />
                By submitting Account Information, you represent and warrant
                that the Account Information is true and accurate to the best of
                your knowledge. Submitting false or misleading Account
                Information may result in you being banned from using the
                Services, at our sole discretion. CastleBird reserves the right
                to verify your submitted Account Information for any reason.
                <br />
                CastleBird retains absolute discretion to refuse to approve your
                account for any reason, including but not limited to if we
                believe you have submitted false or misleading Account
                Information. Without limiting the generality of the Disclaimer
                of Warranties further in these TOU, CastleBird and/or its
                Representatives shall not be responsible for any losses or
                damages, pecuniary or otherwise, to you resulting from
                CastleBird’s refusal to approve your account, and CastleBird
                disclaims any responsibility thereto.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <h3>6. Proprietary and Intellectual Property Rights; Your Data</h3>
          <ul>
            <li>
              <h3>a. CastleBird’s Rights </h3>
              <p className="body-reg">
                You acknowledge that: (i) the Website and Services contain
                proprietary and confidential information that is protected by
                applicable intellectual property laws and other laws, and (ii)
                CastleBird and/or third parties (via license) own all rights,
                title, and interest in and to the Website and Services and
                content that may be presented or accessed through the Website
                and Services (except for Data as defined below), including
                without limitation all Intellectual Property Rights therein and
                thereto.  All rights not specifically granted under these TOU
                are reserved for CastleBird and its licensors. “Intellectual
                Property Rights'' means any and all rights existing from time to
                time under patent law, copyright law, trade secret law,
                trademark law, unfair competition law, and any and all other
                proprietary rights, and any and all applications, renewals,
                extensions and restorations thereof, now or hereafter in force
                and effect worldwide, whether registered or not.
                <br />
                You agree that you will not, and will not allow any third party,
                to (i) copy, sell, license, distribute, transfer, modify, adapt,
                translate, prepare derivative works from, decompile, reverse
                engineer, disassemble or otherwise attempt to derive source code
                from the Website or Services or content that may be presented or
                accessed through the Website or Services for any purpose, unless
                otherwise permitted by these TOU or the functionalities of the
                Website or Services; (ii) take any action to circumvent or
                defeat the security deployed or enforced by any functionality
                contained in or on the Website and Services; or (iii) remove,
                obscure, or alter CastleBird’s or any third party’s copyright
                notices, trademarks, or other proprietary rights notices affixed
                to or contained within or accessed in conjunction with or
                through the Website and Services.
                <br />
                The content, arrangement, and layout of the Website and
                Services, including but not limited to the trademarks, photos,
                logos, videos, audio, images, text (in the form of plain text,
                HTML, or PDFs), and Computer Code are proprietary to CastleBird,
                either owned or under license, and may not be copied, imitated,
                reproduced, displayed, distributed, transmitted, decompiled or
                otherwise used without the express permission of CastleBird, or
                as permitted by the functionality of the Website and Services or
                these TOU.  Any unauthorized use of the content, arrangement, or
                layout of the Website or Services, Computer Code, images, logos,
                videos, audio, or trademarks found on or in the Website or
                Services or any portion of or derivative works thereof may
                violate civil or criminal laws, including but not limited to
                intellectual property laws, and CastleBird may take action
                accordingly.
                <br />
                The above paragraph further applies to third-party property used
                as part of the Website and Services, including but not limited
                to third-party Computer Code.  For the purposes of the present
                section, “Computer Code” includes but is not limited to source
                code in any programming language, object code, frameworks, CSS,
                Ruby, JavaScript or similar files, templates, modules, or any
                similar files, and related documentation.
              </p>
            </li>
            <li>
              <h3>b. Data and Proposals</h3>
              <p className="body-reg">
                All data you submit through the Services as a Logged-in User
                whether in the form of a text, financial information, graphics,
                images, or any other form of inputted information, including any
                information about your Clients and all their associated
                Intellectual Property Rights (collectively “Data”) remain your
                property. Any Proposals or other documents created from your
                inputted Data, and their related Intellectual Property Rights,
                are the property of CastleBird; however, CastleBird grants you a
                worldwide, exclusive, royalty-free, sub-licensable, and
                transferable license to use, edit, translate, reproduce,
                distribute, and display those Proposals and other documents.
                <br />
                Your access to the inputted Data is contingent upon full payment
                of the Subscription Fees. You are expected to maintain copies of
                all Data inputted into the Services. CastleBird adheres to its
                best policies and procedures to prevent data loss, including a
                daily system data backup regime but does not make any guarantees
                that there will be no loss of Data. Furthermore, CastleBird is
                under no obligation to retain copies of your Data following
                termination of your Subscription or account for any reason.
                <br />
                Without limiting the generality of the disclaimers found further
                in these TOU, CastleBird and/or its Representatives shall in no
                way be held responsible for any losses or damages, direct or
                indirect, pecuniary or otherwise, resulting from any loss of
                Data no matter how caused, or CastleBird’s deletion of your Data
                following the termination of your subscription or account, and
                CastleBird disclaims any responsibility thereto.
              </p>
            </li>
            <li>
              <h3>c. Access to Your Data by Third Party Applications</h3>
              <p className="body-reg">
                If you enable third-party applications for use in connection
                with and within the Services, you acknowledge and agree that
                CastleBird may allow providers of those third-party applications
                to access Your Data as required for the interoperation of such
                third-party applications with the Services. Without limiting the
                generality of the disclaimers found further in these TOU,
                CastleBird and/or its Representatives shall in no way be held
                responsible for any losses or damages, direct or indirect,
                pecuniary or otherwise, resulting from or arising from any
                disclosure, modification, or deletion of your Data resulting
                from any such access by the providers of such third-party
                applications.
              </p>
            </li>
            <li>
              <h3>d. Submitted Information</h3>
              <p className="body-reg">
                If you choose to communicate to CastleBird (via any means)
                suggestions for improvements to the Website or Services or any
                idea or proposal related to CastleBird or its businesses or
                properties (collectively, “Feedback”), CastleBird shall own all
                right, title, and interest in and to the Feedback and will be
                entitled to use the Feedback without restriction. You hereby
                irrevocably assign all right, title, and interest in and to the
                Feedback to CastleBird and waive in favor of CastleBird, its
                successors and assigns all your moral rights in the Feedback;
                and agree to provide CastleBird such assistance as CastleBird
                may require to document, perfect, and maintain CastleBird’s
                rights to the Feedback. You acknowledge and agree that, by
                providing any Feedback to CastleBird, you are not entitled to
                any compensation or reimbursement of any kind from CastleBird
                under any circumstances.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <h3>7. Funds</h3>
          <p className="body-reg">
            If you are a Professional Services, you may have positive funds if
            you have successfully completed a Services and funds have been
            released to you. <br />
            Funds in your Account are held by us in our operating accounts held
            with financial institutions. Funds in your Account are not held
            separately by us and may be commingled with our general operating
            funds, and/or funds of other User's Accounts.
            <br />
            You are not entitled to any interest or other earnings for funds
            that are in your Account.
            <br />
            We may receive interest on funds held by us in our operating
            accounts from financial institutions with whom we hold our operating
            accounts. Any such interest earned belongs to us and we will not be
            liable to any User for any imputed interest on such funds.
            <br />
            If your Account has negative funds, we may:
            <ul className="list">
              <li>
                Set off the negative amount with funds that you subsequently
                receive into your Account;
              </li>
              <li>
                if you have funds in multiple currencies in your Account and one
                of the currencies becomes negative for any reason, we may set
                off the negative amount against funds you maintain in a
                different currency (at an exchange rate applied by us);
              </li>
              <li>
                reverse payments you have made from your Account to other User
                Accounts on the Website;
              </li>
              <li>
                deduct amounts you owe us from the money you subsequently add or
                receive into your Account; or
              </li>
              <li>
                immediately suspend or limit your Account until such time as
                your Account no longer has a negative amount.
              </li>
            </ul>
            <br />
            In the event that we offset a negative amount of funds pursuant to
            this section, it may be bundled with another debit coming out of
            your Account.
            <br />
            We reserve the right to collect any funds owed to us by any other
            legal means.
            <br />
            You acknowledge and agree that:
            <ul className="list">
              <li>
                we are not a bank or other licensed financial institution and do
                not provide banking services or any financial services to you;
              </li>
              <li>
                the funds shown in your Account (which may include Milestone
                Payments subject to Section 25, and/or any prepayment of fees
                and charges which you owe to us) represent our unsecured
                obligations to you with respect to your rights to direct us to
                make payment in relation to the purchase and sale of
                Professional Services through the Website and provision of the
                CastleBird Services;
              </li>
              <li>
                if you were a User acquired in acquisition and your account was
                migrated to the Website, we are responsible for your positive
                funds only to the extent of the legal documentation between us
                and any acquired marketplace, along with this agreement, and you
                acknowledge specifically that the onus is on you to confirm the
                validity of your fund, and that any understatement or
                misstatement in relation to this is not a claim against us, and
                belongs with the counterparty of any prior user agreement to
                which you agreed;
              </li>
              <li>
                to the extent that we are required to release funds from your
                Account to you, you will become our unsecured creditor until
                such funds are paid to you;
              </li>
              <li>
                we are not acting as a trustee or fiduciary with respect to such
                funds or payments;
              </li>
              <li>
                the amount of funds showing in your Account is not insured and
                is not a guaranteed deposit;
              </li>
              <li>
                funds may only be loaded into your Account, or released from
                your Account, by us and you must only use the mechanisms
                available on the Website to pay for or receive funds in respect
                of Proessional Services;
              </li>
              <li>
                any refunds required to be processed in your favor will be
                returned only to the source of the original deposit, and cannot
                be redirected to any other payment source;
              </li>
              <li>
                we will hold funds in respect of the amount of your Account
                (including Milestone Payments) in an account held by us with a
                financial institution (or in any manner that we decide in our
                sole discretion from time to time) and such funds are not
                segregated into a separate account; and
              </li>
              <li>
                we may commingle your funds with funds of other Users and our
                own funds and such commingled funds could be used to pay other
                Users or for our general corporate purposes or otherwise,
                however, we will remain obliged to release or refund funds at
                your direction in accordance with this User Agreement.
              </li>
            </ul>
            <br />
            <h3>A. Limits & Fraud Prevention</h3>
            <br />
            We reserve the right to suspend a User withdrawal request if the
            source of the funds is suspected to be fraudulent.
            <br />
            If we become aware that any funds received into an Account from
            another Account as a result of a fraudulent transaction, this will
            be reversed immediately. If those funds have already been released
            to you, you must pay the funds into your Account. If you do not do
            so, we may suspend, limit or cancel your account, or take action
            against you to recover those funds.
            <br />
            We may, in our sole discretion, place a limit on any or all of the
            funds in your Account (thereby preventing any use of the funds) if:
            <ul className="list">
              <li>
                we believe there may be an unacceptable level of risk associated
                with you, your Account, or any or all of your transactions,
                including if we believe that there is a risk that such funds
                will be subject to reversal or chargeback;
              </li>
              <li>
                we believe that the beneficiary of the payment is someone other
                than you;
              </li>
              <li>
                we believe that the payment is being made to a country where we
                do not offer our Service; or
              </li>
              <li>
                we are required to do so by law or applicable law enforcement
                agencies.
              </li>
            </ul>
            <br />
            If you are involved in a dispute, we may (in certain circumstances)
            place a temporary limit on the funds in your Account to cover the
            amount of any potential liability. If the dispute is resolved in
            your favor, we will lift the limit on your funds and those funds may
            be released to you. If the dispute is not resolved in your favor, we
            may remove the funds from your Account. We may also place a limit on
            your account in circumstances where we suspect you of fraudulent or
            other unacceptable behavior, while we investigate any such matter.
            <br />
            <h3>B. Refunds</h3>
            <br />
            You may ask for a refund at any time for any funds that you have
            paid into your Account except if the amount to refund relates to a
            Milestone Payment or relates to fees or charges payable to us.
            <br />
            If the amount the User has asked to refund relates to: (1) a
            Milestone Payment the Dispute Resolution Process may be followed; or
            (2) our fees and charges, the process set out in the Clause
            "Disputes with Us" must be followed.
            <br />
            If we agree to the refund, the funds will be received by the User
            via the same payment method(s) that the User used to make the
            original payment to us.
            <br />
            We may refund funds to Users irrespective of whether a User has
            requested funds be refunded if: (1) we are required by law or
            consider that we are required by law to do so; (2) we determine that
            refunding funds to the User will avoid any dispute or an increase in
            our costs; (3) we refund funds to the User in accordance with any
            refund policy specified by us from time to time; (4) we find out
            that the original payment made by the User is fraudulent; (5) the
            User made a duplicate payment in error; or (6) we consider, in our
            sole opinion, that it is likely that the refund of funds is
            necessary to avoid a credit card chargeback.
            <br />
            You can request a refund by using our customer support website or
            emailing us at support@castlebird.com. Once you have made a
            Milestone Payment, you expressly agree to use the dispute resolution
            process in this agreement, expressly agree to be bound by its
            ruling, and expressly agree not to initiate any chargeback request
            with your card issuer.
            <br />
            If you initiate any chargeback request or other "Request for
            Information" or similar process, you expressly agree and consent to
            us to share any and all information in relation to your agreement of
            these terms and conditions, in order to defeat any such chargeback
            request.
            <br />
            If you have already initiated a chargeback request with your credit
            card issuer, you must not request a refund of funds by contacting us
            and must not seek double recovery.
            <br />
            If we reasonably determine, having considered all the relevant
            circumstances, that you have made an excessive or unreasonable
            number of requests to refund funds back to you or chargebacks, we
            may suspend, limit or close your Account.
            <br />
            <h3>C. Withdrawals</h3>
            <br />
            Your first withdrawal of funds earned may be delayed for up to
            fifteen days for security and fraud purposes.
            <br />
            Subsequent withdrawals may be delayed for up to fifteen days where
            our fraud prevention policies require a delay.
            <br />
            We may impose a minimum withdrawal amount for funds earned. This is
            set out in our schedule of Fees and Charges. The maximum you can
            withdraw per month is $10,000 unless otherwise specifically agreed
            with support.
            <br />
            We may require you to be Verified by CastleBird before you can
            withdraw funds from your CastleBird Account, irrespective of whether
            or not a delay has been enforced. For details on how to become
            Verified by CastleBird please read the Know your Customer and
            Identity Verification Policy.
            <br />
            You acknowledge and agree that we may seek to verify your identity
            or request additional information from you as part of our anti-fraud
            and Know Your Customer policy, as outlined in the section on
            "Identity / Know Your Customer" sections of this agreement.
          </p>
        </li>
        <li>
          <h3>8. Other Disputes With Users</h3>
          <p className="body-reg">
            You acknowledge and agree that in the event that a dispute arises
            between you and Clients in relation to any Services you will first
            attempt to resolve any differences that you have in relation to such
            Services, including in relation to the quality of the services
            provided.
            <br />
            If you continue to have any difficulties or problems in relation to
            a dispute with Client in relation to a Services we encourage you to
            contact us as set out in the Clause entitled "Contacting us".
            <br />
            You agree that any dispute that is not related to a Milestone
            Payment (which must be dealt with in accordance with the Milestone
            Disputes Policy) arising between you and Clients will be handled in
            accordance with this clause. CastleBird will have full rights and
            powers to make a determination for all such disputes. Upon receipt
            of a dispute, CastleBird shall have the right to request the
            Professional Service and the Client to provide documentation in
            support of their claim or position in relation to the dispute. You
            agree that CastleBird has absolute discretion to accept or reject
            any document provided. You also acknowledge that CastleBird is not a
            judicial or alternative dispute resolution institution and that we
            will make the determinations only as an ordinary reasonable person.
            In addition, we do not warrant that the documents provided by the
            parties to the dispute will be true, complete or correct and you
            agree to indemnify and (to the maximum extent permitted by law) hold
            CastleBird and any of our affiliates harmless against any damages or
            liability you may suffer as a result of any documentation or
            material subsequently being found to be false or misleading.
            <br />
            In relation to disputes with any other users of the Website, you
            hereby agree to indemnify CastleBird from any and all claims,
            demands, and damages, actual and consequential, of every kind and
            nature, known and unknown, that is related to such a dispute, our
            determinations or the use of the Milestone Dispute Resolution
            Services for Milestone Payments and/or for Other Disputes.
            <br />
            The CastleBird Code of Conduct applies to all the services offered
            by CastleBird, including, but not limited to, the Milestone Dispute
            Resolution Services. It is agreed by you that you will make every
            endeavor at fair play and post reasonable and fair demands/offers on
            your dispute. No conduct relating to threats, blackmail,
            intimidation, or inducement of users to leave positive feedback will
            be tolerated, in particular, any refusal to provide either
            deliverables or payment in relation to the provision of feedback.
            <br />A User found to be in breach of the Code of Conduct during the
            Milestone Dispute Resolution Service process may automatically lose
            the dispute in favor of the other party involved, regardless of the
            origin of the dispute. The User who breached the Code of Conduct may
            also incur further disciplinary action. For more information, read
            the Code of Conduct.
            <br />
            <h3>A. Disputes With Us</h3>
            <br />
            If a dispute arises between you and CastleBird, our goal is to
            address your concerns immediately and, if we are unable to do so to
            your satisfaction, to provide you with a means of resolving the
            dispute quickly. We strongly encourage you to first contact us
            directly to seek a resolution by using our customer support website
            or emailing us at support@casltebird.com.
            <br />
            For any claim, CastleBird may elect to resolve the dispute in a
            cost-effective manner through binding non-appearance-based
            arbitration. If CastleBird elects arbitration, such arbitration will
            be initiated through an established alternative dispute resolution
            (ADR) provider, which is to be selected by you from a panel of ADR
            providers that CastleBird will provide to you. The ADR provider and
            the parties must comply with the following rules: a) the arbitration
            shall be conducted by telephone, online, and/or be solely based on
            written submissions, the specific manner shall be chosen by the
            party initiating the arbitration; b) the arbitration shall not
            involve any personal appearance by the parties or witnesses unless
            otherwise mutually agreed by the parties; c) any judgment on the
            award rendered by the arbitrator may be entered in any court of
            competent jurisdiction.
            <br />
            All claims you bring against CastleBird must be resolved in
            accordance with the terms of this Agreement. All claims filed or
            brought contrary to this Agreement shall be considered improperly
            filed and a breach of this Agreement. Should you file a claim
            contrary to the terms of this Agreement, CastleBird may recover its
            legal fees and costs (including in-house lawyers and paralegals),
            provided that CastleBird has notified you in writing of the
            improperly filed claim, and you have failed to promptly withdraw the
            claim.
            <br />
            You agree that you will not pursue any claims arising under this
            User Agreement on a class or other representative basis and will not
            seek to coordinate or consolidate any arbitration or action
            hereunder with any other proceeding.
            <br />
            If any proceeding by or against you is commenced under any provision
            of any bankruptcy or insolvency law, CastleBird will be entitled to
            recover all reasonable costs or expenses (including reasonable legal
            fees and expenses) incurred in connection with the enforcement of
            this Agreement.
            <br />
            CastleBird's failure to act with respect to a breach by you or
            others does not waive our right to act with respect to subsequent or
            similar breaches.
          </p>
        </li>
      </ul>
    </section>
  );
};

export default TermsPage;
