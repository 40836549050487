import "./styles/style.scss";
import useScript from 'hooks/useScript';
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { useContext, useEffect, useRef, useState } from "react";
import PostService from "api/services/PostService";
import UserService from "api/services/UserService";
import ServiceService from "api/services/ServiceService";

import {
  UserInterface,
  PostsInterface,
  ServicesInterface,
} from "types/ApiInterface";
import { StateContext } from "store/Provider";
import AuthService from "api/services/authService";
import LoadingSuspense from "components/Suspense";
import "aos/dist/aos.css";
import PagesService from "api/services/PagesService";
import SectionsService from "api/services/SectionsService";
import ProfileNavbar from "components/NavbarProfile";
import { Swiper as SwiperType } from "swiper";
import Footer from "components/Footer";
import SectionComponent from "components/Sections";
import { Theme } from "pages/Home";
import { Helmet } from "react-helmet";
import Favicon from "react-favicon";

import CB_logo from "assets/icon.png";
import UtilityHelper from "helpers/UtilityHelper";
import { ActionKind } from "store/Reducer";
import ApiConfig from "api/apiConfig";

type HeadbandProps = {
};

const Headband: React.FC<HeadbandProps> = () => {
  const currentDomain = window.location.hostname;

  const handleCustomizeClick = () => {
    window.location.href = `${ApiConfig.APP_URL}/signin?domain=${currentDomain}`;
  };

  return (
    <div className="headband">
      <img src="https://castlebird.com/img/castlebird-logo.webp" alt="Logo" />
      <button onClick={handleCustomizeClick} className="customize-button">Click here to customize</button>
    </div>
  );
};

function PublicProfile() {
  useLocation();
  // useScript("../../js/jquery-3.1.1.min.js");
  // useScript("../../js/flickity.min.js");
  // useScript("../../js/easypiechart.min.js");
  // useScript("../../js/parallax.js");
  // useScript("../../js/typed.min.js");
  // useScript("../../js/datepicker.js");
  // useScript("../../js/isotope.min.js");
  // useScript("../../js/ytplayer.min.js");
  // useScript("../../js/lightbox.min.js");
  // useScript("../../js/granim.min.js");
  // useScript("../../js/jquery.steps.min.js");
  // useScript("../../js/countdown.min.js");
  // useScript("../../js/twitterfetcher.min.js");
  // useScript("../../js/spectragram.min.js");
  // useScript("../../js/smooth-scroll.min.js");
  // useScript("../../js/scripts.js");
  let params = useParams();
  const navigate = useNavigate();

  const swiperRef = useRef<SwiperType>();
  const footerRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<UserInterface>();
  const [site, setSite] = useState();
  const [theme, setTheme] = useState<String | Theme>("default");
  const [isFollow, setIsFollow] = useState<boolean>(false);
  const [followId, setFollowId] = useState<string>("");
  const [pro, setPro] = useState<UserInterface>();
  const [portfolio, setPortfolio] = useState<PostsInterface[]>([]);
  const [services, setServices] = useState<ServicesInterface[]>();
  const [pages, setPages] = useState<Array<any>>([]);
  const [currentPage, setCurrentPage] = useState<any>();
  const [sections, setSections] = useState<Array<any>>([]);
  const [isContact, setIsContact] = useState<boolean>(false);
  const [showHeadband, setShowHeadband] = useState<boolean>(false); // State to control Headband visibility
  const currentUser = AuthService.GetCurrentUser();
  const { state, dispatch } = useContext(StateContext);

  const onContact = () => {
    contactRef.current &&
      contactRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
  };
  const getServices = async (id: string) => {
    try {
      const data = await ServiceService.GetService({ user_id: id });
      setServices(data.sort((a: any, b: any) => a.position - b.position));
    } catch (err) {
      console.log(err);
    }
  };

  const getPages = async (user_id: string) => {
    try {
      const data = await PagesService.GetPages({ user_id: user_id });
      setPages(data);
      if (!params.pagePermaid || params.pagePermaid == "null") {
        const page = data.find((page: { page_type: string }) => page.page_type == "home");
        setCurrentPage(page);
        console.log(currentPage)
        getSections(page.id, page.page_type);
      } else {
        const page = data.find((page: { permaid: string }) => page.permaid == params.pagePermaid);
        if (!!page) {
          setCurrentPage(page);
          console.log(currentPage)
          console.log(data)
          getSections(page.id, page.permaid);
          navigate(`/${page.permaid}`);
        } else {
          navigate("404");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getSections = async (page_id: string, permaid: string) => {
    setLoading(true);
    try {
      const data = await SectionsService.GetSections({ page_id: page_id, permaid: permaid });
      setSections(data);
      !!data.find((section: { template_key: string }) => section.template_key == "contact")
        ? setIsContact(true)
        : setIsContact(false);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    setLoading(true);
    setTheme(UtilityHelper.getTheme());
    UtilityHelper.initApp(state).then((val) => {
      setSite(val.website);
      setUser(val.user);
      dispatch({ type: ActionKind.SET_CURRENT_WEBSITE, payload: val.website });
      dispatch({ type: ActionKind.SET_CURRENT_USER, payload: val.user });
      getPages(val.user.id);
      setLoading(false);
      getServices(val.user.id);
      // Check if user.email contains "preview_user_"
      if (val.user.email.includes("preview_user_")) {
        setShowHeadband(true);
      }
    });
  }, []);

  return (
    <>
      <Favicon url={CB_logo} iconSize={36} />
      {site ? (
        <Helmet>
          <title>{site ? site['name'] : ""}</title>
          <meta
            name="keywords"
            content={`CastleBird, castlebird, CB, building website, building website for free, website building, ${user?.job_data?.name}`}
          />
        </Helmet>
      ) : null}

      <div className={`profile flex-vertical ${theme}`}>
        {showHeadband && <Headband/>}
        {site ? (
          <ProfileNavbar
            current_page={currentPage}
            site={site}
            pro={user}
            pages={pages}
            onChangePage={(page) => {
              setCurrentPage(page);
              if (page.page_type !== 'blog') {
                getSections(page.id, page.permaid);
                navigate(`/${page.permaid}`);
              }
              else {
                // blog
                navigate(`/news`);
              }
            }}
          />
        ) : null}

        {loading ? (
          <LoadingSuspense style={{
            flexGrow: 1,
          }} />

        ) : (
          <div className="sections-container flex-vertical">
            {sections.map((section, index) => (
              <SectionComponent
                key={index}
                section={section}
                pro={user}
                posts={portfolio}
                services={services}
                swiperRef={swiperRef}
                contactRef={contactRef}
                onClick={onContact}
                isContact={isContact}
                theme={theme}
                site={site}
              />
            ))}
          </div>
        )}
        <Outlet />
        {site ? (
          <Footer
            ref={footerRef}
            pro={user}
            site={site}
            pages={pages}
            theme={theme}
            onChangePage={(page: any) => {
              setCurrentPage(page);
              navigate(`/${page.permaid}`);
              getSections(page.id, page.permaid);
            }}
          />
        ) : null}
      </div>
    </>
  );
}



export default PublicProfile;
