import { FunctionComponent } from "react";

import "swiper/css/free-mode";
import FullScreenImage from "components/FullScreenImage";
import { Theme } from "pages/Home";
import "./styles.scss";
import SectionHelper from "helpers/SectionHelper";

export interface IGalleryComponent {
  imageList: any;
  theme: Theme | String;
  textData: any;
  section: any;
}

const GalleryDefault: FunctionComponent<IGalleryComponent> = ({
  imageList,
  textData,
  theme,
  section,
}) => {
  return (
    <div className={"gallery-container flex-vertical default " + SectionHelper.getColorClass(section)}>
      <section className="container gallery-content flex-vertical">
        {!!textData && (!!textData.header || !!textData.description) ? (
          <div className="gallery-text flex-vertical">
            {!!textData.header ? (
              <h2>{textData.header}</h2>
            ) : null}
            {!!textData.description ? (
              <p className="description">{textData.description}</p>
            ) : null}
          </div>
        ) : (
          <h2>Gallery</h2>
        )}
        <div className="gallery-items">
          {imageList.map((image: any, index: number) => (
            <div className="gallery-item" key={index}>
              <FullScreenImage src={image.image_file_data?.image_s} />
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default GalleryDefault;
