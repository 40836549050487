import { FunctionComponent } from "react";

import "./styles.scss";
import SectionHelper from "helpers/SectionHelper";
import { AwardItem } from "..";
import UtilityHelper from "helpers/UtilityHelper";

function AwardDefault({
  section,
  textData,
  dataList,
}: {
  section: any;
  dataList: any;
  textData: any;
}) {
  return (
    <section className={"text-center bg-- " + SectionHelper.getColorClass(section)}>
      {UtilityHelper.isPresent(textData) && UtilityHelper.isPresent(textData.header) ? (
        <div className="text">
          {UtilityHelper.isPresent(textData.header) ? (
            <h3 className="header">{textData.header}</h3>
          ) : null}
          {UtilityHelper.isPresent(textData.description) ? (
            <p className="description">{textData.description}</p>

          ) : null}
        </div>
      ) : <h2>Awards & Recognitions</h2>}

      <div className="container">
        <div className="row">
          {dataList.map((award: any, index: number) => (
            <AwardItem key={index} award={award} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default AwardDefault;
