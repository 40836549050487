import { FunctionComponent, useEffect, useState } from "react";
import FullScreenImage from "components/FullScreenImage";
import "./styles.scss";

const CTAYosha: FunctionComponent<{ section: any }> = ({ section }) => {
  console.log(section);
  if (Array.isArray(section?.data) && section?.data?.length !== 0) {
    return (
      <div
        className={`cta-container yosha flex-vertical ${
          !section.data[0].title && !section.data[0].description
            ? "without-text"
            : ""
        } ${
          !section.data[0].image_file_data ||
          Object.keys(section.data[0].image_file_data).length == 0
            ? "without-image"
            : ""
        }`}
      >
        <section className="cta-content">
          {!!section.data[0].image_file_data &&
          Object.keys(section.data[0].image_file_data).length !== 0 ? (
            <div className="image flex-vertical">
              <FullScreenImage
                src={section.data[0]?.image_file_data?.image_s}
              />
            </div>
          ) : null}
          {!!section.data[0].header || !!section.data[0].description ? (
            <div
              className={`info ${
                !section.data[0].image_file_data ? "without-image" : ""
              }`}
            >
              {!!section.data[0].header && (
                <h2 className="title">{section.data[0].header}</h2>
              )}
              <div className="info-descriptions">
                {!!section.data[0].description &&
                  section.data[0].description
                    .split("\n\n")
                    .map((desItem: any, index: number) => (
                      <p key={index} className="description">
                        {desItem} - {desItem} - {desItem} - {desItem} -{" "}
                        {desItem} - {desItem} - {desItem} - {desItem}
                      </p>
                    ))}
              </div>
            </div>
          ) : null}
          {!!section.data[0].link ? (
            <a
              href={`https://${section.data[0].link.replace("https://", "")}`}
              target="_blank"
              className="link"
            >
              <button>
                <p>Contact Now</p>
              </button>
            </a>
          ) : null}
        </section>
      </div>
    );
  } else {
    return <></>;
  }
};

export default CTAYosha;
