import { FunctionComponent } from "react";

import "swiper/css/free-mode";
import FullScreenImage from "components/FullScreenImage";
import "./styles.scss";
import { IGalleryComponent } from "../GalleryDefault";

const GalleryDefaultBoldRed: FunctionComponent<IGalleryComponent> = ({
  imageList,
  textData,
  theme,
  section,
}) => {
  return (
    <div className={`gallery-container flex-vertical default bold-red`}>
      <section className="gallery-content flex-vertical">
        {/* <p className="title">{site?.name}'s Gallery</p> */}
        {!!textData && (!!textData.header || !!textData.description) ? (
          <div className="gallery-text flex-vertical">
            {!!textData.header ? (
              <h3 className="header">{textData.header}</h3>
            ) : null}
            {!!textData.description ? (
              <p className="description">{textData.description}</p>
            ) : null}
          </div>
        ) : (
          <p className="title">Gallery</p>
        )}
        <div className="gallery-items">
          {imageList.map((image: any, index: number) => (
            <div className="gallery-item" key={index}>
              <FullScreenImage src={image.image_file_data.image_s} />
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default GalleryDefaultBoldRed;
