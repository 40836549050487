import "./styles/style.scss";
import useScript from 'hooks/useScript';
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { useContext, useEffect, useRef, useState } from "react";
import PostService from "api/services/PostService";
import UserService from "api/services/UserService";

import ServiceService from "api/services/ServiceService";

import {
  UserInterface,
  PostsInterface,
  ServicesInterface,
} from "types/ApiInterface";
import AuthService from "api/services/authService";
import LoadingSuspense from "components/Suspense";
import "aos/dist/aos.css";
import PagesService from "api/services/PagesService";
import SectionsService from "api/services/SectionsService";
import { StateContext } from "store/Provider";
import ProfileNavbar from "components/NavbarProfile";
import { Swiper as SwiperType } from "swiper";
import Footer from "components/Footer";
import SectionComponent from "components/Sections";
import { Theme } from "pages/Home";
import { Helmet } from "react-helmet";
import Favicon from "react-favicon";

import CB_logo from "assets/icon.png";
import SectionHelper from "helpers/SectionHelper";
import UtilityHelper from "helpers/UtilityHelper";
import DateTimeHelper from "helpers/DateTimeHelper";
import { BsTranslate } from "react-icons/bs";
import { transform } from "typescript";

<Helmet>
  <script src="../../js/jquery-3.1.1.min.js" type="text/javascript" />
  <script src="../../js/flickity.min.js" type="text/javascript" />
  <script src="../../js/easypiechart.min.js" type="text/javascript" />
  <script src="../../js/parallax.js" type="text/javascript" />
  <script src="../../js/typed.min.js" type="text/javascript" />
  <script src="../../js/datepicker.js" type="text/javascript" />
  <script src="../../js/isotope.min.js" type="text/javascript" />
  <script src="../../js/ytplayer.min.js" type="text/javascript" />
  <script src="../../js/lightbox.min.js" type="text/javascript" />
  <script src="../../js/granim.min.js" type="text/javascript" />
  <script src="../../js/jquery.steps.min.js" type="text/javascript" />
  <script src="../../js/countdown.min.js" type="text/javascript" />
  <script src="../../js/twitterfetcher.min.js" type="text/javascript" />
  <script src="../../js/spectragram.min.js" type="text/javascript" />
  <script src="../../js/smooth-scroll.min.js" type="text/javascript" />
  <script src="../../js/scripts.js" type="text/javascript" />
</Helmet>


function BlogPost() {
  useLocation();
  let params = useParams();
  const navigate = useNavigate();

  const swiperRef = useRef<SwiperType>();
  const footerRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useContext(StateContext);
  const [pages, setPages] = useState<Array<any>>([]);
  const [currentPage, setCurrentPage] = useState<any>({ name: '', updated_at: '' });
  const [sections, setSections] = useState<Array<any>>([]);
  const currentUser = AuthService.GetCurrentUser();

  const getSections = async (page_id: string, permaid: string) => {
    setLoading(true);
    console.log("ACF1");
    const data = await SectionsService.GetSections({
      page_id: page_id,
      permaid: permaid,
    });
    console.log("ACF2");
    setSections(data);
    setLoading(false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
    console.log("ACF");
    setLoading(true);
    UtilityHelper.initApp(state).then((val) => {
      if (params.slug && params.slug != "null") {
        PagesService.GetPages({ user_id: val.user.id, permaid: params.slug }).then(
          (val) => {
            if (!val.length || currentPage['snap_blog_status'] == 'draft') {
              navigate("/news");
            }
            setCurrentPage(val[0]);

            getSections(val[0].id, val[0].permaid);
            setLoading(false);
            console.log(val)
            console.log(currentPage);
          }
        );
      }
      else {
        navigate("404");
      }
    });
  }, []);

  return (
    <>
      <div className="article__body">
        {loading ? (
          <LoadingSuspense style={{
            flexGrow: 1,
          }} />

        ) : (
          <>
            <div className="container" >
              {
                UtilityHelper.isPresent(currentPage.snap_blog?.feature_image)
                  ? <div className="stack-container">
                    <div className="image-container">
                      <img alt="background" style={{ maxHeight: "500px" }} src={currentPage.snap_blog?.feature_image} />
                      <article>
                        <div className="ghost-box">
                          <div className="article__title" >
                            <h1 className="h2 mb-4" style={{ visibility: "hidden" }}>{currentPage.name}</h1>
                            <span style={{ visibility: "hidden" }}>{DateTimeHelper.formatDateString(currentPage.updated_at)}</span>
                            <div className="article__body mt-5">
                              {sections.map((section, index) => (
                                <>
                                  {!!section.data[0].title && (
                                    <h5 style={{ visibility: "hidden" }}>{section.data[0].title}</h5>
                                  )}
                                  {!!section.data[0].description ? (
                                    <p style={{ visibility: "hidden" }}>{section.data[0].description}</p>
                                  ) : null}
                                  {SectionHelper.hasImage(section) ? (
                                    <img style={{ visibility: "hidden" }} alt="image" src={section.data[0]?.image_file_data?.image_s} />
                                  ) : null}
                                </>
                              ))}
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                    <div className="white-box" style={{ opacity: '1' }}>
                      <article>
                        <div className="article__title text-left">
                          <h1 className="h2 mb-4">{currentPage.name}</h1>
                          <span>{DateTimeHelper.formatDateString(currentPage.updated_at)}</span>
                          <div className="article__body mt-5">
                            {sections.map((section, index) => (
                              <>
                                {!!section.data[0].title && (
                                  <h5>{section.data[0].title}</h5>
                                )}
                                {!!section.data[0].description ? (
                                  <p>{section.data[0].description}</p>
                                ) : null}
                                {SectionHelper.hasImage(section) ? (
                                  <img alt="image" src={section.data[0]?.image_file_data?.image_s} />
                                ) : null}
                              </>
                            ))}
                          </div>
                        </div>
                      </article>
                    </div>
                  </div>
                  : <div className="white-box" style={{ position: "relative", top: "0" }}>
                    <article>
                      <div className="article__title text-left">
                        <h1 className="h2 mb-4">{currentPage.name}</h1>
                        <span>{DateTimeHelper.formatDateString(currentPage.updated_at)}</span>
                        <div className="article__body mt-5">
                          {sections.map((section, index) => (
                            <>
                              {!!section.data[0].title && (
                                <h5>{section.data[0].title}</h5>
                              )}
                              {!!section.data[0].description ? (
                                <p>{section.data[0].description}</p>
                              ) : null}
                              {SectionHelper.hasImage(section) ? (
                                <img alt="image" src={section.data[0]?.image_file_data?.image_s} />
                              ) : null}
                            </>
                          ))}
                        </div>
                      </div>
                    </article>
                  </div>
              }

            </div>

          </>
        )}
      </div >
    </>
  );
}



export default BlogPost;
