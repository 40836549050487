import ApiClient from "api/apiClient";
import ApiConfig from "api/apiConfig";

class NotifyPreferencesService {
  static type = "notify_group";
  
  static Get = async (user_key: string) => {
    const url = ApiConfig.REACHSNAP_URL + ApiConfig.NOTIFY_REFERENCE_GET + user_key;
    const response = await ApiClient.sendGet(url, {}, false); 
    return response;
  };


  static Update = async (user_key: string, attributes: any) => {
    const url = ApiConfig.REACHSNAP_URL + ApiConfig.NOTIFY_REFERENCE_UPDATE ;
    const response = await ApiClient.sendPatch(this.type, url, user_key, attributes);
    return response;
  };
}

export default NotifyPreferencesService;
