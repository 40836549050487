import { FunctionComponent, useEffect, useState } from "react";
import FullScreenImage from "components/FullScreenImage";
import "./styles.scss";
// import { Theme } from "pages/Home";
import TextLeftYosha from "./components/TextLeftYosha";
import TextLeftDefault from "./components/TextLeftDefault";
import TextLeftStandard from "./components/TextLeftStandard";
import TextLeftNikesh from "./components/TextLeftNikesh";
import TextLeftDefaultRimal from "./components/TextLeftDefaultRimal";
import TextLeftNoImage from "./components/TextLeftNoImage";
import TextLeftSmall from "./components/TextLeftSmall";
import TextLeftFull from "./components/TextLeftFull";

interface ProfileCustomOneProps {
  section: any;
  theme: String;
}

const ProfileTextLeft: FunctionComponent<ProfileCustomOneProps> = ({
  section,
  theme,
}) => {
  const renderTextLeft = () => {
    switch (theme) {
      case "yosha":
        return <TextLeftYosha section={section} />;
      case "standard":
        return <TextLeftStandard section={section} />;
      // case "intenxfitness":
      //   return <TextLeftNikesh section={section} />;
      case "default rimal":
        return <TextLeftDefaultRimal section={section} />;
      default:
        if (!Array.isArray(section?.data) || section?.data?.length === 0) {
          return <></>;
        }
        if (!section.data[0].image_file_data || Object.keys(section.data[0].image_file_data).length == 0) {
          return <TextLeftNoImage section={section} />;
        }
        if (section.variant == "small") {
          return <TextLeftSmall section={section} />;
        }
        if (section.variant == "full") {
          return <TextLeftFull section={section} />;
        }
        return <TextLeftDefault section={section} />;
    }
  };
  if (Array.isArray(section?.data) && section?.data?.length !== 0) {
    return <>{renderTextLeft()}</>;
  } else {
    return <></>;
  }
};

export default ProfileTextLeft;
