import { FunctionComponent } from "react";
import { randomHSLA } from "pages/PublicProfile/components/FAQ";
import UtilityHelper from "helpers/UtilityHelper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../CustomThreeDefault/styles.scss";
import "./styles.scss";

export interface ICustomThreeTheme {
  section: any;
  customList: any;
  textData: any;
}

const CustomThreeDefaultTaylor: FunctionComponent<ICustomThreeTheme> = ({
  section,
  textData,
  customList,
}) => {
  const imageList = section?.data?.filter((e: any) =>
    UtilityHelper.isPresent(e.image_file_data)
  );
  if (Array.isArray(section?.data) && section?.data?.length !== 0) {
    return (
      <div className="custom-three-container default somebodysgun flex-vertical">
        <section className="custom-three-content flex-vertical">
          {UtilityHelper.isPresent(textData) ? (
            <div className="text">
              {UtilityHelper.isPresent(textData.header) ? (
                <h3 className="header">{textData.header}</h3>
              ) : null}
              {UtilityHelper.isPresent(textData.description) ? (
                <p className="description">{textData.description}</p>
              ) : null}
            </div>
          ) : null}
          {customList.length > 3 ? (
            <Swiper
              loop={false}
              navigation={true}
              pagination={true}
              modules={[Autoplay, Navigation, Pagination]}
              slidesPerView={1}
              spaceBetween={16}
              className="custom-three-swiper"
              breakpoints={{
                768: {
                  slidesPerView: 2.2,
                  spaceBetween: 24,
                },
                1400: {
                  slidesPerView: 3.2,
                  spaceBetween: 24,
                },
              }}
            >
              {customList?.map((item: any, index: number) => (
                <SwiperSlide key={index}>
                  <div
                    className={`custom-item flex-horizontal ${!imageList.length && "without-img"
                      }`}
                  >
                    {!!imageList.length ? (
                      !!item.image_file_data &&
                        Object.keys(item.image_file_data).length !== 0 ? (
                        <div
                          className="image flex-vertical"
                          style={{ backgroundColor: randomHSLA() }}
                        >
                          <img src={item.image_file_data.image_s} alt="image" />
                        </div>
                      ) : (
                        <div
                          className="image flex-vertical"
                          style={{ backgroundColor: randomHSLA() }}
                        ></div>
                      )
                    ) : null}
                    <div className="item-info flex-vertical">
                      {!!item.header ? (
                        <h2 className="title">{item.header}</h2>
                      ) : null}
                      {!!item.description ? (
                        <p key={index} className="description">
                          {item.description}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <div className="custom-items">
              {customList?.map((item: any, index: number) => (
                <div
                  key={index}
                  className={`custom-item flex-horizontal ${!imageList.length && "without-img"
                    }`}
                >
                  {!!imageList.length ? (
                    !!item.image_file_data &&
                      Object.keys(item.image_file_data).length !== 0 ? (
                      <div
                        className="image flex-vertical"
                        style={{ backgroundColor: randomHSLA() }}
                      >
                        <img src={item.image_file_data.image_s} alt="image" />
                      </div>
                    ) : (
                      <div
                        className="image flex-vertical"
                        style={{ backgroundColor: randomHSLA() }}
                      ></div>
                    )
                  ) : null}
                  <div className="item-info flex-vertical">
                    {!!item.header ? (
                      <h2 className="title">{item.header}</h2>
                    ) : null}
                    {!!item.description ? (
                      <p key={index} className="description">
                        {item.description}
                      </p>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          )}
        </section>
      </div>
    );
  } else {
    return <></>;
  }
};

export default CustomThreeDefaultTaylor;
