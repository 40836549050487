import AuthService from "api/services/authService";
import ChatRoomService from "api/services/ChatRoomService";
import { useContext, useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { StateContext } from "store/Provider";
import { ActionKind } from "store/Reducer";
import ListContact from "./ListContact";
import LoadingSuspense from "components/Suspense";
import MessagesService from "api/services/MessageService";
import { useNavigate, useParams } from "react-router-dom";

export function timeSince(date: any) {
  var seconds: any = Math.floor((new Date().valueOf() - date) / 1000);

  var interval = seconds / 31536000;
  if (seconds < 60) {
    return "now";
  }
  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
}

console.log(timeSince(new Date("2023-03-31T08:27:31.890Z")));

export interface ContactProps {
  cached_users: [
    {
      permaid: string;
      role: "provider" | "client";
      user_avatar: string;
      user_id: string;
      user_name: string;
    }
  ];
  created_at: string;
  id: string;
  last_message: string;
  last_message_at: string;
  last_message_from: number;
  last_message_status: string;
  name: string;
  status: "active" | "inactive";
  updated_at: string;
  user_id: number;
}

function MessContact({ contactList }: { contactList: ContactProps[] }) {
  const navigate = useNavigate();
  const { dispatch } = useContext(StateContext);
  const { chat_room_id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState("");
  const permaid = AuthService.GetCurrentUser().permaid;

  const handleSelectMess = (contact: ContactProps) => {
    dispatch({ type: ActionKind.SHOW_CONTENT, payload: true });
    navigate(`${contact.id}`);
  };

  const handleSearch = (e: string) => {
    setSearch(e);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      console.log("loading: false");
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="chat-contact">
      <div className="contact-header">
        <h3>Messages</h3>
        <div className="search-container flex-horizontal">
          <RiSearchLine size={20} />
          <input
            value={search}
            placeholder="Search by name"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="contact-list">
        {loading ? (
          <LoadingSuspense
            style={{
              backgroundColor: "transparent",
              width: "100%",
              height: "calc(100vh - 300px)",
            }}
          />
        ) : (
          contactList
            ?.filter((contact) =>
              contact.cached_users[0].user_name
                .toLowerCase()
                .includes(search.toLowerCase())
            )

            .map((contact, index) => (
              <ListContact
                key={"contact-" + index}
                imgUrl={
                  contact.cached_users.filter(
                    (user) => user.permaid !== permaid
                  )[0]?.user_avatar
                }
                contactName={
                  contact.cached_users.filter(
                    (user) => user.permaid !== permaid
                  )[0]?.user_name
                }
                mess={contact.last_message}
                time={timeSince(new Date(contact.last_message_at))}
                read={contact.last_message_status === "seen" ? true : false}
                status={contact.last_message_from}
                select={chat_room_id === contact.id ? true : false}
                onClick={() => handleSelectMess(contact)}
              />
            ))
        )}
      </div>
      <div className="app-download-tablet lora-font">
        {/* <a href=""> */}
        <h4 className="text-center">
          DOWNLOAD OUR APP TO ACCESS FULL EXPERIENCES
        </h4>
        {/* </a> */}
      </div>
    </div>
  );
}

export default MessContact;
