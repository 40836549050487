import { FunctionComponent, useEffect, useState } from "react";
import FullScreenImage from "components/FullScreenImage";
import { Theme } from "pages/Home";

import "../CTADefault/styles.scss";
import "./styles.scss";

const CTADefaultTaylor: FunctionComponent<{
  section: any;
  theme: Theme | String;
}> = ({ section, theme }) => {
  const randomColor = "#" + (((1 << 24) * Math.random()) | 0).toString(16);

  if (Array.isArray(section?.data) && section?.data?.length !== 0) {
    return (
      <div
        className={`cta-container default somebodysgun flex-vertical ${
          !section.data[0].header && !section.data[0].description
            ? "without-text"
            : ""
        } ${
          !section.data[0].image_file_data ||
          Object.keys(section.data[0].image_file_data).length == 0
            ? "without-image"
            : ""
        }`}
      >
        <section className="cta-content">
          {!!section.data[0].image_file_data &&
          Object.keys(section.data[0].image_file_data).length !== 0 ? (
            <div className="image flex-vertical">
              <img src={section.data[0]?.image_file_data?.image_s} />
            </div>
          ) : null}
          {!!section.data[0].header || !!section.data[0].description ? (
            <div
              className={`info ${
                !section.data[0].image_file_data ? "without-image" : ""
              }`}
            >
              {!!section.data[0].header && (
                <h2 className="title">{section.data[0].header}</h2>
              )}
              {!!section.data[0].description ? (
                <p className="description">{section.data[0].description}</p>
              ) : null}
              {!!section.data[0].link ? (
                <a
                  href={`https://${section.data[0].link.replace(
                    "https://",
                    ""
                  )}`}
                  target="_blank"
                  className="link"
                >
                  <button>
                    <p>GET STARTED TODAY</p>
                  </button>
                </a>
              ) : null}
            </div>
          ) : null}
        </section>
      </div>
    );
  } else {
    return <></>;
  }
};

export default CTADefaultTaylor;
