import { FunctionComponent, useEffect, useState } from "react";
import "./styles.scss";
import FullScreenImage from "components/FullScreenImage";
import { Theme } from "pages/Home";
import CustomOneDefault from "./components/CustomOneDefault";
import CustomOneStandard from "./components/CustomOneStandard";
import CustomOneYosha from "./components/CustomOneYosha";
import CustomOneNikesh from "./components/CustomOneNikesh";
import CustomOneDefaultBoldRed from "./components/CustomOneDefaultRimal";
import CustomOneNoImage from "./components/CustomOneNoImage";
import CustomOneSmallerImage from "./components/CustomOneSmallerImage";
import CustomOneFull from "./components/CustomOneFull";
import CustomOneCenter from "./components/CustomOneCenter";

interface ProfileCustomOneProps {
  section: any;
  theme: Theme | String;
}

const ProfileCustomOne: FunctionComponent<ProfileCustomOneProps> = ({
  section,
  theme,
}) => {
  const renderCustomOne = () => {
    switch (theme) {
      case "default rimal":
        return <CustomOneDefaultBoldRed section={section} />;
      case "standard":
        return <CustomOneStandard section={section} />;
      case "yosha":
        return <CustomOneYosha section={section} />;
      // case "intenxfitness":
      //   return <CustomOneNikesh section={section} />;
      default:
        if (!Array.isArray(section?.data) || section?.data?.length === 0) {
          return <></>;
        }
        if (!section.data[0].image_file_data || Object.keys(section.data[0].image_file_data).length == 0) {
          return <CustomOneNoImage section={section} />;
        }
        if (section.variant == "small") {
          return <CustomOneSmallerImage section={section} />;
        }
        if (section.variant == "full") {
          return <CustomOneFull section={section} />;
        }
        if (section.variant == "center") {
          return <CustomOneCenter section={section} />;
        }
        return <CustomOneDefault section={section} />;
    }
  };
  if (Array.isArray(section?.data) && section?.data?.length !== 0) {
    return <>{renderCustomOne()}</>;
  } else {
    return <></>;
  }
};

export default ProfileCustomOne;
