// import "App.scss";
import MPService from "api/services/MixpanelService";
import Building from "./components/2.Benefits";
import Devices from "./components/3.Create";
import Features from "./components/6.Tools";
import Hero from "./components/1.Hero";
import Professionals from "./components/7.Professionals";
import Showcase from "./components/4.Centralized";
import { useContext, useEffect, useRef, useState } from "react";
import UserService from "api/services/UserService";
import PublicProfile from "pages/PublicProfile";
import LoadingSuspense from "components/Suspense";
import LandingForm from "./components/9.Form";
import NavBar from "components/Navbar";
import Deliver from "./components/5.Deliver";

import "./styles/style.scss";
import { UserInterface } from "types/ApiInterface";
import { StateContext } from "store/Provider";
import { ActionKind } from "store/Reducer";
import { Helmet } from "react-helmet";
import Favicon from "react-favicon";

import CB_logo from "assets/icon.png";
import UtilityHelper from "helpers/UtilityHelper";

export type Theme = "standard" | "default" | "yosha" | "other";

function Home() {
  const [isHome, setIsHome] = useState(true);
  const [user, setUser] = useState<UserInterface>();
  const [site, setSite] = useState();
  const [theme, setTheme] = useState<String | Theme>("default");
  const [loading, setLoading] = useState(true);
  const formRef = useRef<HTMLInputElement>(null);
  const { state, dispatch } = useContext(StateContext);

  useEffect(() => {
    setLoading(true);
    setTheme(UtilityHelper.getTheme());

    UtilityHelper.initApp(state).then((val) => {
      setLoading(false);
      if (!val.website) return setIsHome(true);

      setSite(val.website);
      setUser(val.user);
      dispatch({ type: ActionKind.SET_CURRENT_WEBSITE, payload: val.website });
      dispatch({ type: ActionKind.SET_CURRENT_USER, payload: val.user });
      setIsHome(false);
    });
  }, []);

  const clickToForm = () => {
    formRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    formRef.current?.focus();
    formRef.current?.classList.add(`animation_trigger`);
  };

  const setHome: () => JSX.Element = () => {
    if (isHome) {
      return (
        <>
          <Favicon url={CB_logo} />
          <Helmet>
            <title>CastleBird - The app for professional</title>
            <meta
              name="description"
              content="CastleBird - The App for Professionals. Design a beautiful professional showcase; tell your story; chat, video calls with your clients, via One Simple App."
            />
            <meta
              name="keywords"
              content="CastleBird, castlebird, CB, building website, building website for free, website building"
            />
          </Helmet>
          {/* <div className="home flex-vertical">
            <NavBar />
            <Hero onClick={clickToForm} />
            <Building />
            <Devices onClick={clickToForm} />
            <Showcase onClick={clickToForm} />
            <Deliver onClick={clickToForm} />
            <Features />
            <Professionals />
            <LandingForm ref={formRef} />
          </div> */}
        </>
      );
    } else {
      return <PublicProfile />;
    }
  };

  return <>{loading ? <LoadingSuspense /> : setHome()}</>;
  // return <>{loading ? <section className="text-center ">
  //   <div className="container">
  //     <div className="row">
  //       <div className="col-md-12 col-lg-10">
  //         <video
  //           // src={section.data[0].video_file_data.video_url}
  //           autoPlay muted loop
  //         >
  //           <source
  //             src='http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/Sintel.mp4'
  //             type="video/mp4"
  //           />
  //         </video>

  //       </div>
  //     </div>

  //   </div>

  // </section> : setHome()}</>;

  // if (loading) {
  //   return <LoadingSuspense />;
  // } else {
  //   if (isHome) {
  //     return (
  //       <div className="home flex-vertical">
  //         <NavBar />
  //         <Hero onClick={clickToForm} />
  //         <Building />
  //         <Devices onClick={clickToForm} />
  //         <Showcase onClick={clickToForm} />
  //         <Deliver onClick={clickToForm} />
  //         <Features />
  //         <Professionals />
  //         <LandingForm ref={formRef} />
  //       </div>
  //     );
  //   } else {
  //     return <PublicProfile user={user} site={site} theme={theme} />;
  //   }
  // }
}

export default Home;
