
class DateTimeHelper {
    static formatDateString(dateString: string): string {
        const date = new Date(dateString);
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const day = date.getUTCDate();
        const month = months[date.getUTCMonth()];
        const year = date.getUTCFullYear();

        let daySuffix;
        if (day % 10 === 1 && day !== 11) {
            daySuffix = 'st';
        } else if (day % 10 === 2 && day !== 12) {
            daySuffix = 'nd';
        } else if (day % 10 === 3 && day !== 13) {
            daySuffix = 'rd';
        } else {
            daySuffix = 'th';
        }

        return `${month} ${day}${daySuffix} ${year}`;
    }
}

export default DateTimeHelper;