import { FunctionComponent, useEffect, useState } from "react";
import FullScreenImage from "components/FullScreenImage";
import "../CustomOneDefault/styles.scss";
import "./styles.scss";

const CustomOneDefaultRimal: FunctionComponent<{ section: any }> = ({
  section,
}) => {
  return (
    <div
      className={`custom-one-container default rimal flex-vertical ${
        !section.data[0].title && !section.data[0].description
          ? "without-text"
          : ""
      } ${
        !section.data[0].image_file_data ||
        Object.keys(section.data[0].image_file_data).length == 0
          ? "without-image"
          : ""
      }`}
    >
      <section className="custom-one-content">
        {!!section.data[0].image_file_data ? (
          <div className="image flex-vertical">
            <FullScreenImage src={section.data[0].image_file_data.image_s} />
          </div>
        ) : null}
        {!!section.data[0].title || !!section.data[0].description ? (
          <div
            className={`info ${
              !section.data[0].image_file_data ? "without-image" : ""
            }`}
          >
            {!!section.data[0].title && (
              <h2 className="title">{section.data[0].title}</h2>
            )}
            {!!section.data[0].description ? (
              <p className="description">{section.data[0].description}</p>
            ) : null}
          </div>
        ) : null}
      </section>
    </div>
  );
};

export default CustomOneDefaultRimal;
