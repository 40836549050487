// import IntroVid from "assets/PublicProfile/hero/intro.png";
import PlayBtn from "assets/PublicProfile/hero/play-circle.png";
import { BsFillPlayCircleFill } from "react-icons/bs";

import "./style.scss";
import { Theme } from "pages/Home";
import SectionHelper from "helpers/SectionHelper";

interface IntroHeroProps {
    section: any;
    theme: Theme | String;
}

function IntroHero({ section, theme }: Partial<IntroHeroProps>) {
    if (Array.isArray(section?.data) && section?.data.length != 0) {
        return (
            <section className="intro-container flex-vertical">
                <div className="intro-content flex-vertical">
                    <video
                        // src={section.data[0].video_file_data.video_url}
                        style={{ width: "100%" }}
                        controls
                    >
                        <source
                            src={section?.data[0]?.video_file_data?.video_url}
                            type="video/mp4"
                        />
                    </video>
                </div>
            </section>
        );
    } else {
        return <></>;
    }
}

export default IntroHero;
