import { FunctionComponent } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

import "./styles.scss";
import "swiper/css";
import "swiper/css/pagination";
import SectionHelper from "helpers/SectionHelper";

const ClientSwiper: FunctionComponent<{ section: any }> = ({ section }) => {
  return (
    <div className="client-container flex-vertical other">
      <section className={`client-content default ` + SectionHelper.getColorClass(section)}>
        <h2 className="text-center">Partners & Clients</h2>
        <div className="client-images">
          <Swiper
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            spaceBetween={20}
            slidesPerView={2}
            centeredSlides={true}
            loop={true}
            breakpoints={{
              768: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              1440: {
                slidesPerView: 5,
                spaceBetween: 50,
              },
            }}
            className="client-swiper"
          >
            {section.data?.map((logo: any, index: number) => (
              <SwiperSlide key={index}>
                <div className="client-image">
                  <img src={logo.image_file_data?.image_s} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </div>
  );
};

export default ClientSwiper;
