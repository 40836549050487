import { FunctionComponent, useEffect, useState } from "react";
import FullScreenImage from "components/FullScreenImage";
import { Theme } from "pages/Home";

import "./styles.scss";
import SectionHelper from "helpers/SectionHelper";
import { ParallaxBanner } from "react-scroll-parallax";
import { wrap } from "module";

const CTAParallaxFull: FunctionComponent<{
    section: any;
    theme: Theme | String;
}> = ({ section, theme }) => {
    if (Array.isArray(section?.data) && section?.data?.length !== 0) {
        return (
            <section className={"text-left imagebg " + SectionHelper.getColorClass(section)}>
                {!!section.data[0].image_file_data && Object.keys(section.data[0].image_file_data).length !== 0 ? (

                    <ParallaxBanner
                        layers={[
                            { image: section.data[0]?.image_file_data?.image_s, speed: -40 },
                        ]}
                        className="aspect-[2/1] h-[100%] justify-content-center flex"
                    >
                        {!!section.data[0].header || !!section.data[0].description ? (
                            <div className="container justify-content-center flex" style={{ width: '100%', height: '100%' }}>
                                <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <div className="col-md-8 col-lg-6" style={{ textAlign: 'center' }}>
                                        <div className="cta text-center">
                                            {!!section.data[0].header && (
                                                <h2>{section.data[0].header}</h2>
                                            )}
                                            {!!section.data[0].description && (
                                                <p className="lead" style={{ fontWeight: '400' }}>{section.data[0].description}</p>
                                            )}
                                            {!!section.data[0].link && (
                                                <a
                                                    href={`https://${section.data[0].link.replace(
                                                        "https://",
                                                        ""
                                                    )}`}
                                                    target="_blank"
                                                    className="btn btn--primary type--uppercase justify-center"
                                                >
                                                    <span className="btn__text">
                                                        Contact now
                                                    </span>
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </ParallaxBanner>

                ) : null
                }


            </section >
        );
    } else {
        return <></>;
    }
};

export default CTAParallaxFull;
