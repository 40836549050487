import { FunctionComponent } from "react";
import "swiper/css/free-mode";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { IGalleryComponent } from "../GalleryDefault";
import "./styles.scss";
import SectionHelper from "helpers/SectionHelper";

const GalleryStandard: FunctionComponent<IGalleryComponent> = ({
  imageList,
  textData,
  theme,
  section,
}) => {
  return (
    <section className={"gallery-container standard " + SectionHelper.getColorClass(section)}>
      <div className="gallery-content">
        {!!textData && (!!textData.header || !!textData.description) ? (
          <div className="gallery-text flex-vertical">
            {!!textData.header ? (
              <h2>{textData.header}</h2>
            ) : null}
            {!!textData.description ? (
              <p className="description">{textData.description}</p>
            ) : null}
          </div>
        ) : (
            <h2 className="text-center">Gallery</h2>
        )}
        <Swiper
          spaceBetween={0}
          slidesPerView={1.5}
          centeredSlides={true}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          breakpoints={{
            1440: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
          }}
          className="gallery-swiper"
        >
          {imageList?.map((image: any, index: number) => (
            <SwiperSlide key={index}>
              <div className="gallery-item" key={index}>
                <img src={image.image_file_data?.image_s} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default GalleryStandard;
