import ApiConfig from "api/apiConfig";
import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";

interface RestrictedProps {
  children: ReactNode; // Define the type for children prop
}

const Restricted: React.FC<RestrictedProps> = ({ children }) => {
  const allowedDomain = ApiConfig.MAIN_DOMAIN;
  const localhost = "localhost";
  const currentHost = window.location.hostname;

  if (currentHost === allowedDomain || currentHost == localhost) {
    // If the current hostname is the allowed domain, render the route
    return <>{children}</>;
  } else {
    // If not, redirect to the home page or show a 404 page
    return <Navigate to="/" replace />;
  }
};

export default Restricted;
