import UpdateCardIntenxFitness from "./WorkCardIntenxFitness";
import "./styles.scss";

interface WorkProps {
  pro: any;
  posts: any;
  theme: String;
  site: any;
}

function BlogIntenxFitness({ pro, posts, theme, site }: Partial<WorkProps>) {
  return (
    <section className="update-container intenxfitness">
      <div className="update-items flex-horizontal">
        {posts.map((post: any, index: number) => (
          <UpdateCardIntenxFitness
            key={index}
            post={post}
            pro={pro}
            site={site}
          />
        ))}
      </div>
    </section>
  );
}

export default BlogIntenxFitness;
