import React, { useEffect, useRef } from 'react';
import lottie, { AnimationItem } from 'lottie-web';

interface LottieBackgroundProps {
  animationData: any; 
  className?: string;
}

const LottieBackground: React.FC<LottieBackgroundProps> = ({ animationData }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const animationInstance = useRef<AnimationItem | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      animationInstance.current = lottie.loadAnimation({
        container: containerRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animationData,
      });
    }

    return () => {
      if (animationInstance.current) {
        animationInstance.current.destroy(); 
      }
    };
  }, [animationData]);

  return (
    <div
      ref={containerRef}
      style={{
        // position: 'fixed',
        // top: 0,
        // left: 0,
        width: '100%',
        height: '100%',
        marginTop: '-80px',
        zIndex: 1, 
        pointerEvents: 'none',
      }}
    />
  );
};

export default LottieBackground;
export {};

