import UpdateCardStandard from "./WorkCardStandard";
import "./styles.scss";

interface WorkProps {
  pro: any;
  posts: any;
  theme: String;
  site: any;
}

function BlogStandard({ pro, posts, theme, site }: Partial<WorkProps>) {
  return (
    <div className="update-container flex-vertical standard">
      <section className="update-content flex-vertical">
        <p>From Customers &</p>
        <p className="title">Their Success Stories</p>
        <div className="update-items flex-horizontal">
          {posts.map((post: any, index: number) => (
            <UpdateCardStandard key={index} post={post} pro={pro} site={site} />
          ))}
        </div>
        {/* <button>
            <p>View more stories</p>
          </button> */}
      </section>
    </div>
  );
}

export default BlogStandard;
