import { Swiper, SwiperSlide } from "swiper/react";
import { ForwardedRef, FunctionComponent, useEffect, useRef, useState } from "react";
import { Autoplay, Swiper as SwiperType } from "swiper";
import open_quote from "assets/Home/new/open_quote.png";
import close_quote from "assets/Home/new/close_quote.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "../Testimonial/style.scss";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { Theme } from "pages/Home";
import TestimonialJame from "./component/TestimonialJame";
import TestimonialDefault from "./component/TestimonialDefault";
import TestimonialNikesh from "./component/TestimonialNikesh";
import TestimonialStandard from "./component/TestimonialStandard";
import TestimonialDefaultRimal from "./component/TestimonialDefaultRimal";

interface TestimonialCardProps {
  testimonial: any;
}

export const TestimonialCard: FunctionComponent<TestimonialCardProps> = ({
  testimonial,
}) => {
  return (
    <div className="testimonial row justify-content-center">
      <div className="col-lg-2 col-md-4 col-6 text-center">
        <img className="testimonial__image" alt="Image" src={`${!!testimonial.image_file_data
          ? testimonial.image_file_data?.image_s
          : ""
          }`} />
      </div>
      <div className="col-lg-7 col-md-8 col-12">
        <span className="h3">&ldquo;{testimonial.description}&rdquo;
        </span>
        <h5>{testimonial.name}</h5>
        <span>{testimonial.position}</span>
      </div>
    </div>
    // <div className="testimonial-card flex-vertical">
    //   <div className="content">
    //     <img className="open-quote" src={open_quote} loading="lazy" />
    //     <img className="close-quote" src={close_quote} loading="lazy" />
    //     <p>{testimonial.description}</p>
    //   </div>
    //   <div className="description flex-horizontal">
    //     <div className="avatar flex-vertical">
    //       <img
    //         src={`${
    //           !!testimonial.image_file_data
    //             ? testimonial.image_file_data?.image_s
    //             : ""
    //         }`}
    //         alt="image"
    //         loading="lazy"
    //       />
    //     </div>
    //     <div className="info flex-vertical">
    //       <p className="name">{testimonial.name}</p>
    //       <p className="position">{testimonial.position}</p>
    //     </div>
    //   </div>
    // </div>
  );
};

interface TestimonialProps {
  section: any;
  theme: Theme | String;
}


const Testimonial: FunctionComponent<TestimonialProps> = ({
  section,
  theme,
}) => {
  const [dataList, setDataList] = useState([]);
  const [textData, setTextData] = useState({
    type: "text",
    header: "",
    description: "",
  });

  useEffect(() => {
    if (Array.isArray(section?.data)) {
      setDataList(section?.data.filter((item: any) => !item.type));
      setTextData(section?.data.find((item: any) => !!item.type));
    }
  }, []);

  const renderTestimonial = () => {
    switch (theme) {
      case "rimal":
        return <TestimonialDefaultRimal section={section} dataList={dataList}
          textData={textData} />;
      case "freemium":
        return <TestimonialJame section={section} dataList={dataList}
          textData={textData} />;
      // case "intenxfitness":
      //   return <TestimonialNikesh section={section} dataList={dataList}
      //     textData={textData} />;
      case "standard":
        return <TestimonialStandard section={section} dataList={dataList}
          textData={textData} />;
      default:
        return <TestimonialDefault section={section} dataList={dataList}
          textData={textData} />;
    }
  };
  if (Array.isArray(section?.data) && section?.data?.length !== 0) {
    return <>{renderTestimonial()}</>;
  } else {
    return <></>;
  }
};

export default Testimonial;