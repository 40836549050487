import Close from "assets/Navbar/close.png";
import useClickOutside from "hooks/useClickOutside";
import { useRef, useEffect } from "react";
import { Link, useMatch } from "react-router-dom";
import { UserInterface } from "types/ApiInterface";

import "./style.scss";

interface NavbarMobileProps {
  isOpen: boolean;
  isLogin: boolean;
  user?: UserInterface;
  onClick: () => void;
  logOut: () => void;
  pages: any;
  current_page: any;
  onChangePage: (page: any) => void;
  site: any;
}

function ProfileNavMobile({
  isOpen,
  onClick,
  current_page,
  user,
  isLogin,
  logOut,
  pages,
  onChangePage,
  site,
}: NavbarMobileProps) {

  const navbar = useRef<HTMLDivElement>(null);

  const match = useMatch("/");

  const onFeatures = () => {
    const feature = document.querySelector(".section-2");
    feature?.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    console.log(current_page)

    console.log('asdasd /{current_page}')

    console.log(pages)
  }, []);

  const onAbout = () => {
    const about = document.querySelector(".section-5");
    about?.scrollIntoView({
      behavior: "smooth",
    });
  };
  const onContact = () => {
    const contact = document.querySelector(".section-9");
    contact?.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <div
      className={`nav-mobile-container ${isOpen ? "visible " : "hidden"}`}
      ref={navbar}
    >
      {/* <div className="top-bar flex-horizontal">
        <h3>{site.name}</h3>
        <img
          className="nav-mobile-menu"
          id="menu-mobile"
          src={Close}
          alt="Close icon"
          onClick={onClick}
          loading="lazy"
        />
      </div> */}
      <ul className="page-list">
        {pages.map((page: any, index: number) => (
          <li key={index}>
            <span
              className={`text-capitalized ${current_page?.id == page?.id ? "active-page" : ""
                }`}
              onClick={() => {
                onChangePage(page);
                onClick();
              }}
            >
              {page.name}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ProfileNavMobile;
