import { FunctionComponent } from "react";

import "./styles.scss";
import SocialIcon from "../convertSocial";
import { SocialProps } from "../SocialDefault";

const SocialFremium: FunctionComponent<SocialProps> = ({ section }) => {
  return (
    <section className={`social-container flex-vertical freemium`}>
      <p className="title">Follow Me On</p>
      <div className="flex-horizontal social-items">
        {section.data.map((link: any, index: number) => (
          <SocialIcon key={index} social={link} />
        ))}
      </div>
    </section>
  );
};

export default SocialFremium;
