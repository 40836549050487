import { FunctionComponent, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import Logo1 from "assets/New folder/logo_b12.png";
import Logo2 from "assets/New folder/logo_invision (2).png";
import Logo3 from "assets/New folder/logo_pmr.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "../ClientLogo/style.scss";
import FullScreenImage from "components/FullScreenImage";
import { Theme } from "pages/Home";
import ClientSwiper from "./component/ClientSwiper";
import ClientDefault from "./component/ClientStack";
import ClientGrid from "./component/ClientGrid";
import ClientStandard from "./component/ClientStandard";
import ClientSwiperTaylor from "./component/ClientSwiperTaylor";
import ClientStack from "./component/ClientStack";

interface ClientLogoProps {
  section: any;
  theme: Theme | String;
}

const ClientLogo: FunctionComponent<ClientLogoProps> = ({ section, theme }) => {
  const renderClientLogo = () => {
    switch (theme) {
      case "other":
       return <ClientSwiper section={section} />;
      case "default somebodysgun":
        return <ClientSwiperTaylor section={section} />;
      case "default rimal":
        return <ClientSwiper section={section} />;
      case "standard":
        return <ClientStandard section={section} />;
      default:
        if (section.variant == "small") {
          return <ClientStack section={section} />;
        }
        if (section.variant == "grid") {
          return <ClientGrid section={section} />;
        }
        return <ClientSwiper section={section} />;
    }
  };

  if (Array.isArray(section.data) && section?.data?.length !== 0) {
    return <>{renderClientLogo()}</>;
  } else {
    return <></>;
  }
};

export default ClientLogo;
