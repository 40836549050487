import { FunctionComponent } from "react";
import "./styles.scss";
import SectionHelper from "helpers/SectionHelper";

const ClientStack: FunctionComponent<{ section: any }> = ({ section }) => {
  return (
    <section className={`client-container default text-center ` + SectionHelper.getColorClass(section)}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <ul className="list-inline list-inline--images">
              {section.data?.map((logo: any, index: number) => (
                <li>
                  <img src={logo.image_file_data?.image_s} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientStack;
