import { FunctionComponent, useEffect, useState } from "react";
import "swiper/css/free-mode";
import "./style.scss";
import { Theme } from "pages/Home";
import GalleryDefault from "./components/GalleryDefault";
import GalleryStandard from "./components/GalleryStandard";
import GalleryDefaultBoldRed from "./components/GalleryDefaultBoldRed";
import GalleryDefaultTaylor from "./components/GalleryDefaultTaylor";

interface ProfileGalleryProps {
  section: any;
  theme: Theme | String;
  site: any;
}

const ProfileGallery: FunctionComponent<ProfileGalleryProps> = ({
  section,
  theme,
  site,
}) => {
  const [imageList, setImageList] = useState([]);
  const [textData, setTextData] = useState({
    type: "text",
    header: "",
    description: "",
  });

  useEffect(() => {
    if (Array.isArray(section?.data)) {
      setImageList(section?.data.filter((item: any) => !item.type));
      setTextData(section?.data.find((item: any) => !!item.type));
    }
  }, []);
  const renderGallery = () => {
    switch (theme) {
      case "default bold-red":
        return (
          <GalleryDefaultBoldRed
            imageList={imageList}
            theme={theme}
            textData={textData}
            section={section}
          />
        );
      case "default somebodysgun":
        return (
          <GalleryDefaultTaylor
            imageList={imageList}
            theme={theme}
            textData={textData}
            section={section}
          />
        );
      case "standard":
        return (
          <GalleryStandard
            imageList={imageList}
            theme={theme}
            textData={textData}
            section={section}
          />
        );

      default:
        if (section.variant == "sliding") {
          return <GalleryStandard section={section} imageList={imageList} theme={theme} textData={textData} />;
        }
        return <GalleryDefault section={section} imageList={imageList} theme={theme} textData={textData} />
    }
  };

  if (Array.isArray(section?.data) && section?.data?.length !== 0) {
    return <>{renderGallery()}</>;
  } else {
    return <></>;
  }
};

export default ProfileGallery;
