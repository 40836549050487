import { FunctionComponent, useEffect, useState } from "react";
import FullScreenImage from "components/FullScreenImage";
import "./styles.scss";
import UtilityHelper from "helpers/UtilityHelper";
import { ProfileCustomOneProps } from "../HeroLeftDefault";

const HeroLeftFreemium: FunctionComponent<ProfileCustomOneProps> = ({
  section,
  imageList,
}) => {
  return (
    <div
      className={`hero-left-container freemium flex-vertical ${
        !section.data[0]?.title && !section.data[0]?.description
          ? "without-text"
          : ""
      } ${UtilityHelper.isPresent(imageList) ? "" : "without-image"}`}
    >
      <section className="hero-left-content">
        {!UtilityHelper.isPresent(imageList) ? null : (
          <div className="image flex-vertical">
            <FullScreenImage src={imageList[1]?.image_file_data?.image_s} />
          </div>
        )}
        {!!section.data[0]?.title || !!section.data[0]?.description ? (
          <div
            className={`info ${
              !section.data[0]?.image_file_data ? "without-image" : ""
            }`}
          >
            {!!section.data[0]?.title && (
              <h2 className="title">{section.data[0]?.title}</h2>
            )}

            {!!section.data[0]?.description ? (
              <p className="description">{section.data[0]?.description}</p>
            ) : null}
          </div>
        ) : null}
      </section>
    </div>
  );
};

export default HeroLeftFreemium;
