import { useState } from "react";
import { PostsInterface } from "types/ApiInterface";

import "./styles.scss";
import { IoMdAdd } from "react-icons/io";
import { BiMinus } from "react-icons/bi";
import { Theme } from "pages/Home";
import SectionHelper from "helpers/SectionHelper";
import UtilityHelper from "helpers/UtilityHelper";

function FaqDefault({ section,
  textData,
  dataList, }: {
    section: any;
    dataList: any;
    textData: any;
  }) {
  const [showFAQ, setShowFAQ] = useState<Array<number>>([0]);
  return (
    <div className={"process-container flex-vertical default " + SectionHelper.getColorClass(section)}>
      <section className="process-content container">
        {UtilityHelper.isPresent(textData) && UtilityHelper.isPresent(textData.header) ? (
          <div>
            {UtilityHelper.isPresent(textData.header) ? (
              <h2>{textData.header}</h2>
            ) : null}
            {UtilityHelper.isPresent(textData.description) ? (
              <p>{textData.description}</p>

            ) : null}
          </div>
        ) : <h2>Frequently Asked Question</h2>}
        <div className="process-items flex-vertical">
          {dataList.map((faq: any, index: number) => (
            <div
              key={index}
              onClick={() => {
                if (showFAQ.includes(index)) {
                  const newFAQ = showFAQ.filter((faq) => faq !== index);
                  setShowFAQ([...newFAQ]);
                } else {
                  setShowFAQ([...showFAQ, index]);
                }
              }}
              className={`process-item flex-horizontal ${showFAQ.includes(index) ? "show" : ""
                }`}
            >
              <div className={`question-item flex-horizontal `}>
                <p className="question">{faq?.question}</p>
                {showFAQ.includes(index) ? (
                  <BiMinus className="question-btn" size={24} />
                ) : (
                  <IoMdAdd className="question-btn" size={24} />
                )}
              </div>
              <p className="answer">{faq?.answer}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default FaqDefault;
