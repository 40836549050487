import { FunctionComponent } from "react";
import FullScreenImage from "components/FullScreenImage";
import { randomHSLA } from "pages/PublicProfile/components/FAQ";

import "./styles.scss";

interface ProfileCustomThreeProps {
  section: any;
}

const CustomThreeStandard: FunctionComponent<ProfileCustomThreeProps> = ({
  section,
}) => {
  if (Array.isArray(section?.data) && section?.data?.length !== 0) {
    return (
      <div className="custom-three-container standard flex-vertical">
        <section className="custom-three-content flex-vertical">
          {/* <div className="title">Selected Works</div> */}
          <div className="custom-items">
            {section.data?.map((item: any, index: number) => (
              <div key={index} className="custom-item flex-horizontal">
                {!!item.image_file_data &&
                Object.keys(item.image_file_data).length !== 0 ? (
                  <div
                    className="image flex-vertical"
                    style={{ backgroundColor: randomHSLA() }}
                  >
                    <FullScreenImage src={item.image_file_data?.image_s} />
                  </div>
                ) : null}

                <div className="item-info flex-vertical">
                  {!!item.header ? (
                    <h2 className="title">{item.header}</h2>
                  ) : null}
                  {!!item.description ? (
                    <p key={index} className="description">
                      {item.description}
                    </p>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    );
  } else {
    return <></>;
  }
};

export default CustomThreeStandard;
