import ApiClient from "api/apiClient";
import ApiConfig from "api/apiConfig";

class AccountService {
  static type = "account";

  static GetAccounts = async (where: any) => {
    const url = ApiConfig.API_URL + ApiConfig.ACCOUNT_INDEX;

    try {
      const response = await ApiClient.sendGet(url, where, false);
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  static GetAccount = async (account_id: any) => {
    const url = ApiConfig.API_URL + ApiConfig.ACCOUNT_INDEX + account_id;

    try {
      const response = await ApiClient.sendGet(url, {}, false);
      return response;
    } catch (error) {
      console.error(error);
    }
  };
}

export default AccountService;
