import AccountService from "api/services/AccountService";
import WebsiteService from "api/services/SiteService";
import posthog from 'posthog-js';

class TrackerHelper {
  static virtualPage(url: any, name: any) {
    posthog.capture('Custom Page View', {
      url: url,
      name: name,
    });
  };
}

export default TrackerHelper;
