import { FunctionComponent, useEffect, useState } from "react";
import "./styles.scss";
import FullScreenImage from "components/FullScreenImage";
import { randomHSLA } from "../FAQ";
import { Swiper, SwiperSlide } from "swiper/react";
import CustomThreeDefault from "./component/CustomThreeDefault";
import CustomThreeStandard from "./component/CustomThreeStandard";
import { Theme } from "pages/Home";
import CustomThreeYosha from "./component/CustomThreeYosha";
import CustomThreeJames from "./component/CustomThreeJames";
import CustomThreeDefaultTaylor from "./component/CustomThreeTaylor";

interface ProfileCustomThreeProps {
  section: any;
  theme: Theme | String;
}

const ProfileCustomThree: FunctionComponent<ProfileCustomThreeProps> = ({
  section,
  theme,
}) => {
  const [customList, setCustomList] = useState([]);
  const [textData, setTextData] = useState({
    type: "text",
    header: "",
    description: "",
  });

  useEffect(() => {
    if (Array.isArray(section?.data)) {
      setCustomList(section?.data.filter((item: any) => !item.type));
      setTextData(section?.data.find((item: any) => !!item.type));
    }
  }, []);

  const renderCustomThree = () => {
    switch (theme) {
      case "standard":
        return <CustomThreeStandard section={section} />;
      case "yosha":
        return (
          <CustomThreeYosha
            section={section}
            customList={customList}
            textData={textData}
          />
        );
      case "freemium":
        return (
          <CustomThreeJames
            section={section}
            customList={customList}
            textData={textData}
          />
        );
      case "default somebodysgun":
        return (
          <CustomThreeDefaultTaylor
            section={section}
            customList={customList}
            textData={textData}
          />
        );
      default:
        return (
          <CustomThreeDefault
            section={section}
            customList={customList}
            textData={textData}
          />
        );
    }
  };

  if (Array.isArray(section?.data) && section?.data?.length !== 0) {
    return <>{renderCustomThree()}</>;
  } else {
    return <></>;
  }
};

export default ProfileCustomThree;
