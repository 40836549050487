import { createContext, useReducer } from "react";
import reducer, {
  GlobalStateInterface,
  initialState,
  ActionType,
} from "./Reducer";

interface IContextProps {
  state: GlobalStateInterface;
  dispatch: (action: ActionType) => void;
}
export const StateContext = createContext({} as IContextProps);

export const StateProvider = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <StateContext.Provider value={value}>
      {props.children}
    </StateContext.Provider>
  );
};
