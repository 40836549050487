import { Theme } from "pages/Home";
import ProfileAwards from "pages/PublicProfile/components/Awards";
import ProfileCTA from "pages/PublicProfile/components/CTA";
import ClientLogo from "pages/PublicProfile/components/ClientLogo";
import ProfileContact from "pages/PublicProfile/components/Contact";
import ProfileCustomOne from "pages/PublicProfile/components/CustomOne";
import ProfileCustomThree from "pages/PublicProfile/components/CustomThree";
import ProfileSocial from "pages/PublicProfile/components/Follow";
import ProfileGallery from "pages/PublicProfile/components/Gallery";
import Hero from "pages/PublicProfile/components/Hero";
import ProfileHeroCentered from "pages/PublicProfile/components/HeroCentered";
import ProfileHeroLeftAlign from "pages/PublicProfile/components/HeroLeftAlign";
import Info from "pages/PublicProfile/components/Info";
import Intro from "pages/PublicProfile/components/Intro";
import ProfileMaps from "pages/PublicProfile/components/Maps";
import Process from "pages/PublicProfile/components/FAQ";
import Service from "pages/PublicProfile/components/Service";
import Testimonial from "pages/PublicProfile/components/Testimonial";
import ProfileTextLeft from "pages/PublicProfile/components/TextLeft";
import Work from "pages/PublicProfile/components/Work";
import { FunctionComponent, useState } from "react";
import './SectionComponent.scss'; // Import the SCSS file
import ApiConfig from "api/apiConfig";

interface SectionComponentProps {
  section: any;
  pro: any;
  posts?: any;
  services?: any;
  swiperRef?: any;
  contactRef?: any;
  onClick?: any;
  isContact?: boolean;
  theme: Theme | String;
  site: any;
}

const SectionComponent: FunctionComponent<SectionComponentProps> = ({
  section,
  pro,
  posts,
  services,
  swiperRef,
  contactRef,
  onClick,
  isContact,
  theme,
  site,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  // Check if pro.email contains "preview-user-"
  const shouldShowOverlay = pro.email && pro.email.includes("preview_user_");

  const renderContent = () => {
    switch (section.template_key) {
      case "hero":
        return Hero({ pro, section, theme, onClick, isContact });
      case "bio":
        return Info({ section, pro, theme, site });
      case "client_logos":
        return ClientLogo({ section, theme });
      case "posts":
        return Work({ pro, posts, theme, site });
      case "service":
        return Service({ section, theme });
      case "contact":
        return ProfileContact({ section, theme }, contactRef);
      case "faq":
        return Process({ section, theme });
      case "map":
        return ProfileMaps({ section, theme });
      case "awards":
        return ProfileAwards({ section, theme });
      case "testimonials":
        return Testimonial({ section, theme });
      case "social_icons":
        return ProfileSocial({ section, theme });
      case "intro_video":
        return Intro({ section, theme });
      case "gallery":
        return ProfileGallery({ section, theme, site });
      case "custom_one_column":
        return ProfileCustomOne({ section, theme });
      case "custom_three_column":
        return ProfileCustomThree({ section, theme });
      case "text_left":
        return ProfileTextLeft({ section, theme });
      case "call_to_action":
        return ProfileCTA({ section, theme });
      case "hero_generic_1":
        return ProfileHeroCentered({ section, theme });
      case "hero_generic_2":
        return ProfileHeroLeftAlign({ section, theme });
      default:
        return <></>;
    }
  };

  const handleEditButtonClick = () => {
    const currentDomain = window.location.hostname;
    const redirectUrl = `${ApiConfig.APP_URL}/signin?domain=${currentDomain}#login_web`;
    window.location.href = redirectUrl;
  };

  return (
    <div
      className={`section-overlay-container ${shouldShowOverlay && isHovered ? 'hovered' : ''}`}
      onMouseEnter={() => shouldShowOverlay && setIsHovered(true)}
      onMouseLeave={() => shouldShowOverlay && setIsHovered(false)}
    >
      {renderContent()}
      {shouldShowOverlay && isHovered && (
        <div className="overlay">
          <button className="edit-button" onClick={handleEditButtonClick}>
            Edit this section
          </button>
        </div>
      )}
    </div>
  );
};

export default SectionComponent;
