import { FunctionComponent, useEffect, useState } from "react";
import FullScreenImage from "components/FullScreenImage";
import "./styles.scss";
import { Theme } from "pages/Home";
import UtilityHelper from "helpers/UtilityHelper";
import HeroCenteredStandard from "./components/HeroCenteredStandard";
import HeroCenteredFreemium from "./components/HeroCenteredFreemium";
import HeroCenteredDefault from "./components/HeroCenteredDefault";
import HeroCenteredDefaultTaylor from "./components/HeroCenteredTaylor";

interface ProfileCustomOneProps {
  section: any;
  theme: Theme | String;
}

const ProfileHeroCentered: FunctionComponent<ProfileCustomOneProps> = ({
  section,
  theme,
}) => {
  const [imageList, setImageList] = useState<Array<any>>([]);
  useEffect(() => {
    if (UtilityHelper.isPresent(section?.data)) {
      setImageList(
        section?.data?.filter((e: any) =>
          UtilityHelper.isPresent(e?.image_file_data)
        )
      );
    } else {
      setImageList([]);
    }
  }, []);

  const renderHero = () => {
    switch (theme) {
      case "standard":
        return <HeroCenteredStandard section={section} imageList={imageList} />;
      case "freemium":
        return <HeroCenteredFreemium section={section} imageList={imageList} />;
      case "default somebodysgun":
        return (
          <HeroCenteredDefaultTaylor section={section} imageList={imageList} />
        );
      default:
        return <HeroCenteredDefault section={section} imageList={imageList} />;
    }
  };
  if (Array.isArray(section?.data) && section?.data?.length !== 0) {
    if (section?.data?.length > 1) {
      return <>{renderHero()}</>;
    } else {
      if (UtilityHelper.isPresent(section?.data[0])) {
        return <>{renderHero()}</>;
      } else {
        return <></>;
      }
    }
  } else {
    return <></>;
  }
};

export default ProfileHeroCentered;
