// import IntroVid from "assets/PublicProfile/hero/intro.png";
import PlayBtn from "assets/PublicProfile/hero/play-circle.png";
import { BsFillPlayCircleFill } from "react-icons/bs";

import "./style.scss";
import { Theme } from "pages/Home";
import SectionHelper from "helpers/SectionHelper";

interface IntroVideoProps {
    section: any;
    theme: Theme | String;
}

function IntroVideo({ section, theme }: Partial<IntroVideoProps>) {
    if (Array.isArray(section?.data) && section?.data.length != 0) {
        return (
            <section className={`text-center ` + SectionHelper.getColorClass(section)}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-10">
                            <video
                                src={section.data[0].video_file_data.video_url}
                                style={{ width: "100%" }}
                                controls
                            >
                                <source
                                    src={section?.data[0]?.video_file_data?.video_url}
                                    type="video/mp4"
                                />
                            </video>

                        </div>
                    </div>

                </div>

            </section>
        );
    } else {
        return <></>;
    }
}

export default IntroVideo;
