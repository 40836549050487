import { ForwardedRef, forwardRef } from "react";
import "./styles.scss";
import { Theme } from "pages/Home";
import ClientDefault from "../ClientLogo/component/ClientStack";
import ContactStandard from "./components/ContactStandard";
import ContactDefault from "./components/ContactDefault";
import ContactJames from "./components/ContactJames";
import ContactYosha from "./components/ContactYosha";
import ContactJustine from "./components/ContactJustine";
import ContactNikesh from "./components/ContactNikesh";
import ContactDefaultRimal from "./components/ContactDefaultRimal";

interface ProfileContactProps {
  section: any;
  theme: Theme | String;
}

function ProfileContact(
  { section, theme }: ProfileContactProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const renderContact = () => {
    switch (theme) {
      case "freemium":
        return <ContactJames section={section} ref={ref} />;
      case "rimal":
        return <ContactDefaultRimal section={section} ref={ref} />;
      // case "intenxfitness":
      //   return <ContactNikesh section={section} ref={ref} />;
      case "standard":
        return <ContactStandard section={section} ref={ref} />;
      case "yosha":
        return <ContactYosha section={section} ref={ref} />;
      default:
        return <ContactDefault section={section} ref={ref} />;
    }
  };
  if (Array.isArray(section?.data) && section?.data?.length !== 0) {
    return <>{renderContact()}</>;
  } else {
    return <></>;
  }
}

export default ProfileContact;
