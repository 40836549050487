import { ForwardedRef, Ref } from "react";
import "../ContactDefault/styles.scss";
import "./styles.scss";

function ContactDefaultRimal({
  section,
  ref,
}: {
  section: any;
  ref: Ref<HTMLDivElement>;
}) {
  return (
    <div className={`contact-container flex-vertical default rimal`}>
      <section ref={ref} className="contact-content flex-horizontal">
        {!!section.data[0].text && (
          <p className="title">{section.data[0].text}</p>
        )}
        <div className="info-items flex-vertical">
          {!!section.data[0].email && (
            <div className="info-item">
              <p className="label">Email</p>
              <div className="info">{section.data[0].email}</div>
            </div>
          )}
          {!!section.data[0].phone && (
            <div className="info-item">
              <p className="label">Phone Number</p>
              <p className="info">{section.data[0].phone}</p>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}

export default ContactDefaultRimal;
