import { FunctionComponent, useEffect, useState } from "react";
import FullScreenImage from "components/FullScreenImage";
import "./styles.scss";
import SectionHelper from "helpers/SectionHelper";

const TextLeftDefault: FunctionComponent<{ section: any }> = ({ section }) => {
  return (
    <section className={"imageblock switchable--switch feature-large " + SectionHelper.getColorClass(section)}>
      <div className="imageblock__content col-lg-6 col-md-4 pos-right">
        <div className="background-image-holder" style={{ backgroundColor: "rgba(0, 0, 0, 0)", backgroundPosition: "initial", backgroundRepeat: "repeat", backgroundAttachment: "scroll", backgroundImage: "url(" + section.data[0]?.image_file_data?.image_s + ")", backgroundSize: "auto", backgroundOrigin: "padding-box", backgroundClip: "border-box", opacity: "1" }}>
          <img alt="image" src={section.data[0]?.image_file_data?.image_s} />
        </div>
      </div>
      {!!section.data[0].title || !!section.data[0].description ? (
        <div className="container">
          <div className="row ">
            <div className="col-lg-5 col-md-7">
              {!!section.data[0].title && (
                <h2>{section.data[0].title}</h2>
              )}
              {!!section.data[0].description ? (
                <p className="lead">{section.data[0].description}</p>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </section>
  );
};

export default TextLeftDefault;
