import "./style.scss";
import HeroDefault from "./component/HeroDefault";
import HeroStandard from "./component/HeroStandard";
import { Theme } from "pages/Home";
import HeroYosha from "./component/HeroYosha";
import UtilityHelper from "helpers/UtilityHelper";
import { useState, useEffect } from "react";

interface ProfileHeroProps {
  pro?: any;
  section: any;
  onClick: any;
  isContact: boolean;
  theme: Theme | String;
}

function Hero({ pro, section, theme }: Partial<ProfileHeroProps>) {
  const [imageList, setImageList] = useState<Array<any>>([]);
  useEffect(() => {
    if (UtilityHelper.isPresent(section?.data)) {
      setImageList(
        section?.data?.filter((e: any) =>
          UtilityHelper.isPresent(e?.image_file_data)
        )
      );
    } else {
      setImageList([]);
    }
  }, []);

  const renderHero = () => {
    switch (theme) {
      case "standard":
        return (
          <HeroStandard pro={pro} section={section} imageList={imageList} />
        );
      case "yosha":
        return <HeroYosha pro={pro} section={section} imageList={imageList} />;
      default:
        return (
          <HeroDefault pro={pro} section={section} imageList={imageList} />
        );
    }
  };

  if (Array.isArray(section?.data) && section?.data?.length !== 0) {
    if (section?.data?.length > 1) {
      return <>{renderHero()}</>;
    } else {
      if (UtilityHelper.isPresent(section?.data[0])) {
        return <>{renderHero()}</>;
      } else {
        return <></>;
      }
    }
  } else {
    return <></>;
  }
}

export default Hero;
