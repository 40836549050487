import UpdateCardDefault from "./WorkCardDefault";
import "./styles.scss";

interface WorkProps {
  pro: any;
  posts: any;
  theme: String;
  site: any;
}

function BlogDefault({ pro, posts, theme, site }: Partial<WorkProps>) {
  return (
    <section className="update-container default">
      {/* <p className="title">Updates from {site?.name}</p> */}
      <p className="title">Blogs</p>
      <div className="update-items flex-horizontal">
        {posts.map((post: any, index: number) => (
          <UpdateCardDefault key={index} post={post} pro={pro} site={site} />
        ))}
      </div>
    </section>
  );
}

export default BlogDefault;
