import React, { useEffect, useState } from 'react';
import { Box, Text, Spinner, VStack } from '@chakra-ui/react';
import { Button } from 'primereact/button';
import AuthService from 'api/services/authService';
import WebsiteService from 'api/services/SiteService';
import { ProgressSpinner } from 'primereact/progressspinner';
import LottieBackground from './lottie/Lottie';
import LottieAnimationData from './lottie/animation.json'
import TrackerHelper from 'helpers/TrackerHelper';

interface FinalStepProps {
  selectedBusinessTag: string | null;
  selectedBusinessName: string | null;
  location: string;
  latLng: string;
}

const FinalStep: React.FC<FinalStepProps> = ({
  selectedBusinessTag,
  selectedBusinessName,
  location,
  latLng,
}) => {
  const [loading, setLoading] = useState(true);
  const [statusText, setStatusText] = useState<string>('');
  const [currentWebsite, setCurrentWebsite] = useState<any>(null);

  useEffect(() => {
    const updateWebsiteData = async () => {
      try {
        const currentWebsite = await WebsiteService.getCurrentSite();
        setCurrentWebsite(currentWebsite);

        const updateData = {
          business_type: selectedBusinessTag,
          business_name: selectedBusinessName,
          name: selectedBusinessName,
          business_location: location,
          business_latlng: latLng,
        };

        await WebsiteService.Update(currentWebsite.id, updateData);
        await WebsiteService.getAiContent({ ai_action: 'generate_pages' });
      } catch (error) {
        console.error('Error updating website:', error);
      }
    };

    const displayStatusText = () => {
      const texts = [
        'Initiating your website...',
        'Writing content...',
        'Selecting themes and images...',
        'Our AI is still working hard...',
      ];
      let i = 0;

      const interval = setInterval(() => {
        setStatusText(texts[i]);
        i += 1;
        if (i >= texts.length) {
          clearInterval(interval);
        }
      }, 2000);
    };

    displayStatusText();

    const checkCompletion = async () => {
      try {
        const timeoutPromise = new Promise<void>((resolve) => {
          setTimeout(() => resolve(), 10000);
        });

        await Promise.race([updateWebsiteData(), timeoutPromise]);
      } catch (error) {
        console.error("Error during update:", error);
      } finally {
        setLoading(false);
      }
    };


    checkCompletion();

    TrackerHelper.virtualPage("/onboarding/step_final", "Onboarding - Generating");
  }, [selectedBusinessTag, selectedBusinessName, location, latLng]);

  useEffect(() => {
    const onboardingCard = document.querySelector('.onboarding-card') as HTMLElement;
    const container = document.querySelector('.onboarding-container') as HTMLElement;
    if (loading && container && onboardingCard) {
      // container.style.background = 'none';
      // onboardingCard.style.boxShadow='none';
      // onboardingCard.style.background = 'transparent';
    } else {
      container.style.background = '';
      onboardingCard.style.boxShadow = 'rgba(149, 157, 165, 0.2) 0px 8px 24px';
      onboardingCard.style.background = '#fafafa';
    }

  }, [loading]);

  const handleViewWebsite = () => {
    const domain = WebsiteService.getSiteDomain(currentWebsite);
    if (domain) {
      window.location.href = domain;
    }
  };

  return (
    <Box textAlign="center" p={5}>
      {loading ? (
        <VStack spacing={4}>
          {/* <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" /> */}
          <LottieBackground animationData={LottieAnimationData} className="lottie-background" />
          <Text
            style={{ fontSize: '24px', fontWeight: '500', color: 'black', marginTop: '-40px' }}
            key={statusText}
            animation="fadeIn 0.5s ease-in-out forwards"
            opacity={0} /* Initially hide text */
            sx={{
              '@keyframes fadeIn': {
                '0%': { opacity: 0 },
                '100%': { opacity: 1 },
              },
            }}
          >
            {statusText}
          </Text>
        </VStack>
      ) : (
        <VStack spacing={4}>
          <div className="title">Your website is ready!</div>
          <Button
            label="View my website"
            className="p-button-primary generate-button"
            onClick={handleViewWebsite}
            style={{ backgroundColor: '#2318DD', fontWeight: '600', color: '#fafafa', padding: '12px 40px' }}
          />
        </VStack>
      )}
    </Box>
  );
};

export default FinalStep;
