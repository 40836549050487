import { FunctionComponent } from "react";
import "./styles.scss";

const ClientStandard: FunctionComponent<{ section: any }> = ({ section }) => {
  return (
    <div className={`client-container standard`}>
      <section className="client-content">
        <p className="title">Partners & Clients</p>
        <div className="client-images">
          {section.data?.map((logo: any, index: number) => (
            <div className="client-image" key={index}>
              <img src={logo.image_file_data.image_url} />
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default ClientStandard;
