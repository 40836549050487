import { FunctionComponent } from "react";
import { randomHSLA } from "pages/PublicProfile/components/FAQ";
import UtilityHelper from "helpers/UtilityHelper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./styles.scss";
import SectionHelper from "helpers/SectionHelper";

export interface IServiceTheme {
  section: any;
  customList: any;
  textData: any;
}

const ServiceDefault: FunctionComponent<IServiceTheme> = ({
  section,
  textData,
  customList,
}) => {
  const imageList = section?.data?.filter((e: any) =>
    UtilityHelper.isPresent(e.image_file_data)
  );
  if (Array.isArray(section?.data) && section?.data?.length !== 0) {
    let mainBody;

    if (section?.variant == "centered_text") {
      mainBody = (<section className={"custom-three-content flex-vertical text-center " + SectionHelper.getColorClass(section)}>
        <div className="container">
          <div className="row">
            {customList?.map((item: any, index: number) => (
              <div className="col-md-4">
                <div className="feature feature-1">
                  {!!imageList.length ?
                    (!!item.image_file_data && Object.keys(item.image_file_data).length !== 0 ? (
                      <img alt="Image" src={item.image_file_data.image_s} />
                    ) : null
                    ) : null}
                  <div className="feature__body boxed boxed--lg boxed--border">
                    {!!item.header ? (
                      <h3>{item.header}</h3>
                    ) : null}
                    {!!item.description ? (
                      <p className="lead">
                        {item.description}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>)
    }
    else if (section?.variant == "background_with_header") {
      mainBody = (<section className={"custom-three-content flex-vertical " + SectionHelper.getColorClass(section)}>
        <div className="container">
          <div className="row">
            {customList?.map((item: any, index: number) => (
              <div className="col-md-4 col-6">

                <div className="feature feature-7 boxed text-center imagebg" data-overlay="3">

                  {!!imageList.length ?
                    (!!item.image_file_data && Object.keys(item.image_file_data).length !== 0 ? (
                      <div className="background-image-holder" style={{ backgroundColor: "rgba(0, 0, 0, 0)", backgroundPosition: "initial", backgroundRepeat: "repeat", backgroundAttachment: "scroll", backgroundImage: "url(" + item.image_file_data.image_s + ")", backgroundSize: "auto", backgroundOrigin: "padding-box", backgroundClip: "border-box", opacity: "1" }}>
                        <img alt="background" src={item.image_file_data.image_s} className="border--round" />
                      </div>
                    ) : null
                    ) : null}

                  {!!item.header ? (
                    <h4 className="pos-vertical-center">{item.header}</h4>
                  ) : null}
                </div>

              </div>
            ))}
          </div>
        </div>
      </section>)
    } else if (section?.variant == "column_header_des") {
      mainBody = (<section className={"custom-three-content flex-vertical text-center " + SectionHelper.getColorClass(section)}>
        <div className="container">
          <div className="row">
            {customList?.map((item: any, index: number) => (
              <div className="col-md-4">
                <div className="feature">
                  {!!imageList.length ?
                    (!!item.image_file_data && Object.keys(item.image_file_data).length !== 0 ? (
                      <img alt="Image" src={item.image_file_data.image_s} className="border--round" />
                    ) : null
                    ) : null}
                  {!!item.header ? (
                    <h4>{item.header}</h4>
                  ) : null}
                  {!!item.description ? (
                    <p className="lead">
                      {item.description}
                    </p>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>)
    }
    else {
      mainBody = (<section className={"custom-three-content flex-vertical " + SectionHelper.getColorClass(section)}>
        <div className="container">
          <div className="row">
            {customList?.map((item: any, index: number) => (
              <div className="col-md-4">
                <div className="feature">
                  {!!imageList.length ?
                    (!!item.image_file_data && Object.keys(item.image_file_data).length !== 0 ? (
                      <img alt="Image" src={item.image_file_data.image_s} className="border--round" />
                    ) : null
                    ) : null}

                  {!!item.header ? (
                    <h4>{item.header}</h4>
                  ) : null}
                  {!!item.description ? (
                    <p >
                      {item.description}
                    </p>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>)
    }

    return (
      <div className="custom-three-container default flex-vertical">
        <section className={"custom-three-content flex-vertical " + SectionHelper.getColorClass(section)}>
          {UtilityHelper.isPresent(textData) && UtilityHelper.isPresent(textData.header) ? (
            <div className="text-center">
              {UtilityHelper.isPresent(textData.header) ? (
                <h2>{textData.header}</h2>
              ) : null}
              {UtilityHelper.isPresent(textData.description) ? (
                <p className="lead">{textData.description}</p>
              ) : null}
            </div>
          ) : null}
          <div className="container">
            <div className="row">
              {customList?.map((item: any, index: number) => (
                <div className="col-md-4">
                  <div className="feature">
                    {!!imageList.length ?
                      (!!item.image_file_data && Object.keys(item.image_file_data).length !== 0 ? (
                        <img alt="Image" src={item.image_file_data.image_s} className="border--round" />
                      ) : null
                      ) : null}

                    {!!item.header ? (
                      <h4>{item.header}</h4>
                    ) : null}
                    {!!item.description ? (
                      <p >
                        {item.description}
                      </p>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    );
  } else {
    return <></>;
  }
};

export default ServiceDefault;
