import { FunctionComponent } from "react";
import FullScreenImage from "components/FullScreenImage";
import { randomHSLA } from "pages/PublicProfile/components/FAQ";
import { ICustomThreeTheme } from "../CustomThreeDefault";

import "./styles.scss";

const CustomThreeYosha: FunctionComponent<ICustomThreeTheme> = ({
  customList,
  textData,
}) => {
  if (Array.isArray(customList) && customList?.length !== 0) {
    return (
      <div className="custom-three-container yosha flex-vertical">
        <section className="custom-three-content flex-vertical">
          {/* <div className="title">Selected Works</div> */}
          {!!textData && !!textData.header && !!textData.description ? (
            <div className="custom-text">
              {!!textData.header ? <h3>{textData.header}</h3> : null}
              {!!textData.description ? <p>{textData.description}</p> : null}
            </div>
          ) : null}
          <div className="custom-items">
            {customList?.map((item: any, index: number) => (
              <div key={index} className="custom-item flex-horizontal">
                {!!item.image_file_data &&
                Object.keys(item.image_file_data).length !== 0 ? (
                  <div
                    className="image flex-vertical"
                    style={{ backgroundColor: randomHSLA() }}
                  >
                    <FullScreenImage src={item.image_file_data?.image_s} />
                  </div>
                ) : null}
                <div className="item-info flex-vertical">
                  {!!item.header ? (
                    <h2 className="title">{item.header}</h2>
                  ) : null}
                  {!!item.description ? (
                    <p key={index} className="description">
                      {item.description}
                    </p>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    );
  } else {
    return <></>;
  }
};

export default CustomThreeYosha;
