import { FunctionComponent, useEffect, useState } from "react";
import FullScreenImage from "components/FullScreenImage";

import { Theme } from "pages/Home";
import UtilityHelper from "helpers/UtilityHelper";

import "./styles.scss";

export interface ProfileCustomOneProps {
  section: any;
  imageList: Array<any>;
}

const HeroCenteredDefault: FunctionComponent<ProfileCustomOneProps> = ({
  section,
  imageList,
}) => {
  return (
    <div
      className={`hero-centered-container default flex-vertical ${
        !section.data[0]?.title && !section.data[0]?.description
          ? "without-text"
          : ""
      } ${UtilityHelper.isPresent(imageList) ? "" : "without-img"}`}
    >
      <section className="hero-centered-content">
        {!!section.data[0]?.title || !!section.data[0]?.description ? (
          <div
            className={`info ${
              !section.data[0]?.image_file_data ? "without-image" : ""
            }`}
          >
            {!!section.data[0]?.title && (
              <h2 className="title">{section.data[0]?.title}</h2>
            )}
            {!!section.data[0].description ? (
              <p className="description">{section.data[0].description}</p>
            ) : null}
          </div>
        ) : null}
        {UtilityHelper.isPresent(imageList) ? (
          <div className="image flex-vertical">
            <img
              src={
                section.data[section.data.length - 1].image_file_data?.image_s
              }
            />
          </div>
        ) : null}
      </section>
    </div>
  );
};

export default HeroCenteredDefault;
