import { useState } from "react";
import { Theme } from "pages/Home";
import "../BioLeft/styles.scss";
import "./styles.scss";

export interface InfoProps {
  section: any;
  pro: any;
  theme: Theme | String;
  site: any;
}

function BioDefaultRimal({ section, pro, site }: Partial<InfoProps>) {
  return (
    <div className="bio-container default rimal flex-vertical">
      <section className="bio-content flex-horizontal">
        {!!section.data[0]?.header ? (
          <p className="title">{section.data[0].header}</p>
        ) : !!site.name ? (
          <p className="title">About {site?.name}</p>
        ) : (
          <p className="title">About {pro?.name}</p>
        )}
        {!!section.data[0].description ? (
          <p className="description">{section.data[0].description}</p>
        ) : null}
      </section>
    </div>
  );
}

export default BioDefaultRimal;
