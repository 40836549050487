import { useState } from "react";
import { Theme } from "pages/Home";
import "./styles.scss";
import UtilityHelper from "helpers/UtilityHelper";
import SectionHelper from "helpers/SectionHelper";

export interface InfoProps {
  section: any;
  pro: any;
  theme: Theme | String;
  site: any;
}

function BioCenter({ section, pro, site }: Partial<InfoProps>) {
  return (
    <section className={`text-center ` + SectionHelper.getColorClass(section)}>
        <div className="container">
            <div className="row">
                <div className="col-md-10 col-lg-8">
                    {!!section.data[0]?.header ? (
                      <h2>{section.data[0].header}</h2>
                    ) : null}
                    {!!section.data[0].description ? (
                      <p className="lead">{section.data[0].description}</p>
                    ) : null}
                </div>
            </div>
        </div>
    </section>
  );
}

export default BioCenter;
