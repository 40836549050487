import axios from "axios";
import ApiConfig from "api/apiConfig";
import ApiClient from "api/apiClient";

class UserReachSnapService {
  static type = "user";

  static GetUser = async (where: any) => {
    const url = ApiConfig.REACHSNAP_URL + ApiConfig.USER_INDEX;

    try {
      const response = await ApiClient.sendGet(url, where, false);
      return response;
    } catch (error) {
      console.error(error);
    }
  };

 
}

export default UserReachSnapService;
