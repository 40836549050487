import { FunctionComponent } from "react";
import "./styles.scss";
import SectionHelper from "helpers/SectionHelper";

const ClientGrid: FunctionComponent<{ section: any }> = ({ section }) => {
  return (
    <section className={`client-container default text-center ` + SectionHelper.getColorClass(section)}>
      <div className="container">
        <div className="client-content">
          <h2 className="text-center">Partners & Clients</h2>
          <div className="client-images">
            {section.data?.map((logo: any, index: number) => (
              <div className="client-image" key={index}>
                <img src={logo.image_file_data?.image_s} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientGrid;
