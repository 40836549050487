import { FunctionComponent } from "react";
import { randomHSLA } from "pages/PublicProfile/components/FAQ";
import UtilityHelper from "helpers/UtilityHelper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./styles.scss";
import SectionHelper from "helpers/SectionHelper";

export interface ICustomThreeTheme {
  section: any;
  customList: any;
  textData: any;
}

const CustomThreeDefault: FunctionComponent<ICustomThreeTheme> = ({
  section,
  textData,
  customList,
}) => {
  const imageList = section?.data?.filter((e: any) =>
    UtilityHelper.isPresent(e.image_file_data)
  );
  if (Array.isArray(section?.data) && section?.data?.length !== 0) {
    let mainBody;
    

    if (section?.variant == "centered_text") {
      mainBody = (
      <section className={"custom-three-content flex-vertical text-center " + SectionHelper.getColorClass(section)}>
          {UtilityHelper.isPresent(textData) && UtilityHelper.isPresent(textData.header) ? (
          <div className="text mb-0">
            {UtilityHelper.isPresent(textData.header) ? (
              <h2>{textData.header}</h2>
            ) : null}
            {UtilityHelper.isPresent(textData.description) ? (
              <p className="description mt-0">{textData.description}</p>
            ) : null}
          </div>
          ) : null}
        <div className="container">
          <div className="row">
            {customList?.map((item: any, index: number) => (
              <div className="col-md-4">
                <div className="feature feature-1">
                  {!!imageList.length ?
                    (!!item.image_file_data && Object.keys(item.image_file_data).length !== 0 ? (
                      <img alt="Image" src={item.image_file_data.image_s} />
                    ) : null
                    ) : null}
                  <div className="feature__body boxed boxed--lg boxed--border">
                    {!!item.header ? (
                      <h3>{item.header}</h3>
                    ) : null}
                    {!!item.description ? (
                      <p className="lead">
                        {item.description}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>)
    }
    else if (section?.variant == "background_with_header") {
      mainBody = (<section className={"custom-three-content flex-vertical " + SectionHelper.getColorClass(section)}>
        {UtilityHelper.isPresent(textData) && UtilityHelper.isPresent(textData.header) ? (
          <div className="text mb-0">
            {UtilityHelper.isPresent(textData.header) ? (
              <h2>{textData.header}</h2>
            ) : null}
            {UtilityHelper.isPresent(textData.description) ? (
              <p className="description mt-0">{textData.description}</p>
            ) : null}
          </div>
        ) : null}
        <div className="container">
          <div className="row">
            {customList?.map((item: any, index: number) => (
              <div className="col-md-4 col-6">

                <div className="feature feature-7 boxed text-center imagebg" data-overlay="3">

                  {!!imageList.length ?
                    (!!item.image_file_data && Object.keys(item.image_file_data).length !== 0 ? (
                      <div className="background-image-holder" style={{ backgroundColor: "rgba(0, 0, 0, 0)", backgroundPosition: "initial", backgroundRepeat: "repeat", backgroundAttachment: "scroll", backgroundImage: "url(" + item.image_file_data.image_s + ")", backgroundSize: "auto", backgroundOrigin: "padding-box", backgroundClip: "border-box", opacity: "1" }}>
                        <img alt="background" src={item.image_file_data.image_s} className="border--round" />
                      </div>
                    ) : null
                    ) : null}

                  {!!item.header ? (
                    <h4 className="pos-vertical-center">{item.header}</h4>
                  ) : null}
                </div>

              </div>
            ))}
          </div>
        </div>
      </section>)
    } else if (section?.variant == "column_header_des") {
      mainBody = (<section className={"custom-three-content flex-vertical text-center " + SectionHelper.getColorClass(section)}>
        {UtilityHelper.isPresent(textData) && UtilityHelper.isPresent(textData.header) ? (
          <div className="text mb-0">
            {UtilityHelper.isPresent(textData.header) ? (
              <h2>{textData.header}</h2>
            ) : null}
            {UtilityHelper.isPresent(textData.description) ? (
              <p className="description mt-0">{textData.description}</p>
            ) : null}
          </div>
        ) : null}
        <div className="container">
          <div className="row">
            {customList?.map((item: any, index: number) => (
              <div className="col-md-4">
                <div className="feature">
                  {!!imageList.length ?
                    (!!item.image_file_data && Object.keys(item.image_file_data).length !== 0 ? (
                      <img alt="Image" src={item.image_file_data.image_s} className="border--round" />
                    ) : null
                    ) : null}
                  {!!item.header ? (
                    <h4>{item.header}</h4>
                  ) : null}
                  {!!item.description ? (
                    <p className="lead">
                      {item.description}
                    </p>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>)
    }
    else {
      mainBody = (<section className={"custom-three-content flex-vertical " + SectionHelper.getColorClass(section)}>
        {UtilityHelper.isPresent(textData) && UtilityHelper.isPresent(textData.header) ? (
          <div className="text mb-0">
            {UtilityHelper.isPresent(textData.header) ? (
              <h2>{textData.header}</h2>
            ) : null}
            {UtilityHelper.isPresent(textData.description) ? (
              <p className="description mt-0">{textData.description}</p>
            ) : null}
          </div>
        ) : null}
        <div className="container">
          <div className="row">
            {customList?.map((item: any, index: number) => (
              <div className="col-md-4">
                <div className="feature">
                  {!!imageList.length ?
                    (!!item.image_file_data && Object.keys(item.image_file_data).length !== 0 ? (
                      <img alt="Image" src={item.image_file_data.image_s} className="border--round" />
                    ) : null
                    ) : null}

                  {!!item.header ? (
                    <h4>{item.header}</h4>
                  ) : null}
                  {!!item.description ? (
                    <p >
                      {item.description}
                    </p>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>)
    }

    return (
      <div className={"custom-three-container default mt-24 " + SectionHelper.getColorClass(section) }>
        
        {mainBody}


        {/* {customList.length > 3 ? (
            <Swiper
              loop={false}
              navigation={true}
              pagination={true}
              modules={[Autoplay, Navigation, Pagination]}
              slidesPerView={1}
              spaceBetween={16}
              className="custom-three-swiper"
              breakpoints={{
                768: {
                  slidesPerView: 2.2,
                  spaceBetween: 24,
                },
                1400: {
                  slidesPerView: 3.2,
                  spaceBetween: 24,
                },
              }}
            >
              {customList?.map((item: any, index: number) => (
                <SwiperSlide key={index}>
                  <div
                    className={`custom-item flex-horizontal ${!imageList.length && "without-img"
                      }`}
                  >
                    {!!imageList.length ? (
                      !!item.image_file_data &&
                        Object.keys(item.image_file_data).length !== 0 ? (
                        <div
                          className="image flex-vertical"
                          style={{ backgroundColor: randomHSLA() }}
                        >
                          <img src={item.image_file_data.image_s} alt="image" />
                        </div>
                      ) : (
                        <div
                          className="image flex-vertical"
                          style={{ backgroundColor: randomHSLA() }}
                        ></div>
                      )
                    ) : null}
                    <div className="item-info flex-vertical">
                      {!!item.header ? (
                        <h2 className="title">{item.header}</h2>
                      ) : null}
                      {!!item.description ? (
                        <p key={index} className="description">
                          {item.description}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <div className="custom-items">
              {customList?.map((item: any, index: number) => (
                <div
                  key={index}
                  className={`custom-item flex-horizontal ${!imageList.length && "without-img"
                    }`}
                >
                  {!!imageList.length ? (
                    !!item.image_file_data &&
                      Object.keys(item.image_file_data).length !== 0 ? (
                      <div
                        className="image flex-vertical"
                        style={{ backgroundColor: randomHSLA() }}
                      >
                        <img src={item.image_file_data.image_s} alt="image" />
                      </div>
                    ) : (
                      <div
                        className="image flex-vertical"
                        style={{ backgroundColor: randomHSLA() }}
                      ></div>
                    )
                  ) : null}
                  <div className="item-info flex-vertical">
                    {!!item.header ? (
                      <h2 className="title">{item.header}</h2>
                    ) : null}
                    {!!item.description ? (
                      <p key={index} className="description">
                        {item.description}
                      </p>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          )} */}
      </div>
    );
  } else {
    return <></>;
  }
};

export default CustomThreeDefault;
