import React, { useEffect, useState } from "react";
import { PostsInterface } from "types/ApiInterface";
import blue_bg from "assets/New folder/Ellipse 1000.png";

import "./style.scss";
import { Theme } from "pages/Home";
import FaqDefault from "./FAQDefault";
import FaqStandard from "./FAQStandard";
import FaqJames from "./FAQJames";
import FaqNikesh from "./FAQNikesh";
import FaqDefaultRimal from "./FAQDefaultRimal";
import FaqFull from "./FAQFull";

export function randomHSLA() {
  return `hsla(${~~(360 * Math.random())}, 70%,  72%, 0.5)`;
}

function Process({
  section,
  theme,
}: {
  content?: PostsInterface[];
  demo?: boolean;
  pro?: any;
  section?: any;
  theme: Theme | String;
}) {
  const [dataList, setDataList] = useState([]);
  const [textData, setTextData] = useState({
    type: "text",
    header: "",
    description: "",
  });

  useEffect(() => {
    if (Array.isArray(section?.data)) {
      setDataList(section?.data.filter((item: any) => !item.type));
      setTextData(section?.data.find((item: any) => !!item.type));
    }
  }, []);
  const renderFaq = () => {
    switch (theme) {
      case "yosha":
        return <FaqStandard section={section} dataList={dataList} textData={textData} />;
      case "freemium":
        return <FaqJames section={section} dataList={dataList} textData={textData} />;
      case "standard":
        return <FaqStandard section={section} dataList={dataList} textData={textData} />;
      // case "intenxfitness":
      //   return <FaqNikesh section={section} dataList={dataList} textData={textData} />;
      case "rimal":
        return <FaqDefaultRimal section={section} dataList={dataList} textData={textData} />;
      default:
        if (section.variant == "full") {
          return <FaqFull section={section} dataList={dataList} textData={textData} />;
        }
        return <FaqDefault section={section} dataList={dataList}
          textData={textData} />;
    }
  };

  if (
    !!section?.data &&
    Array.isArray(section.data) &&
    section?.data?.length !== 0
  ) {
    return <>{renderFaq()}</>;
  } else {
    return <></>;
  }
}

export default Process;
