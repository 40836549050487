import { FunctionComponent, useEffect, useState } from "react";
import FullScreenImage from "components/FullScreenImage";
import "./styles.scss";
import SectionHelper from "helpers/SectionHelper";

interface ProfileCustomOneProps {
  section: any;
}

const TextLeftNoImage: FunctionComponent<ProfileCustomOneProps> = ({
  section,
}) => {
  return (
    <section className={`text-center ` + SectionHelper.getColorClass(section)}>
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-lg-8">
            {!!section.data[0]?.title ? (
              <h1 className="title">{section.data[0].title}</h1>
            ) : null}
            {!!section.data[0].description ? (
              <p className="lead">{section.data[0].description}</p>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
};

export default TextLeftNoImage;
