// utils/locationHelper.ts
import ApiConfig from 'api/apiConfig';
import axios from 'axios';

class LocationHelper {
    private static ipinfoUrl = `https://ipinfo.io/json?token=${ApiConfig.IPINFO_TOKEN}`;
    private static ipgeolocationUrl = `https://api.ipgeolocation.io/ipgeo?apiKey=${ApiConfig.IPLOCATION_KEY}`;

    public static async getLocation(): Promise<{ cityCountry: string, latLng: string } | null> {
        // Try fetching location from each service in order
        const urls = [
            this.ipinfoUrl,
            this.ipgeolocationUrl
        ];

        for (const url of urls) {
            const location = await this.fetchLocationFromApi(url);
            if (location) {
                return location;
            }
        }

        return null; // Return null if all services fail
    }

    private static async fetchLocationFromApi(url: string): Promise<{ cityCountry: string, latLng: string } | null> {
        try {
            const response = await axios.get(url);
            const data = response.data;

            // Extract data based on available fields
            const city = data.city || '';
            const country = data.country_name || data.country_code || '';
            const latitude = data.latitude || parseFloat(data.loc?.split(',')[0]) || '';
            const longitude = data.longitude || parseFloat(data.loc?.split(',')[1]) || '';

            if (city && country && latitude && longitude) {
                return {
                    cityCountry: `${city}, ${country}`,
                    latLng: `${latitude},${longitude}`
                };
            }
        } catch (error) {
            console.error('Error fetching location:', error);
        }

        return null;
    }
}

export default LocationHelper;
