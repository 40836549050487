import FullScreenImage from "components/FullScreenImage";
import "./styles.scss";
import { HeroDefaultProps } from "../HeroDefault";
import UtilityHelper from "helpers/UtilityHelper";

function HeroYosha({ section, imageList }: HeroDefaultProps) {
  return (
    <section
      className={`hero-container yosha flex-horizontal ${
        UtilityHelper.isPresent(imageList) ? "" : "without-img"
      } ${
        !section.data[section.data.length - 1].title &&
        !section.data[section.data.length - 1].description
          ? "without-text"
          : ""
      }`}
    >
      {UtilityHelper.isPresent(imageList) ? (
        <div className="hero-item hero-image">
          <div className="image flex-vertical">
            <img src={imageList[0].image_file_data?.image_s} />
          </div>
        </div>
      ) : null}
      <div className="hero-overlay"></div>
      {!!section.data[0].title || !!section.data[0].description ? (
        <div className={`hero-item hero-text`}>
          {!!section.data[0].title ? (
            <p className="title">{section.data[0].title}</p>
          ) : null}

          {!!section.data[0].description ? (
            <div className="content flex-vertical">
              <p className="description">{section.data[0].description}</p>
            </div>
          ) : null}
        </div>
      ) : null}
    </section>
  );
}

export default HeroYosha;
