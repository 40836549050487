import { FunctionComponent, useEffect, useState } from "react";

import "./styles.scss";
import { Theme } from "pages/Home";
import AwardDefault from "./AwardDefault";


interface AwardItemProps {
  award?: any;
}

export const AwardItem: FunctionComponent<AwardItemProps> = ({ award }) => {
  return (
    <div className="col-md-4">
      <div className="feature feature-8">
        <img alt="Image" src={award.image_file_data?.image_s} title="" />
        {!!award.award_name ? <h5>{award.award_name}</h5> : null}
        {!!award.award_url && award.award_url != "" ? (
          <span>{award.award_url}</span>
        ) : null}
      </div>
    </div>
  );
};

interface ProfileAwardsProps {
  section: any;
  theme: Theme | String;
}

const ProfileAwards: FunctionComponent<ProfileAwardsProps> = ({
  section,
  theme,
}) => {
  const [dataList, setDataList] = useState([]);
  const [textData, setTextData] = useState({
    type: "text",
    header: "",
    description: "",
  });
  useEffect(() => {
    if (Array.isArray(section?.data)) {
      setDataList(section?.data.filter((item: any) => !item.type));
      setTextData(section?.data.find((item: any) => !!item.type));
    }
  }, []);
  const renderAward = () => {
    switch (theme) {
      case "rimal":
        return <AwardDefault section={section} dataList={dataList}
          textData={textData} />;
      default:
        return <AwardDefault section={section} dataList={dataList}
          textData={textData} />;
    }
  };
  if (Array.isArray(section.data) && section?.data?.length !== 0) {
    return <>{renderAward()}</>;
  } else {
    return <></>;
  }
};

export default ProfileAwards;
