import ApiClient from "api/apiClient";
import ApiConfig from "api/apiConfig";

class PagesService {
  static type = "page";

  static GetPages = async (where: any) => {
    const url = ApiConfig.API_URL + ApiConfig.PAGES_INDEX;

    try {
      const response = await ApiClient.sendGet(url, where, false);
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  static CreatePage = async (where: any) => {
    const url = ApiConfig.API_URL + ApiConfig.PAGES_CREATE;

    try {
      const response = await ApiClient.sendPost(this.type, url, where, true);
      return response;
    } catch (error) {
      console.error(error);
    }
  };
}

export default PagesService;
