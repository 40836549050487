import { FunctionComponent } from "react";
import { BsFacebook } from "react-icons/bs";
import { AiOutlineTwitter, AiOutlineInstagram } from "react-icons/ai";
import { FaBehance, FaLinkedinIn, FaSkype, FaTiktok } from "react-icons/fa";
import { SiYoutubemusic } from "react-icons/si";
import { IoLogoWhatsapp } from "react-icons/io";
import { IoLogoWechat } from "react-icons/io5";

const SocialIcon: FunctionComponent<{ social: any }> = ({ social }) => {
  const addHttps = (url: string) => {
    if (!/^https?:\/\//i.test(url)) {
      return "http://" + url;
    } else return url;
  };

  if (social?.link?.includes("facebook")) {
    return (
      <a
        href={`${addHttps(social.link)}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <div
          className="flex-vertical social-item"
          style={{
            backgroundColor: "#1877F2",
          }}
        >
          <BsFacebook color="white" size={20} />
        </div>
      </a>
    );
  } else if (social?.link?.includes("instagram")) {
    return (
      <a
        href={`${addHttps(social.link)}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <div
          className="flex-vertical social-item"
          style={{
            background:
              "radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%)",
            filter:
              "progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 )",
          }}
        >
          <AiOutlineInstagram color="white" size={28} />
        </div>
      </a>
    );
  } else if (social?.link?.includes("twitter")) {
    return (
      <a
        href={`${addHttps(social.link)}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <div
          className="flex-vertical social-item"
          style={{
            backgroundColor: "#1DA1F2",
          }}
        >
          <AiOutlineTwitter color="white" size={20} />
        </div>
      </a>
    );
  } else if (social?.link?.includes("linkedin")) {
    return (
      <a
        href={`${addHttps(social.link)}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <div
          className="flex-vertical social-item"
          style={{
            backgroundColor: "#2867B2",
          }}
        >
          <FaLinkedinIn color="white" size={20} />
        </div>
      </a>
    );
  } else if (social?.link?.includes("youtube")) {
    return (
      <a
        href={`${addHttps(social.link)}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <div
          className="flex-vertical social-item"
          style={{
            backgroundColor: "#FF0000",
          }}
        >
          <SiYoutubemusic color="white" size={20} />
        </div>
      </a>
    );
  } else if (social?.link?.includes("tiktok")) {
    return (
      <a
        href={`${addHttps(social.link)}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <div
          className="flex-vertical social-item"
          style={{
            backgroundColor: "#000",
          }}
        >
          <FaTiktok color="#fff" size={20} />
        </div>
      </a>
    );
  } else if (social?.link?.includes("wa")) {
    return (
      <a
        href={`${addHttps(social.link)}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <div
          className="flex-vertical social-item"
          style={{
            backgroundColor: "#000",
          }}
        >
          <IoLogoWhatsapp color="#25D366" size={20} />
        </div>
      </a>
    );
  } else if (social?.link?.includes("wechat")) {
    return (
      <a
        href={`${addHttps(social.link)}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <div
          className="flex-vertical social-item"
          style={{
            backgroundColor: "#000",
          }}
        >
          <IoLogoWechat color="#fff" size={20} />
        </div>
      </a>
    );
  } else if (social?.link?.includes("behance")) {
    return (
      <a
        href={`${addHttps(social.link)}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <div
          className="flex-vertical social-item"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <FaBehance color="#000" size={20} />
        </div>
      </a>
    );
  } else if (social?.link?.includes("pinterest")) {
    return (
      <a
        href={`${addHttps(social.link)}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <div
          className="flex-vertical social-item"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <FaBehance color="#bd081c" size={20} />
        </div>
      </a>
    );
  } else if (social?.link?.includes("skype")) {
    return (
      <a
        href={`${addHttps(social.link)}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <div
          className="flex-vertical social-item"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <FaSkype color="#00aff0" size={20} />
        </div>
      </a>
    );
  } else {
    return <div></div>;
  }
};

export default SocialIcon;
