import { useState } from "react";
import { PostsInterface } from "types/ApiInterface";

import "./styles.scss";
import { IoMdAdd } from "react-icons/io";
import { BiMinus } from "react-icons/bi";
import { Theme } from "pages/Home";
import UtilityHelper from "helpers/UtilityHelper";

function FaqStandard({ section,
  textData,
  dataList, }: {
    section: any;
    dataList: any;
    textData: any;
  }) {
  const [showFAQ, setShowFAQ] = useState<Array<number>>([0]);

  return (
    <div className={`process-container flex-vertical standard`}>
      <section className="process-content">
        {UtilityHelper.isPresent(textData) && UtilityHelper.isPresent(textData.header) ? (
          <div className="text">
            {UtilityHelper.isPresent(textData.header) ? (
              <h1 className="text-capitalize">{textData.header}</h1>
            ) : null}
            {UtilityHelper.isPresent(textData.description) ? (
              <p className="description">{textData.description}</p>
            ) : null}
          </div>
        ) : <h1 className="title text-capitalize">Frequently Asked Question</h1>}

        <div className="process-items flex-vertical">
          {dataList.map((faq: any, index: number) => (
            <div
              key={index}
              onClick={() => {
                if (showFAQ.includes(index)) {
                  const newFAQ = showFAQ.filter((faq) => faq !== index);
                  setShowFAQ([...newFAQ]);
                } else {
                  setShowFAQ([...showFAQ, index]);
                }
              }}
              className={`process-item flex-horizontal ${showFAQ.includes(index) ? "show" : ""
                }`}
            >
              <div className={`question-item flex-horizontal `}>
                <p className="question">{faq?.question}</p>
                {showFAQ.includes(index) ? (
                  <BiMinus className="question-btn" size={24} />
                ) : (
                  <IoMdAdd className="question-btn" size={24} />
                )}
              </div>
              <p className="answer">{faq?.answer}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default FaqStandard;
