import { FunctionComponent } from "react";

import "../SocialDefault/styles.scss";
import "./styles.scss";
import SocialIcon from "../convertSocial";

export interface SocialProps {
  section: any;
}

const SocialDefaultTaylor: FunctionComponent<SocialProps> = ({ section }) => {
  return (
    <section className={`social-container flex-vertical default somebodysgun`}>
      <p className="title">
        Follow Taylor for real-time updates, behind-the-scenes content, and
        community discussions
      </p>
      <div className="flex-horizontal social-items">
        {section.data.map((link: any, index: number) => (
          <SocialIcon key={index} social={link} />
        ))}
      </div>
    </section>
  );
};

export default SocialDefaultTaylor;
