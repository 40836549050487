import { Swiper, SwiperSlide } from "swiper/react";
import { ForwardedRef, FunctionComponent, useRef } from "react";
import { Autoplay, Swiper as SwiperType } from "swiper";
import "./styles.scss";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { Theme } from "pages/Home";
import SectionHelper from "helpers/SectionHelper";
import { TestimonialCard } from "../..";
import UtilityHelper from "helpers/UtilityHelper";

function TestimonialDefault({
  section,
  textData,
  dataList,
}: {
  section: any;
  dataList: any;
  textData: any;
}) {
  const swiperRef = useRef<SwiperType>();
  return (
    <section className={"testimonial-container flex-vertical default " + SectionHelper.getColorClass(section)}>
      {UtilityHelper.isPresent(textData) && UtilityHelper.isPresent(textData.header) ? (
        <div className="text">
          {UtilityHelper.isPresent(textData.header) ? (
            <h3 className="header">{textData.header}</h3>
          ) : null}
          {UtilityHelper.isPresent(textData.description) ? (
            <p className="description">{textData.description}</p>

          ) : null}
        </div>
      ) : <h2>Testimonials</h2>}
      <Swiper
        autoplay={{
          delay: 3500,
          disableOnInteraction: true,
        }}
        modules={[Autoplay]}
        slidesPerView={1}
        spaceBetween={20}
        centeredSlides={true}
        loop={true}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        className="testimonial-swiper"
        breakpoints={{
          768: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          1440: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
        }}
      >
        {dataList.map((testimonial: any, index: number) => (
          <SwiperSlide key={index}>
            <TestimonialCard testimonial={testimonial} />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="swiper-btn flex-horizontal">
        <button onClick={() => swiperRef.current?.slidePrev()}>
          <BsArrowLeftShort size={32} color="black" />
        </button>
        <button onClick={() => swiperRef.current?.slideNext()}>
          <BsArrowRightShort size={32} color="black" />
        </button>
      </div>
    </section>
  );
}

export default TestimonialDefault;
