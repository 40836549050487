// import IntroVid from "assets/PublicProfile/hero/intro.png";
import PlayBtn from "assets/PublicProfile/hero/play-circle.png";
import { BsFillPlayCircleFill } from "react-icons/bs";

import "./style.scss";
import { Theme } from "pages/Home";
import IntroHero from "./components/IntroHero";
import IntroVideo from "./components/IntroVideo";
import IntroLoop from "./components/IntroLoop";

interface IntroProps {
  section: any;
  theme: Theme | String;
}

function Intro({ section, theme }: Partial<IntroProps>) {
  const renderIntro = () => {
    if (section.variant == "hero") {
      return <IntroHero theme={theme} section={section} />;
    } else if (section.variant == "loop") {
      return <IntroLoop theme={theme} section={section} />
    }
    return <IntroVideo theme={theme} section={section} />;
  };
  if (
    Array.isArray(section?.data) &&
    section?.data?.length !== 0 &&
    !!section?.data[0]
  ) {
    return <>{renderIntro()}</>;
  } else {
    return <></>;
  }
}

export default Intro;
