import { FunctionComponent, useState } from "react";
import "./style.scss";
import { IoMdCloseCircle } from "react-icons/io";

interface FullScreenImageProps {
  src: string;
  style?: any;
}

const FullScreenImage: FunctionComponent<FullScreenImageProps> = ({
  src,
  style,
}) => {
  const [isFull, setIsFull] = useState(false);
  return (
    <>
      <div className="full-screen-img" onClick={() => setIsFull(true)}>
        <img style={style} src={src} alt={"image"} loading="lazy" />
      </div>
      {isFull ? (
        <div className="full-screen-img_active">
          <div
            onClick={() => setIsFull(false)}
            className="full-screen-img_active-overlay"
          ></div>
          <button onClick={() => setIsFull(false)}>
            <IoMdCloseCircle size={36} color="white" />
          </button>
          <div className="full-screen-img-container">
            <img src={src} alt={"User image"} loading="lazy" />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default FullScreenImage;
