import React from 'react'
import Logo from '../../assets/logo.webp';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

const Successful = () => {
  const navigate = useNavigate();

  const lastCall = () => {
    navigate(-1);
  };

  return (
    <div className="flex items-center justify-center min-h-screen" style={{background:'rgba(34, 117, 215, 0.05)'}}>
         <div className='logo md:m-6 '>
          <a href="/">
            <img src={Logo} alt="CastleBird Logo" className="mx-auto mb-4" />
          </a>
        </div>
    <div className="bg-white rounded-lg p-8 max-w-2xl text-center" style={{padding:'64px 80px'}}>
      {/* Icon */}
      <div className="bg-green-100 rounded-full p-4 mb-4 inline-block">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-16 w-16"
            fill="none"
            viewBox="0 0 24 24"
            stroke="green" 
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </div>

      <h1 className="text-3xl font-bold mb-2 text-gray-800">
      Unsubscription successful!
      </h1>

      <p className="text-gray-600 mb-6 text-lg">
      You will no longer receive any emails from Castlebird.
      </p>
      <hr />
        <div className="cta gap-8" style={{ marginTop: '-8px' }}>
          <h2 className="text-2xl font-bold mb-2 text-gray-800">
            Change your mind?
          </h2>
          <Button
            label="Click here"
            className="p-button-primary mt-0 "
            onClick={lastCall}
            style={{ padding: '12px 24px', backgroundColor: '#2275D7', borderRadius: '4px', fontWeight: 'bold' }}
          />
        </div>
    </div>
  </div>
  )
}

export default Successful
