import { IoIosArrowBack } from "react-icons/io";
import "../Terms/styles/styles.scss";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const PolicyPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });

  return (
    <section className="terms-section">
      <div className="header flex-horizontal">
        <h1>Privacy Policy</h1>
      </div>
      <p className="body-reg">
        We are serious about protecting the privacy of our users. This Privacy
        Policy describes how we use and protect your personal data. It applies
        to CastleBird.com, any subdomains thereof and any other websites where
        this policy is linked to in the footer of the page and any mobile
        application of such CastleBird websites (which we have collectively
        called the “Site” in this policy).
        <br />
        The Site is operated by CasleBird Inc. The terms “users” or “you” as
        used in this Privacy Policy, refer to guests, who use the Site to look
        for properties to rent, property owners and managers who list properties
        for rent on the Site and other users who use software or other tools or
        services we provide on a Site. It also applies to other visitors to our
        site - for example those applying for a job through the Site.
        <br />
        We use information collected about you as explained in this Privacy
        Policy. The Site may contain links to third parties' websites. We're not
        responsible for the privacy practices or the content of those websites.
        The Site may also contain links to terms and conditions and privacy
        policies of third party providers who provide tools or services on a
        Site. Therefore, please read carefully any privacy policies on those
        links or websites before either agreeing to their terms or using those
        tools, services or websites. Unless we explain otherwise in this policy,
        the words and phrases used in this policy have the same meaning as in
        the Terms of Service.
        <br />
        By providing information to us when listing a property, searching for a
        property, using any tool or service on a Site or otherwise using the
        Site, you expressly agree to this Privacy Policy and our terms of use.
        This Privacy Policy was last updated on the date set forth above and
        applies from that date. We may occasionally amend this Privacy Policy to
        reflect regulatory requirements, advances in technology, Site updates
        and changes in our information collection and disclosure practices. Any
        new policy will automatically be effective when it is published on a
        Site. You should therefore return here regularly to view our most up to
        date Privacy Policy. You should also print a copy for your records.
      </p>
      <h3>Who processes your personal data, and where?</h3>
      <p className="body-reg">
        Your personal data (also referred to as personal information) - that is,
        data that identifies you, or from which you are identifiable - will be
        collected, processed, stored and used by us, and passed to and processed
        by CastleBird and third party data processors acting on our behalf.
        CastleBird is a global business. As a result, personal data contributed
        by or collected from users may be stored and processed in Canada, United
        States or any other country in which we, members of the CastleBird or
        our agents maintain facilities. By using our Site, you acknowledge and
        agree that you may be transferring your personal data outside of the
        country in which you have contributed it (“your country”), and that you
        consent to any transfer of information outside of your country. These
        countries may not have similar data protection laws to those that apply
        in your country. However, if we transfer your personal data outside of
        your country in this way, we will take steps to try to ensure that your
        privacy rights are protected in compliance with applicable data
        protection law and this Privacy Policy.
        <br />
        We do not knowingly collect or process data from anyone under the age of
        eighteen (18) years old.
        <br />
        CastleBird sites may also use Google Analytics to gather statistics on
        site usage. This may involve the use of cookies. There are more details
        in our Cookie Notice and in Google’s own privacy policy. Google may
        aggregate data they collect from their various services including Google
        Analytics, Google Translate, Google Maps and YouTube. You acknowledge
        and accept that CastleBird has no control over Google’s data collection.
        We strongly advise you to look at Google’s privacy policy for details of
        their data collection practices. You can find more information about how
        Google uses data here:
        https://www.google.com/policies/privacy/partners/. If you want to opt
        out from Google Analytics, please check here:
        https://tools.google.com/dlpage/gaoptout.
      </p>
      <h3>The information we gather</h3>
      <p className="body-reg">
        At various times you may give your personal data to us. For example, in
        order to send a booking request or inquiry from you to a property owner
        or manager, we may need to know all or some of the following: your full
        name, address, telephone number, e-mail address, your current location,
        and, in the case of a listing, a method of payment. You may also give
        personal data to us at other times, such as when registering on the Site
        or when submitting a listing for a property. We also receive your
        personal information when you set up financial account information for
        payment processing. We may also gather information from cookies or web
        beacons. You can find out more about how they operate in the relevant
        section of this policy below. We also collect IP addresses, mobile
        device identifier details, your location, navigation and click-stream
        data, the time of accessing the Site, homes you viewed, what you
        searched for, the duration of your visit, and other details of your
        activity on the Site. If you ask us to connect with other sites (for
        example if you ask us to connect with your Facebook account) we may get
        information about you that way too. In connection with electronic
        communications we facilitate through a Site, we use electronic or manual
        checks to monitor such communications for trust and security purposes.
        For further details on how we collect personal data, see Surveys and
        Communications below. We obtain data from you either by you inputting
        your personal details, by the automatic collection of information about
        you as you use the Site, when we speak to you, or from third parties.
        Third party information may include financial information from our
        payment processor partners that we use to update our records and to
        prevent and detect fraud, information from your mobile provider if you
        are accessing the Site over a mobile device or information from a third
        party such as a social media or travel opinions site where you have
        asked us to share your data with them.
      </p>
      <h3>How we use your personal data</h3>
      <p className="body-reg">
        Our primary purpose in collecting your personal data is to provide you
        with the services you request and those which we believe will optimize
        your use of the Site. You agree that we may use your personal data for
        the following purposes:
        <ul>
          <li>
            For the services provided on the Site or to support your request
          </li>
          <li>To contact you from time to time with user or service updates</li>
          <li>
            To send other messages that help us provide our services on the Site
          </li>
          <li>
            To assist people you have done (or have agreed to do) business with.
            For example if you are an owner and a guest who has booked or
            inquired with you needs your details we may pass them on.
          </li>
        </ul>
        <br />
        If you are a guest and an owner you have booked with needs to contact
        you we may give them your details
        <ul>
          <li>
            To customize, measure, report on and improve our services, content
            and advertising
          </li>
          <li>
            To promote services related to the Site or those of the CastleBird
            and/or our affiliates
          </li>
          <li>
            To compare information for accuracy, update our records, and verify
            it with third parties
          </li>
          <li>
            To prevent, investigate or prosecute activity we think may be
            potentially illegal, unlawful or harmful
          </li>
          <li>
            To enforce our Privacy Policy and/or our Terms of Service or any
            other purpose referenced herein or therein.
          </li>
        </ul>
        <br />
        We collate statistics about site traffic, sales and other commercial
        information which we pass onto third parties to assist us with improving
        the services we provide to you. We also use demographic information to
        tailor the Site and we share that information with third parties so that
        they can build up a better picture of our customer base and general
        consumer trends.
        <br />
        If you are a property owner or manager, we may also display your phone
        number on the listing itself so that guests may contact you and to
        restrict fraudulent behavior. We may also contact you about your account
        and your use of our services.
      </p>
      <h3>How We May Disclose Your Personal Data</h3>
      <p className="body-reg">
        We may disclose your personal data to enforce our policies, or where we
        are permitted (or believe in good faith that we are required) to do so
        by applicable law, such as in response to a request by a law enforcement
        or governmental authority, or in connection with actual or proposed
        litigation, or to protect our property, people and other rights or
        interests.
        <br />
        We may also share your personal data with:
        <ul>
          <li>
            One of our partners if you've requested their services or if you’ve
            requested to be provided with information by them
          </li>
          <li>Another member if you have done business with them</li>
          <li>
            A third party performing services on our behalf, such as to process
            payments
          </li>
          <li>
            Companies in the CastleBird and/or affiliates; or Other companies or
            business entities, for example if we are thinking of a merger with
            or sale to that company or business entity
          </li>
          <li>
            Other companies we work with to feature all or part of the property
            listings or otherwise provide promotional or other services related
            to our or CastleBird’s business. This might include featuring your
            listings and photographs on other websites
          </li>
          <li>
            Any third party you have asked us to share your personal data with –
            such as Facebook if you have asked us to connect with your Facebook
            account; or
          </li>
          <li>
            Any legal or governmental entity pursuant to a subpoena or other
            legal request, or as necessary to remit certain taxes in the course
            of processing payments.
          </li>
        </ul>
        <br />
        You may have accessed our Site through a hyperlink from the website of
        one of our partners. If so, you consent to your personal details and
        purchase information, including behavioral patterns, being shared with
        that trading partner in accordance with our contractual relationship
        with them.
        <br />
        In the event that any part of the CastleBird is sold, or some of its
        assets transferred to a third party, your personal information, as a
        valuable asset, may also be transferred. Potential purchasers and their
        advisors may have access to data as part of the sale process. We will
        request the acquiring party to follow the practices described in this
        Privacy Policy with respect to previously collected information.
        Nevertheless, we cannot promise that an acquiring party or the merged
        entity will have the same privacy practices or treat your information
        the same as described in this Privacy Policy. Similarly, your personal
        information may be passed on to a successor in interest in the unlikely
        event of a liquidation, bankruptcy, or administration. Our customer
        database could be sold separately from the rest of the business, in
        whole or in a number of parts. It could be that the purchaser's business
        is different from ours, too.
      </p>
      <h3>Communications</h3>
      <p className="body-reg">
        We and the CastleBird may offer different communications from time to
        time intended to enhance the services we or they offer. Users may cancel
        their subscription to these communications at any time, although it may
        take a short while for the changes in your preferences to become
        effective.
      </p>
      <h3>Surveys</h3>
      <p className="body-reg">
        We also use surveys to collect information about our users. From time to
        time, we request users' input in order to evaluate potential features
        and services. The decision to answer a survey is completely yours. We
        use information gathered from surveys to improve our services.
      </p>
      <h3>Games</h3>
      <p className="body-reg">
        From time to time we may provide games on the Site. These games may
        allow for connectivity with other users or third parties. The rules for
        each game will specify how the information gathered from you will be
        used and disclosed if it is different than as described in this Privacy
        Policy. If that is the case you consent to your personal data being used
        and transferred accordingly.
      </p>
      <h3>Mobile Applications</h3>
      <p className="body-reg">
        When you download or use apps relating to our websites, we may receive
        information about your location and your mobile device, including a
        unique identifier for your device. We may use this information to
        provide you with location-based services, such as advertising, search
        results, and other personalized content. Most mobile devices allow you
        to turn off location services. If you have questions about how to
        disable your device's location services, we recommend you consult your
        device’s manual, or contact your mobile service carrier or your device
        manufacturer.
      </p>
      <h3>Telephone Calls</h3>
      <p className="body-reg">
        As well as collecting data online and from correspondence we may also
        speak with you face to face (for example at a Summit or other
        conference) or by telephone. We may record telephone conversations for
        quality assurance and training purposes. This Privacy Policy also
        governs those recordings and we keep this data in the same way that we
        keep other data. Recordings of calls may also be transferred to
        locations or processed worldwide.
      </p>
      <h3>Inquiries and other Electronic Communications</h3>
      <p className="body-reg">
        Guests may send inquiries to property owners or managers via clickable
        links on the listings on a Site. Further, guests and property owners or
        managers may communicate with each other via tools on a Site. If you
        choose to send an inquiry or booking request through these links, your
        personal information, including your email address and any other
        information you supply (unless the Site specifies otherwise), will be
        visible to the property owner or manager in question so that they might
        reply directly to you. Your details may also be sent to us and we have
        access to those communications even if you contact the property owner or
        manager directly. If property owners or managers and guests further
        engage in communications through a Site, we also have access to those
        communications, which we monitor for compliance and security purposes.
        Additionally those inquiries and messages may be hosted on, be processed
        by or transmit through our servers. If you engage in such communications
        and call the other party, you may be asked to leave a return telephone
        number. Do not share information in the e-mail or phone call that you
        are not prepared to allow such person and CastleBird to have, including,
        but not limited to, credit card and bank account information. In
        addition inquiries and messages can be sent to the individual member you
        selected and to our system. Our customer service team may share such
        communications with property owners or managers. We may also from time
        to time, use third party e-mail servers to send and track receipt of
        such communications, and analyze the pattern of such communications for
        trust and security purposes as well as to gather data, such as inquiry
        and booking data (on an anonymous basis), to assist us in better
        understanding our business.
      </p>
      <h3>How Do We Protect Your Personal Information Once We Have It?</h3>
      <p className="body-reg">
        We take reasonable technical and organizational measures to guard
        against unauthorized or unlawful processing of your personal data and
        against accidental loss or destruction of, or damage to, your personal
        data. While no system is completely secure, we believe the measures
        implemented by the Site reduce our vulnerability to security problems to
        a level appropriate to the type of data involved. We have security
        measures in place to protect our user database and access to this
        database is restricted internally. However, it remains your
        responsibility:
        <ul>
          <li>
            To protect against unauthorized access to your use of the Site
          </li>
          <li>
            To ensure no one else uses the Site while your machine is logged on
            to the Site (including by logging on to your machine through a
            mobile, Wi-Fi or shared access connection you are using)
          </li>
          <li>To log off or exit from the Site when not using it</li>
          <li>
            Where relevant, to keep your password or other access information
            secret. Your password and log in details are personal to you and
            should not be given to anyone else or used to provide shared access
            for example over a network; and
          </li>
          <li>
            To maintain good internet security. For example if your email
            account or Facebook account is compromised this could allow access
            to your account with us if you have given us those details and/or
            permitted access through those accounts. If your email account is
            compromised it could be used to ask us to reset a password and gain
            access to your account with us.
          </li>
        </ul>
        <br />
        You should keep all of your account details secure. If you think that
        any of your accounts has been compromised you should change your account
        credentials with us, and in particular make sure any compromised account
        does not allow access to your account with us. You should also tell us
        as soon as you can, so that we can try to help you keep your account
        secure and if necessary warn anyone else who could be affected.
        <br />
        If you have asked us to share data with third party sites, however (such
        as Facebook) their servers may not be secure. Payment card and bank
        account information is generally stored by our payment processing
        partners and we contractually require them to keep that data secure. We
        also use third parties to help us optimize our Site flow, content and
        advertising (see below).
        <br />
        Note that, despite the measures taken by us and the third parties we
        engage, the Internet is not secure. As a result others may nevertheless
        unlawfully intercept or access private transmissions or data.
      </p>
      <h3>
        What are Cookies, Web Beacons and Clear GIFs and Why Do We Use Them?
      </h3>
      <p className="body-reg">
        A "cookie" is a small file placed on your hard drive by some of our web
        pages. We, or third parties we do business with, may use cookies to help
        us analyze our web page flow, customize our services, content and
        advertising, measure promotional effectiveness and promote trust and
        safety. Cookies are commonly used at most major transactional websites
        in much the same way we use them here at our Site.
        <br />
        You may delete and block all cookies from this site, but parts of the
        site will not work. We want to be open about our cookie use.
        <br />
        Even if you are only browsing the Site, certain information (including
        computer and connection information, browser type and version, operating
        system and Account details, and the time of accessing the Site) is
        automatically collected about you. This information will be collected
        every time you access the Site and it will be used for the purposes
        outlined in this Privacy Policy.
        <br />
        You can reduce the information cookies collected from your device. An
        easy way of doing this is often to change the settings in your browser.
        If you do that you should know that (a) your use of the Site may be
        adversely affected (and possibly entirely prevented), (b) your
        experience of this and other sites that use cookies to enhance or
        personalize your experience may be adversely affected, and (c) you may
        not be presented with advertising that reflects the way that you use our
        and other sites. Unless you have adjusted your browser settings so that
        it will refuse cookies, our system will send cookies as soon as you
        visit our Site. By using the Site you consent to this, unless you change
        your browser settings.
        <br />
        We gather and share information concerning the use of the Site by
        property owners and managers and guests with one or more third-party
        tracking companies for the purpose of reporting statistics. To do this,
        some of the pages you visit on our Site use electronic images placed in
        the web page code, called pixel tags (also called "clear GIFs" or "web
        beacons") that can serve many of the same purposes as cookies.
        <br />
        Web beacons may be used to track the traffic patterns of users from one
        page to another in order to maximize web traffic flow. Our third-party
        advertising service providers may also use web beacons to recognize you
        when you visit the Site and to help determine how you found the Site.
        Your browser or device may include “Do Not Track” functionality. Our
        information collection and disclosure practices, and the choices that we
        provide to visitors, will continue to operate as described in this
        Privacy Policy, whether or not a Do Not Track signal is received.
      </p>
      <h3>Phishing or False emails</h3>
      <p className="body-reg">
        If you receive an unsolicited email that appears to be from us or a
        property owner or manager that requests personal information (such as
        your credit card, login, or password), or that asks you to verify or
        confirm your account or other personal information by clicking on a
        link, that email was likely to have been sent by someone trying to
        unlawfully obtain your information, sometimes referred to as a "phisher"
        or "spoofer." We do not ask for this type of information in an email. Do
        not provide the information or click on the link.
      </p>
      <h3>Job applicants, current and former employees</h3>
      <p className="body-reg">
        If you use the Site to apply to work with us, we will use the
        information you supply to process your application and to monitor
        recruitment statistics. Where we want to disclose information to a third
        party, for example where we want to take up a reference, will not do so
        without informing you beforehand unless the disclosure is required by
        law. Personal information about unsuccessful candidates will be held for
        12 months after the recruitment exercise has been completed, it will
        then be destroyed or deleted. We retain de-personalized statistical
        information about applicants to help inform our recruitment activities .
        CastleBird is based in Canada and employee and recruitment data is held
        there and in other CastleBird locations worldwide.
        <br />
        Once a person has taken up employment with us, we will compile a file
        relating to their employment. At that stage we will give you more
        details about how we hold employee details.
      </p>
      <h3>How long will we keep your personal data?</h3>
      <p className="body-reg">
        We will retain your personal data only as long as is necessary for the
        purposes to which you consent under the Terms of Service and this
        Privacy Policy, or as is required by applicable law, and then we will
        delete it.
      </p>
      <h3>Delete your Personal Information</h3>
      <p className="body-reg">
        You can request we delete your personal information by emailing us at
        help@castlebird.com.
      </p>
      <h3>How do I contact you?</h3>
      <p className="body-reg">
        If you have any questions about this Privacy Policy, the practices of
        this Site, or your dealings with this Site, we encourage you to contact
        us at help@castlebird.com.
      </p>
    </section>
  );
};

export default PolicyPage;
