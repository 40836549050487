import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import WebsiteService from 'api/services/SiteService';
import LocationHelper from 'helpers/LocationHelper';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import TrackerHelper from 'helpers/TrackerHelper';

interface Step3Props {
  selectedBusinessTag: string | null;
  selectedBusinessName: string | null;
  setSelectedBusinessName: (name: string) => void;
  getDataFromUser: (data: boolean) => void;
  setLocationData: (location: string, latLng: string) => void;


}

const Step3: React.FC<Step3Props> = ({ selectedBusinessTag, selectedBusinessName, setSelectedBusinessName, getDataFromUser,setLocationData }) => {
  const [tags, setTags] = useState<string[]>([]);
  const [search, setSearch] = useState<string>(selectedBusinessName || '');

  const [location, setLocation] = useState<string>('');
  const [latLng, setLatLng] = useState<string>('');

  const [showTagContainer, setShowTagContainer] = useState<boolean>(false);
  const tagContainerRef = useRef<HTMLDivElement>(null);
  const [blurOutside, setBlurOutside] = useState<boolean>(false)


  useEffect(() => {
    const fetchTags = async () => {
      if (!selectedBusinessTag ) return;

      const result = await WebsiteService.getAiContent({
        ai_action: 'business_name_tags',
        business_tag: selectedBusinessTag,
        filter: '' || search
      });
      setTags(result.tags);
    };

    fetchTags();

    TrackerHelper.virtualPage("/onboarding/business_name", "Onboarding - Business Name");
  }, []);

  useEffect(() => {
    getDataFromUser(!!selectedBusinessName);
  }, [selectedBusinessName])

  useEffect(() => {
    const fetchLocation = async () => {
      if (search) return;
      const locationData = await LocationHelper.getLocation();

      if (locationData) {
        setLocation(locationData.cityCountry);
        setLatLng(locationData.latLng);
        setLocationData(locationData.cityCountry, locationData.latLng);
      }
    };

    fetchLocation();
  }, []);

  const handleSearchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowTagContainer(true);
    const userKeyword = event.target.value;
    setSearch(userKeyword);
    setSelectedBusinessName(userKeyword);

    if (userKeyword.trim()) {
      const result = await WebsiteService.getAiContent({
        ai_action: 'business_name_tags',
        business_tag: selectedBusinessTag || '',
        filter: userKeyword,
      });
      const totalResults = [userKeyword, ...result.tags]
      setTags(totalResults);
    }
  };

  const handleTagClick = (tag: string, index: number) => {
    setSelectedBusinessName(tag);
    setSearch(tag);
    setShowTagContainer(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      tagContainerRef.current &&
      !tagContainerRef.current.contains(event.target as Node) &&
      !document.activeElement?.matches('.input-text-full') // Check if click is within input field
    ) {
      setShowTagContainer(false);
      document.removeEventListener('click', handleClickOutside); // Clean up event listener
    }
  };

  useEffect(() => {
    // Add event listener on component mount
    document.addEventListener('click', handleClickOutside);

    // Remove event listener on component unmount to prevent memory leaks
    return () => document.removeEventListener('click', handleClickOutside);
  }, [blurOutside]);

  return (
    <div>
      {/* <ProgressBar value={80} style={{ height: '6px' }} className="mb-4" /> */}
      <div className="title">What is the name of your business?</div>

      <IconField iconPosition="left" className="full-width-input mb-3">
        <InputText
          placeholder="Enter your business name"
          value={search}
          onChange={handleSearchChange}
          onBlur={() => setBlurOutside(!blurOutside)}
          onFocus={() => setShowTagContainer(true)}
          className="input-text-full py-3 pl-3"
          style={{ color: 'black', fontWeight: '600' }}
        />
      </IconField>

      {showTagContainer && (
        <div className="tag-container" ref={tagContainerRef}>
          {tags.map((tag, index) => (
            <Button
              key={index}
              label={tag}
              className='p-button-text'
              onClick={() => handleTagClick(tag, index)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Step3;