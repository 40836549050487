import { FunctionComponent, useEffect, useState } from "react";
import FullScreenImage from "components/FullScreenImage";
import "./styles.scss";
import { Theme } from "pages/Home";
import CTADefault from "./CTADefault";
import CTAYosha from "./CTAYosha";
import CTAFreemium from "./CTAFreemium";
import CTADefaultTaylor from "./CTADefaultTaylor";
import CTASmall from "./CTASmall";
import CTAParallax from "./CTAParallax";
import CTAParallaxFull from "./CTAParallaxFullScreen";

interface ProfileCustomOneProps {
  section: any;
  theme: Theme | String;
}

const ProfileCTA: FunctionComponent<ProfileCustomOneProps> = ({
  section,
  theme,
}) => {
  const renderCTA = () => {
    switch (theme) {
      case "somebodysgun":
        return <CTADefaultTaylor theme={theme} section={section} />;
      case "yosha":
        return <CTAYosha section={section} />;
      case "freemium":
        return <CTAFreemium section={section} />;
      default:
        if (section.variant == "small") {
          return <CTASmall theme={theme} section={section} />;
        } else if (section.variant == 'parallax') {
          return <CTAParallax theme={theme} section={section} />;
        } else if (section.variant == 'parallaxFull') {
          return <CTAParallaxFull theme={theme} section={section} />;
        }
        return <CTADefault theme={theme} section={section} />;
    }
  };
  if (Array.isArray(section?.data) && section?.data?.length !== 0) {
    return <>{renderCTA()}</>;
  } else {
    return <></>;
  }
};

export default ProfileCTA;
