import ApiClient from "api/apiClient";
import ApiConfig from "api/apiConfig";

class ServiceService {
  static type = "services";

  static GetService = async (where: any) => {
    const url = ApiConfig.API_URL + ApiConfig.SERVICE_INDEX;

    try {
      const response = await ApiClient.sendGet(url, where, false);
      return response;
    } catch (error) {
      console.error(error);
    }
  };
}

export default ServiceService;
