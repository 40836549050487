import { FunctionComponent } from "react";

import "./styles.scss";
import { Theme } from "pages/Home";

interface ProfileMapsProps {
  section: any;
  theme: Theme | String;
}

function replaceBlank(string: String) {
  return string.replace(/ /g, "+");
}

const ProfileMaps: FunctionComponent<ProfileMapsProps> = ({
  section,
  theme,
}) => {
  if (Array.isArray(section?.data) && section?.data.length != 0) {
    return (
      <section className="switchable bg--secondary">
        <div className="container">
          <div className="row justify-content-around">
            <div className="col-md-6 col-lg-5">
              <p className="title">Our Location</p>
              <p className="description lead">{section.data[0].address}</p>
            </div>
            <div className="col-md-6 col-lg-5">
              <div className="video-cover border--round">
                <iframe
                  width="100%"
                  height="100%"
                  loading="lazy"
                  allowFullScreen
                  referrerPolicy="no-referrer-when-downgrade"
                  src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAj7DvH9o1zO1UaLf1096Jpp7kSN9r5lLU
            &q=${replaceBlank(section.data[0].address)}`}
                ></iframe>
              </div>
              <p className="lead justify-content-center flex" style={{ alignContent: "center" }}>Check out our location</p>
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return <></>;
  }
};

export default ProfileMaps;
