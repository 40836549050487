import { ForwardedRef, Ref } from "react";
import "./styles.scss";
import SectionHelper from "helpers/SectionHelper";

function ContactDefault({
  section,
  ref,
}: {
  section: any;
  ref: Ref<HTMLDivElement>;
}) {
  return (
    <section ref={ref} className={"switchable--switch bg-- " + SectionHelper.getColorClass(section)}>
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-md-8 col-lg-7">
            <h2>{section.data[0].text}</h2>
            <p className="lead">
              Visit us or make contact via the details below to talk more.
            </p>
          </div>
          <div className="col-md-4 col-lg-3">
            {!!section.data[0].email ? (
              <div className="text-block">
                <h5>Email</h5>
                <p>
                  {section.data[0].email}
                </p>
              </div>
            ) : null}
            {!!section.data[0].phone ? (
              <div className="text-block">
                <h5>Phone</h5>
                <p>
                  {section.data[0].phone}
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactDefault;
