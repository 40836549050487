import { FunctionComponent, useEffect, useState } from "react";
import FullScreenImage from "components/FullScreenImage";
import { Theme } from "pages/Home";

import "./styles.scss";
import SectionHelper from "helpers/SectionHelper";

const CTADefault: FunctionComponent<{
  section: any;
  theme: Theme | String;
}> = ({ section, theme }) => {

  if (Array.isArray(section?.data) && section?.data?.length !== 0) {
    return (
      <section className={"text-center " + SectionHelper.printImageBgClass(section) + " " + SectionHelper.getColorClass(section)} data-overlay={SectionHelper.printOverlay(section)}>
        {SectionHelper.hasImage(section) ? (
          <div className="background-image-holder" style={{ backgroundColor: "rgba(0, 0, 0, 0)", backgroundPosition: "initial", backgroundRepeat: "repeat", backgroundAttachment: "scroll", backgroundImage: "url(" + section.data[0]?.image_file_data?.image_s + ")", backgroundSize: "auto", backgroundOrigin: "padding-box", backgroundClip: "border-box", opacity: "1" }}>
            <img alt="image" src={section.data[0]?.image_file_data?.image_s} />
          </div>
        ) : null}

        {!!section.data[0].header || !!section.data[0].description ? (
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-lg-6">
                <div className="cta">
                  {!!section.data[0].header && (
                    <h2>{section.data[0].header}</h2>
                  )}
                  {!!section.data[0].description ? (
                    <p className="lead">{section.data[0].description}</p>
                  ) : null}
                  {!!section.data[0].link ? (
                    <a
                      href={`https://${section.data[0].link.replace(
                        "https://",
                        ""
                      )}`}
                      target="_blank"
                      className="btn btn--primary type--uppercase"
                    >
                      <span className="btn__text">
                        Contact now
                      </span>
                    </a>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </section>
    );
  } else {
    return <></>;
  }
};

export default CTADefault;
