import { FunctionComponent } from "react";
import { BsFacebook } from "react-icons/bs";
import { AiOutlineTwitter, AiOutlineInstagram } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { SiYoutubemusic } from "react-icons/si";

import "./styles.scss";
import { Theme } from "pages/Home";
import SocialIcon from "./components/convertSocial";
import SocialFremium from "./components/SocialJames";
import SocialStandard from "./components/SocialStandard";
import SocialDefaultTaylor from "./components/SocialDefaultTaylor";
import SocialDefault from "./components/SocialDefault";

interface ProfileSocialProps {
  section: any;
  theme: Theme | String;
}

const ProfileSocial: FunctionComponent<ProfileSocialProps> = ({
  section,
  theme,
}) => {
  const renderSocial = () => {
    switch (theme) {
      case "freemium":
        return <SocialFremium section={section} />;
      case "standard":
        return <SocialStandard section={section} />;
      case "somebodysgun":
        return <SocialDefaultTaylor section={section} />;
      default:
        return <SocialDefault section={section} />;
    }
  };

  if (Array.isArray(section?.data) && section?.data?.length !== 0) {
    return <>{renderSocial()}</>;
  } else {
  }
  return <></>;
};

export default ProfileSocial;
