import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css";

import "./styles.scss";
import { Pagination } from "swiper";

function UpdateCardIntenxFitness({
  post,
  pro,
  site,
}: {
  post: any;
  pro: any;
  site: any;
}) {
  const [seeMore, setSeeMore] = useState<boolean>(false);
  const location = useLocation();

  if (!!post?.content || !!post?.picture_data) {
    return (
      <article
        className="update-card flex-vertical"
        style={{ alignItems: "flex-start" }}
      >
        <Link
          to={`/post/${post.id}`}
          state={{ prevRoute: location.pathname }}
          style={{ width: "100%" }}
        >
          <div className="update-text">
            {!!post?.title ? (
              <div
                onClick={() => setSeeMore(!seeMore)}
                className={`update-title`}
              >
                {post.title}
              </div>
            ) : null}
            {!!post?.content ? (
              <div className={`update-description `}>{post.content}</div>
            ) : null}
          </div>
          <div className="update-image">
            {Array.isArray(post?.picture_data) ? (
              <Swiper
                slidesPerView={1}
                spaceBetween={16}
                pagination={true}
                modules={[Pagination]}
                breakpoints={{
                  768: {
                    navigation: false,
                    slidesPerView: 2,
                  },
                  1440: {
                    navigation: true,
                    slidesPerView: 3,
                  },
                }}
                className="nikesh-update-swiper"
              >
                {post?.picture_data?.map((picture: any) => (
                  <SwiperSlide>
                    <div className="image">
                      <img src={`${picture?.image_s}`} alt="" />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : null}
          </div>
        </Link>
      </article>
    );
  } else {
    return <></>;
  }
}

export default UpdateCardIntenxFitness;
