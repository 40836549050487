import { FunctionComponent, useEffect, useState } from "react";
import FullScreenImage from "components/FullScreenImage";
import { Theme } from "pages/Home";

import "./styles.scss";
import SectionHelper from "helpers/SectionHelper";
import { ParallaxBanner } from "react-scroll-parallax";

const CTAParallax: FunctionComponent<{
    section: any;
    theme: Theme | String;
}> = ({ section, theme }) => {
    if (Array.isArray(section?.data) && section?.data?.length !== 0) {
        return (
            <section className={"text-center imagebg " + SectionHelper.getColorClass(section)}>
                {!!section.data[0].image_file_data && Object.keys(section.data[0].image_file_data).length !== 0 ? (

                    <ParallaxBanner
                        layers={[
                            { image: section.data[0]?.image_file_data?.image_s, speed: -30, },
                        ]}
                        className="aspect-[2/1] h-[400px] content-center"
                    >
                        {!!section.data[0].header || !!section.data[0].description ? (
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8 col-lg-6">
                                        <div className="cta">
                                            {!!section.data[0].header && (
                                                <h2>{section.data[0].header}</h2>
                                            )}
                                            {!!section.data[0].description ? (
                                                <p className="lead">{section.data[0].description}</p>
                                            ) : null}
                                            {!!section.data[0].link ? (
                                                <a
                                                    href={`https://${section.data[0].link.replace(
                                                        "https://",
                                                        ""
                                                    )}`}
                                                    target="_blank"
                                                    className="btn btn--primary type--uppercase"
                                                >
                                                    <span className="btn__text">
                                                        Contact now
                                                    </span>
                                                </a>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </ParallaxBanner>
                ) : null}


            </section>
        );
    } else {
        return <></>;
    }
};

export default CTAParallax;
