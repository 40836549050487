import { FunctionComponent, useEffect, useState } from "react";
import FullScreenImage from "components/FullScreenImage";
import "./styles.scss";
import { Theme } from "pages/Home";
import UtilityHelper from "helpers/UtilityHelper";
import HeroLeftStandard from "./components/HeroLeftStandard";
import HeroLeftFreemium from "./components/HeroLeftFreemium";
import HeroLeftDefault from "./components/HeroLeftDefault";

interface ProfileCustomOneProps {
  section: any;
  theme: Theme | String;
}

const ProfileHeroLeftAlign: FunctionComponent<ProfileCustomOneProps> = ({
  section,
  theme,
}) => {
  const [imageList, setImageList] = useState<Array<any>>([]);
  useEffect(() => {
    if (UtilityHelper.isPresent(section?.data)) {
      setImageList(
        section?.data?.filter((e: any) =>
          UtilityHelper.isPresent(e?.image_file_data)
        )
      );
    } else {
      setImageList([]);
    }
  }, []);

  const renderHero = () => {
    switch (theme) {
      case "standard":
        return <HeroLeftStandard section={section} imageList={imageList} />;
      case "freemium":
        return <HeroLeftFreemium section={section} imageList={imageList} />;
      default:
        return <HeroLeftDefault section={section} imageList={imageList} />;
    }
  };
  if (Array.isArray(section?.data) && section?.data?.length !== 0) {
    if (section?.data?.length > 1) {
      return <>{renderHero()}</>;
    } else {
      if (UtilityHelper.isPresent(section?.data[0])) {
        return <>{renderHero()}</>;
      } else {
        return <></>;
      }
    }
  } else {
    return <></>;
  }
};

export default ProfileHeroLeftAlign;
