import { useEffect, useState } from "react";
import "./styles.scss";

function LoadingSuspense({
  first_time = false,
  style,
}: {
  first_time?: boolean;
  style?: React.CSSProperties;
}) {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return null;
  }

  return (
    <div className="screen" style={style}>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      {first_time && (
        <h1 className="text-center">
          Hang on a minute, we are creating your profile
        </h1>
      )}
    </div>
  );
}

export default LoadingSuspense;
