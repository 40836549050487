import { FunctionComponent, useEffect, useState } from "react";
import FullScreenImage from "components/FullScreenImage";
import "./styles.scss";
import SectionHelper from "helpers/SectionHelper";

const CustomOneFull: FunctionComponent<{ section: any }> = ({ section }) => {
  return (
    <section className="imagebg feature-large-7 switchable">
      <div className="background-image-holder" style={{ backgroundColor: "rgba(0, 0, 0, 0)", backgroundPosition: "initial", backgroundRepeat: "repeat", backgroundAttachment: "scroll", backgroundImage: "url(" + section.data[0]?.image_file_data?.image_s + ")", backgroundSize: "auto", backgroundOrigin: "padding-box", backgroundClip: "border-box", opacity: "1" }}>
        <img alt="image" src={section.data[0]?.image_file_data?.image_s} />
      </div>
      {!!section.data[0].title || !!section.data[0].description ? (
        <div className="container">
          <div className="row">
            <div className="col-md-7 col-lg-5">
              <div className="boxed boxed--lg border--round bg--white row justify-content-center">
                <div className="col-lg-10 col-md-12">
                  {!!section.data[0].title && (
                    <h2>{section.data[0].title}</h2>
                  )}
                  {!!section.data[0].description ? (
                    <p className="lead">{section.data[0].description}</p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </section>
  );
};

export default CustomOneFull;
