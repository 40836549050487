import { FunctionComponent } from "react";

import "./styles.scss";
import SocialIcon from "../convertSocial";
import SectionHelper from "helpers/SectionHelper";

export interface SocialProps {
  section: any;
}

const SocialDefault: FunctionComponent<SocialProps> = ({ section }) => {
  return (
    <section className={"social-container default flex-vertical bg-- " + SectionHelper.getColorClass(section)}>
      <h2>Follow Me On</h2>
      <div className="flex-horizontal social-items">
        {section.data.map((link: any, index: number) => (
          <SocialIcon key={index} social={link} />
        ))}
      </div>
    </section>
  );
};

export default SocialDefault;
