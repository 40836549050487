import React from 'react'
import './styles/download.css'
import { googleLogout } from '@react-oauth/google'
import AuthService from 'api/services/authService';
import { useNavigate } from 'react-router-dom';

const Download = () => {
  return (
    <div className=" flex flex-col download-page px-4 pt-8">
        <a href="https://castlebird.com/" className="flex gap-2 signout-btn font-medium text-lg cursor-pointer  " >                   
            Log out
        </a>

    <div className="flex left-col"  style={{flexDirection: 'column', alignItems: 'center'}} > 
   
        <h1 className="download-title text-center">Download Castlebird app to customize your website</h1>
              <p className="subtitle text-xl text-center mt-0 pb-3">Make sure to login to our app using <b>{AuthService.GetCurrentUser() ? AuthService.GetCurrentUser().email : null}</b> to edit, preview, personalize the newly created website to suit your needs and preferences. </p>
        <div className="download-btn flex gap-4" style={{padding: '10px 30px !important', }}  > 
            <a href="https://play.google.com/store/apps/details?id=com.castlebird.castleapp" className="googleplay-btn">
                <img src={require('assets/googleplay 1.png')} />
            </a>
            <a href="https://apps.apple.com/us/app/castlebird/id6449547159" className="appstore-btn">
                <img src={require('assets/appstore 1.png')} />
            </a>
        </div>
    </div>

    <div className="right-col ">
       <img src={require('assets/Sign Up graphic.png')} />
    </div>

     
   
    
</div>
  )
}

export default Download
