import { FunctionComponent } from "react";
import FullScreenImage from "components/FullScreenImage";

import "../TextLeftDefault/styles.scss";
import "./styles.scss";

interface ProfileCustomOneProps {
  section: any;
}

const TextLeftDefaultRimal: FunctionComponent<ProfileCustomOneProps> = ({
  section,
}) => {
  if (Array.isArray(section?.data) && section?.data?.length !== 0) {
    return (
      <div
        className={`text-left-container default rimal flex-vertical ${
          !section.data[0].title && !section.data[0].description
            ? "without-text"
            : ""
        } ${!section.data[0].image_file_data ? "without-image" : ""}`}
      >
        <section className="text-left-content">
          {!!section.data[0].image_file_data &&
          Object.keys(section.data[0].image_file_data).length !== 0 ? (
            <div className="image flex-vertical">
              <FullScreenImage src={section.data[0].image_file_data.image_s} />
            </div>
          ) : null}
          {!!section.data[0].title || !!section.data[0].description ? (
            <div
              className={`info ${
                !section.data[0].image_file_data ? "without-image" : ""
              }`}
            >
              {!!section.data[0].title && (
                <h2 className="title">{section.data[0].title}</h2>
              )}

              {!!section.data[0].description ? (
                <p className="description">{section.data[0].description}</p>
              ) : null}
            </div>
          ) : null}
        </section>
      </div>
    );
  } else {
    return <></>;
  }
};

export default TextLeftDefaultRimal;
