import { FunctionComponent, useEffect, useState } from "react";
import FullScreenImage from "components/FullScreenImage";
import "./styles.scss";
import SectionHelper from "helpers/SectionHelper";

const CustomOneCenter: FunctionComponent<{ section: any }> = ({ section }) => {
  function splitStringInHalf(str: string) {
    const midIndex = Math.floor(str.length / 2);
    let splitIndex = midIndex;

    // Move back to find a space (word boundary) before index
    while (splitIndex > 0 && str[splitIndex] !== ' ') {
      splitIndex--;
    }

    // If no space found, move forward 
    if (splitIndex === 0) {
      while (splitIndex < str.length && str[splitIndex] !== ' ') {
        splitIndex++;
      }
    }

    return [str.slice(0, splitIndex), str.slice(splitIndex + 1)];
  }

  return (
    <section className="feature-large feature-large-2 bg--secondary">
      <div className="container">
        <div className="row justify-content-around">
          <div className="col-md-4 col-lg-3">
            {!!section.data[0].title && (
              <h3>{section.data[0].title}</h3>
            )}
            {!!section.data[0].description ? (
              <p className="lead">{splitStringInHalf(section.data[0].description)[0]}</p>
            ) : null}
          </div>
          <div className="col-md-4 col-lg-4">
            <img alt="Image" className="border--round box-shadow-wide" src={section.data[0]?.image_file_data?.image_s} title="" />
          </div>
          <div className="col-md-4 col-lg-2">
            <hr className="short" />
            {!!section.data[0].description ? (
              <p>{splitStringInHalf(section.data[0].description)[1]}</p>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomOneCenter;
