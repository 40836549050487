import "./style.scss";
import Logo from "assets/logo.svg";
import Facebook from "assets/Footer/facebook.png";
import Twitter from "assets/Footer/twitter.png";
import Linkedin from "assets/Footer/linkedin.png";
import { Link, useParams } from "react-router-dom";
import { BsArrowUpShort } from "react-icons/bs";
import { LegacyRef, ReactNode, forwardRef } from "react";
import { IoArrowUpOutline } from "react-icons/io5";
import { Theme } from "pages/Home";

interface Props {
  pro?: any;
  pages: any;
  onChangePage: any;
  site: any;
  theme: Theme | String;
}
export type Ref = HTMLDivElement;

const Footer = forwardRef<Ref, Props>(
  ({ pro, pages, site, onChangePage, theme }, ref) => (
    <footer className={`footer flex-vertical default ${theme} bg-- `}>
      <div ref={ref} className="navigation flex-horizontal container" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="text">
          {!!site.name ? (
            <h1 className="pro_name text-uppercase">{site?.name}.</h1>
          ) : (
            <h1 className="pro_name text-uppercase">{pro?.name}.</h1>
          )}
          {!!site.name ? (
            <div className="copy-right type--fine-print">
              Designed by {site?.name} | Powered by CastleBird
            </div>
          ) : (
            <div className="copy-right type--fine-print">
              Designed by {pro?.name} | Powered by CastleBird
            </div>
          )}
        </div>

      </div>
    </footer>
  )
);

export default Footer;
