import { Theme } from "pages/Home";
import "./styles.scss";
import UtilityHelper from "helpers/UtilityHelper";
import FullScreenImage from "components/FullScreenImage";

export interface HeroDefaultProps {
  pro?: any;
  section: any;
  imageList: Array<any>;
}

function HeroDefault({ section, imageList }: HeroDefaultProps) {
  // CENTER
  if (section.variant == "center") {
    return (
      <div
        className={`hero-centered-container default flex-vertical ${!section.data[0]?.title && !section.data[0]?.description
          ? "without-text"
          : ""
          } ${UtilityHelper.isPresent(imageList) ? "" : "without-img"}`}
      >
        <section className="hero-centered-content">
          {!!section.data[0]?.title || !!section.data[0]?.description ? (
            <div
              className={`info ${!section.data[0]?.image_file_data ? "without-image" : ""
                }`}
            >
              {!!section.data[0]?.title && (
                <h1>{section.data[0]?.title}</h1>
              )}
              {!!section.data[0].description ? (
                <p className="lead">{section.data[0].description}</p>
              ) : null}
            </div>
          ) : null}
          {UtilityHelper.isPresent(imageList) ? (
            <div className="image flex-vertical">
              <img
                src={
                  section.data[section.data.length - 1].image_file_data?.image_s
                }
              />
            </div>
          ) : null}
        </section>
      </div>
    );
  }

  // LEFT
  if (section.variant == "left") {
    return (
      <section className="imageblock switchable--switch height-100">
        {!UtilityHelper.isPresent(imageList) ? null : (
          <div className="imageblock__content col-lg-6 col-md-4 pos-right">
            <div className="background-image-holder" style={{ backgroundColor: "rgba(0, 0, 0, 0)", backgroundPosition: "initial", backgroundRepeat: "repeat", backgroundAttachment: "scroll", backgroundImage: "url(" + imageList[0]?.image_file_data?.image_s + ")", backgroundSize: "auto", backgroundOrigin: "padding-box", backgroundClip: "border-box", opacity: "1" }}>
              <img alt="image" src={imageList[0]?.image_file_data?.image_s} />
            </div>
          </div>
        )}
        {!!section.data[0]?.title || !!section.data[0]?.description ? (
          <div className="container pos-vertical-center">
            <div className="row">
              <div className="col-lg-5 col-md-7">
                {!!section.data[0]?.title && (
                  <h1>{section.data[0]?.title}</h1>
                )}
                {!!section.data[0]?.description ? (
                  <p className="lead">{section.data[0]?.description}</p>
                ) : null}
                {/* <a className="btn btn--primary type--uppercase" href="#">
                  <span className="btn__text">
                    View The Demos
                  </span>
                </a> */}
              </div>
            </div>
          </div>
        ) : null}

      </section>

    );
  }
  if (section.variant == "inside") {
    return (
      <section className="cover height-100 imagebg text-center">
        {!UtilityHelper.isPresent(imageList) ? null : (
          <div className="background-image-holder" style={{ backgroundColor: "rgba(0, 0, 0, 0)", backgroundPosition: "initial", backgroundRepeat: "repeat", backgroundAttachment: "scroll", backgroundImage: "url(" + imageList[0]?.image_file_data?.image_s + ")", backgroundSize: "auto", backgroundOrigin: "padding-box", backgroundClip: "border-box", opacity: "1" }}>
            <img alt="image" src={imageList[0]?.image_file_data?.image_s} />
          </div>
        )}
        {!!section.data[0]?.title || !!section.data[0]?.description ? (
          <div className="container pos-vertical-center">
            <div className="row">
              <div className="col-md-12">
                <div className="h1--large type--uppercase mb-5">
                  {!!section.data[0]?.title && (
                    <h1>
                      <em>
                        <strong>{section.data[0]?.title}</strong>
                      </em>
                    </h1>
                  )}
                </div>
                {!!section.data[0]?.description ? (
                  <p className="lead">{section.data[0]?.description}</p>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </section>
    );
  }
  // DEFAULT
  return (
    // <section
    //   className={`hero-container default flex-horizontal ${
    //     UtilityHelper.isPresent(imageList) ? "" : "without-img"
    //   } ${
    //     !section.data[section.data.length - 1].title &&
    //     !section.data[section.data.length - 1].description
    //       ? "without-text"
    //       : ""
    //   }`}
    // >
    //   {UtilityHelper.isPresent(imageList) ? (
    //     <div className="hero-item hero-image">
    //       <div className="image flex-vertical">
    //         {<img src={imageList[0].image_file_data?.image_s} />}
    //       </div>
    //     </div>
    //   ) : null}
    //   <div className="hero-overlay"></div>
    //   {!!section.data[0].title || !!section.data[0].description ? (
    //     <div className={`hero-item hero-text`}>
    //       {!!section.data[0].title ? (
    //         <p className="title">{section.data[0].title}</p>
    //       ) : null}
    //       {!!section.data[0].description ? (
    //         <div className="content flex-vertical">
    //           <p className="description">{section.data[0].description}</p>
    //         </div>
    //       ) : null}
    //     </div>
    //   ) : null}
    // </section>
    <section className="cover height-80 imagebg text-center" data-overlay="4">
      {UtilityHelper.isPresent(imageList) ? (
        <div className="background-image-holder" style={{ backgroundColor: "rgba(0, 0, 0, 0)", backgroundPosition: "initial", backgroundRepeat: "repeat", backgroundAttachment: "scroll", backgroundImage: "url(" + imageList[0].image_file_data?.image_s + ")", backgroundSize: "auto", backgroundOrigin: "padding-box", backgroundClip: "border-box", opacity: "1", }}>
          <img src={imageList[0].image_file_data?.image_s} />
        </div>
      ) : (<div className="background-image-holder">
        <img src="" />
      </div>)}
      <div className="container pos-vertical-center">
        <div className="row">
          <div className="col-md-8 col-lg-7">
            {!!section.data[0].title ? (
              <h1>{section.data[0].title}</h1>
            ) : null}
            {!!section.data[0].description ? (
              <p className="lead">
                {section.data[0].description}
              </p>
            ) : null}

            {/* <a className="btn btn--primary type--uppercase" href="#">
              <span className="btn__text">
                View The Demos
              </span>
            </a> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroDefault;
