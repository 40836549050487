import React from 'react';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import 'primereact/resources/themes/saga-blue/theme.css'; // or any other theme
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './styles/signin.css'
import { toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import AuthService from 'api/services/authService';
import { useLocation, useNavigate } from 'react-router-dom';
import posthog from 'posthog-js';

const SignIn = () => {
    const location = useLocation();
    const navigate=useNavigate();

    const handleGoogleSignIn = () => {
        googleLogin();
    };

    const googleLogin = useGoogleLogin({
        onSuccess: (codeResponse) => LoginGoogleSuccess(codeResponse),
        onError: (error) => console.log("Login Failed:", error),
    });

    const LoginGoogleSuccess = async (res: any) => {
        const queryParams = new URLSearchParams(location.search);
        const domain = queryParams.get('domain');

        try {
            let old_user = AuthService.GetCurrentUser();
            const data = await AuthService.loginGoogle(res, domain);
            console.log(`User data: ${data}`);
            const saved = await AuthService.SaveAuth(data);
            navigate('/download', {replace:true})
            const currentUser= await AuthService.GetCurrentUser();
            const emailOfCurrentUser = currentUser.email;
            toast.success(`The website has been saved to ${emailOfCurrentUser} account`, {position: "top-right"});

            if (old_user) {
                posthog.alias(old_user.email, currentUser.email);
            }
            else {
                posthog.identify(currentUser.email);
            }
        } catch (error) {
            console.error('error', error);
            toast.error('Login failed', { position: "top-right" });
        }
    };
    
    const handleGoBack=()=> {
        navigate(-1);
    }

    return (
        <div className=" flex flex-col signin-page px-4 pt-8 ">
            <a id="back-btn" className="flex back-btn font-medium text-lg underline cursor-pointer pl-2 "  onClick={(e)=>{ e.preventDefault(); handleGoBack()}}>                   
                Back
            </a>
          
            
                <div className="flex left-col" style={{flexDirection: 'column', alignItems: 'center'}}>
                <h1 className="signin-title text-2xl md:text-3xl lg:text-5xl text-center">Personalize your website now</h1>
                <Button
                    label="Sign in with Google"
                    icon="pi pi-google"
                    className="google-signin-button"
                    onClick={handleGoogleSignIn}
                    style={{padding: '10px 30px !important', }}
                />
                <span className="information block">By signing up, you agree to our <a href='https://castlebird.com/terms'>Terms of Service</a> and <a href='https://castlebird.com/privacy'>Privacy Policy.</a>
                </span>
                </div>
        
             
            <div className="right-col ">
               <img src={require('assets/Sign Up graphic.png')} />
            </div>

        </div>
    );
};

export default SignIn;
