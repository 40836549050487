import { FunctionComponent } from "react";
import FullScreenImage from "components/FullScreenImage";
import { randomHSLA } from "pages/PublicProfile/components/FAQ";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Navigation } from "swiper";
import "swiper/css/navigation";

import "./styles.scss";

export interface ICustomThreeTheme {
  section: any;
  customList: any;
  textData: any;
}

const CustomThreeJames: FunctionComponent<ICustomThreeTheme> = ({
  textData,
  customList,
}) => {
  return (
    <div className="custom-three-container freemium flex-vertical">
      <section className="custom-three-content flex-vertical">
        <div className="custom-text">
          {!!textData.header ? <h3>{textData.header}</h3> : null}
          {!!textData.description ? <p>{textData.description}</p> : null}
        </div>
        <Swiper
          autoplay={{
            delay: 2500,
            disableOnInteraction: true,
          }}
          navigation={false}
          modules={[Autoplay, Navigation]}
          slidesPerView={1.2}
          spaceBetween={20}
          loop={true}
          className="custom-three-swiper"
          breakpoints={{
            768: {
              navigation: false,
              slidesPerView: 2.2,
              spaceBetween: 40,
            },
            1440: {
              navigation: true,
              slidesPerView: 3.5,
              spaceBetween: 40,
            },
          }}
        >
          {customList?.map((item: any, index: number) => (
            <SwiperSlide key={index}>
              <div className="custom-item flex-horizontal">
                {!!item.image_file_data &&
                Object.keys(item.image_file_data).length !== 0 ? (
                  <div
                    className="image flex-vertical"
                    style={{ backgroundColor: randomHSLA() }}
                  >
                    <img src={item.image_file_data?.image_s} alt="image" />
                  </div>
                ) : null}
                <div className="item-info flex-vertical">
                  {!!item.header ? (
                    <h2 className="title">{item.header}</h2>
                  ) : null}
                  {!!item.description ? (
                    <p key={index} className="description">
                      {item.description}
                    </p>
                  ) : null}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </div>
  );
};

export default CustomThreeJames;
