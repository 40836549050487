import "App.scss";
import "./styles/style.scss";
import { useContext, useEffect, useRef, useState } from "react";
import UserService from "api/services/UserService";
import {
  UserInterface,
} from "types/ApiInterface";
import LoadingSuspense from "components/Suspense";
import "aos/dist/aos.css";
import PagesService from "api/services/PagesService";
import { Theme } from "pages/Home";
import { StateContext } from "store/Provider";
import { Helmet } from "react-helmet";
import Favicon from "react-favicon";
import CB_logo from "assets/icon.png";
import WebsiteService from "api/services/SiteService";
import AccountService from "api/services/AccountService";
import { ActionKind } from "store/Reducer";
import UtilityHelper from "helpers/UtilityHelper";
import Footer from "components/Footer";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import ProfileNavbar from "components/NavbarProfile";
import SectionsService from "api/services/SectionsService";
<Helmet>
  <script src="../../js/jquery-3.1.1.min.js" type="text/javascript" />
  <script src="../../js/flickity.min.js" type="text/javascript" />
  <script src="../../js/easypiechart.min.js" type="text/javascript" />
  <script src="../../js/parallax.js" type="text/javascript" />
  <script src="../../js/typed.min.js" type="text/javascript" />
  <script src="../../js/datepicker.js" type="text/javascript" />
  <script src="../../js/isotope.min.js" type="text/javascript" />
  <script src="../../js/ytplayer.min.js" type="text/javascript" />
  <script src="../../js/lightbox.min.js" type="text/javascript" />
  <script src="../../js/granim.min.js" type="text/javascript" />
  <script src="../../js/jquery.steps.min.js" type="text/javascript" />
  <script src="../../js/countdown.min.js" type="text/javascript" />
  <script src="../../js/twitterfetcher.min.js" type="text/javascript" />
  <script src="../../js/spectragram.min.js" type="text/javascript" />
  <script src="../../js/smooth-scroll.min.js" type="text/javascript" />
  <script src="../../js/scripts.js" type="text/javascript" />
</Helmet>

function BlogLayout() {
  const [isHome, setIsHome] = useState(true);
  const [user, setUser] = useState<UserInterface>();
  const [site, setSite] = useState({
    logo_data: {
      image_s: ''
    },
    name: ''
  });
  let params = useParams();
  const navigate = useNavigate();
  const [theme, setTheme] = useState<String | Theme>("default");
  const [loading, setLoading] = useState(true);
  const formRef = useRef<HTMLInputElement>(null);
  const { state, dispatch } = useContext(StateContext);
  const [pages, setPages] = useState<Array<any>>([]);
  const footerRef = useRef<HTMLDivElement>(null);
  const [currentPage, setCurrentPage] = useState<any>();

  const getPages = async (user_id: string) => {
    console.log("Get pages");
    const data = await PagesService.GetPages({ user_id: user_id, });
    setPages(data);

    const blogPage = data.find((dataPage: { page_type: string; }) => dataPage.page_type === 'blog');

    setCurrentPage(blogPage);
  };

  useEffect(() => {
    setLoading(true);
    setTheme(UtilityHelper.getTheme());
    UtilityHelper.initApp(state).then((val) => {
      console.log(val);
      setSite(val.website);
      setUser(val.user);
      getPages(val.user.id)
      dispatch({ type: ActionKind.SET_CURRENT_WEBSITE, payload: val.website });
      dispatch({ type: ActionKind.SET_CURRENT_USER, payload: val.user });
      setLoading(false);
    });
  }, []);



  const setHome: () => JSX.Element = () => {
    return (
      <>
        <Favicon
          // url={!!site.logo_data ? site.logo_data.image_s : CB_logo}
          url={CB_logo}
          iconSize={36}
        />
        {site ? (
          <Helmet>
            <title>{site ? site['name'] : ""}</title>
            <meta
              name="keywords"
              content={`CastleBird, castlebird, CB, building website, building website for free, website building, ${user?.job_data?.name}`}
            />
          </Helmet>
        ) : null}
        <div className={`profile flex-vertical ${theme}`}>
          {site ? (
            <ProfileNavbar
              current_page={currentPage}
              site={site}
              pro={user}
              pages={pages}
              onChangePage={(page) => {
                if (page.page_type !== 'blog') {
                  setCurrentPage(page);
                  navigate(`/${page.permaid}`);
                }
                else {
                  // blog
                  navigate(`/news`);
                }
              }}
            />
          ) : null}
        </div>
        <Outlet />
        <div className="h-56" />
        {site ? (
          <Footer
            ref={footerRef}
            pro={user}
            site={site}
            pages={null}
            theme={theme}
            onChangePage={(page: any) => {
            }}
          />
        ) : null}
      </>
    );
  };

  return <>{loading ? <LoadingSuspense /> : setHome()}</>;
}

export default BlogLayout;